import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/dayjs';
import VCalendar from 'v-calendar';
import $ from 'jquery';

Vue.prototype.$ = $;
Vue.prototype.jQuery = $;

import App from './App.vue';
import store from './store';
import router from './router';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.prototype.AndroidShareHandler = window.AndroidShareHandler;
Vue.prototype.webkit = window.webkit;

Vue.use(VueGtag, {
    config: { id: 'G-E6MM8D39D3' },
});

// Use vc-calendar & vc-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
