<template>
    <v-main class="customMain">
        <PopupDate :is-select="chooseDate" v-show="!$store.state.isChoose" @customEvent="handleCustomEvent" />
        <BackOnTop title="個人資料修改" url="/member" />
        <PopupTop :is-show="showAlert" :errorMsg="msg" />
        <PopupRegisterGift
            :giftQuantity="giftQuantity"
            v-model="showRegisterGift"
            @click:confirm="handleRegisterGift"
            @click:close="$router.push('/')"
        />
        <PageTitleBar title="基本資料" />
        <v-container fluid class="member-container px-8 mt-6">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="mt-0">
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyInput
                            v-model="form.account"
                            label="聯絡電話(帳號)"
                            iconName="ic24Phone.svg"
                            hide-details="true"
                            isRequired
                            disabled
                            :sizes="18"
                            hideCheck
                            inputEl="memberAccount"
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyInput
                            v-model="form.name"
                            label="姓名"
                            iconName="ic24Name.svg"
                            placeholder="請輸入"
                            :sizes="18"
                            :rules="[() => !!form.name || '請輸入姓名']"
                            :isError="handleBlur(true, form.name)"
                            hide-details="true"
                            wrongHint="請輸入會員名字"
                            :isChecking="isChecking"
                            inputEl="memberName"
                            isRequired
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyVSelect 
                            label="生日"
                            icon-name="ic24BirthdayBlack.svg"
                            :sizes="18"
                            hide-details="true"
                            isRequired
                            required
                            :date="chooseDate"
                            :isError="handleBlur(true, chooseDate)"
                            wrongHint="請選擇生日"
                            :isChecking="isChecking"
                            inputEl="memberBirthday"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancySelect
                            v-model="form.gender"
                            label="性別"
                            iconName="ic24Gender.svg"
                            isRequired
                            :sizes="18"
                            :rules="[() => !!form.gender || '請輸入生理性別']"
                            hide-details="true"
                            inputEl="memberGender"
                            hideCheck
                            :options="[
                                {
                                    text: '性別',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '女', value: 'female' },
                                { text: '男', value: 'male' },
                            ]"
                            :items="[
                                {
                                    text: '性別',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '女', value: 'female' },
                                { text: '男', value: 'male' },
                            ]"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <FancyInput
                        v-model="form.id_card"
                        label="身分證末三碼"
                        iconName="ic24idcode.svg"
                        placeholder="請輸入"
                        isRequired
                        :sizes="18"
                        type="number"
                        :rules="[
                            () => (form.id_card.length == 3 ? true : '身分證末三碼不符合格式'),
                        ]"
                        :isError="handleBlur(form.id_card.length == 3, form.id_card)"
                        hide-details="true"
                        wrongHint="身分證末三碼不符合格式"
                        :isChecking="isChecking"
                        inputEl="memberIdCard"
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <FancyInput
                        v-model="form.telephone"
                        label="住家電話"
                        :sizes="18"
                        placeholder="請輸入"
                        iconName="ic24Phone.svg"
                        type="number"
                        :rules="[
                            () => (form.telephone.length <= 20 ? true : '住家電話超過20字'),
                        ]"
                        :isError="handleBlur(form.telephone.length <= 20, true)"
                        hide-details="true"
                        wrongHint="住家電話超過20字"
                        inputEl="memberTelephone"
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyInput
                          v-model="form.email"
                          label="電子信箱"
                          placeholder="請輸入"
                          iconName="ic24EmailBlack.svg"
                          type="email"
                          :rules="[
                            () =>
                              validateEmail(form.email)
                                ? true
                                : !!form.email
                                ? '不符合信箱格式或長度超過100字'
                                : true,
                          ]"
                          :isError="handleBlur(validateEmail(form.email), form.email)"
                          hide-details="true"
                          wrongHint="不符合信箱格式或長度超過100字"
                          inputEl="memberEmail"
                          :sizes="18"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-0">
                        <FancySelect
                          v-model="form.reg_storeno"
                          label="註冊門市"
                          iconName="ic24Name.svg"
                          :sizes="18"
                          :items="shopOptions"
                          :options="shopOptions"
                          hide-details="true"
                          required
                          hideCheck
                          inputEl="memberRegStoreno"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0" style="margin-bottom: 30px;">
                        <v-checkbox
                            color="#FF9F4B"
                            hide-details="true"
                            v-model="form.isSendDM"
                        >
                            <template #label>
                              <div style="font-family: Noto Sans TC;color:#363230;font-size:12px;">
                                我願意收到
                                <!-- <span style="font-family: Noto Sans TC;color:#D16200;" @click="$router.push('/dm?from=member')">紙本DM</span> -->
                                <span style="font-family: Noto Sans TC;color:#D16200;">紙本DM</span>
                              </div>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <div v-show="form.isSendDM" style="display:flex;flex-direction:column;gap:24px;justify-content:center">
                        <div class="title2-medium" style="width:100%;text-align: start;color:#0D0C0C">聯絡資料 <span style="color:#FF453A">*</span></div>
                        <div style="display:flex;column-gap:10px;flex-wrap:wrap;">
                            <FancySelect
                              style="width:calc(50% - 5px)"
                              v-model="form.county"
                              label="居住縣市"
                              placeholder="請選擇"
                              iconName="ic24Location.svg" 
                              :sizes="18"
                              :items="country_list"
                              :options="country_list"
                              :rules="[v => !form.isSendDM || !!form.county || '請選擇居住縣市']"
                              :isError="handleBlur(true, !form.isSendDM || !!form.county)"
                              hide-details="true"
                              wrongHint="請選擇居住縣市"
                              :isChecking="isChecking"
                              inputEl="memberCounty"
                            />
                            <FancySelect
                              style="width:calc(50% - 5px)"
                              v-model="form.district"
                              label="郵遞區號+區域"
                              placeholder="請選擇"
                              iconName=""
                              :sizes="18"
                              :items="zip_select_list"
                              :options="zip_select_list"
                              :rules="[v => !form.isSendDM || !!form.district || '請選擇郵遞區號+區域']"
                              :isError="handleBlur(true, !form.isSendDM || !!form.district)"
                              hide-details="true"
                              wrongHint="請選擇郵遞區號+區域'"
                              :isChecking="isChecking"
                              inputEl="memberDistrict"
                            />
                            <FancyInput
                            style="width:100%"
                              v-model="form.address"
                              label="居住地址"
                              placeholder="例:復興路186號"
                              :sizes="18"
                              iconName="ic24Location.svg"
                              type="text"
                              :rules="[v => !form.isSendDM || !!form.address || '請輸入居住地址']"
                              hide-details="true"
                              :isError="handleBlur(true, !form.isSendDM || !!form.address)"
                              wrongHint="請輸入居住地址"
                              :isChecking="isChecking"
                              inputEl="memberAddress"
                            />
                        </div>
                    </div>
                </v-row>
            </v-form>
        </v-container>
        <BtnSign :canClick="!canNext" :style="{pointerEvents: !canNext ? 'auto':'none', color: !canNext ? 'white':'#6F6F6F', backgroundColor: !canNext ? '#FF9F4B':'#F2F2F2'}" class="btn" text="修改完成" @click="handleSave" />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue'
import PopupTop from '@/components/Popup/PopupTop.vue'
import PageTitleBar from '@/components/PageTitleBar.vue'
import FancyInput from '@/components/FancyInput.vue'
import PopupDate from '@/components/Popup/PopupDate.vue';
import FancySelect from '@/components/FancySelect.vue'
import FancyVSelect from '@/components/FancyVSelect.vue'
import { getUserInfo, updateUserInfo, KgGetStore, checkRegisterCoupon } from '@/apis/api.js'
import PopupRegisterGift from '@/components/NewPopup/PopupRegisterGift.vue'
import BtnSign from '@/components/BtnSign.vue';

export default {
    name: 'MemberPassword',
    components: {
        BackOnTop,
        PageTitleBar,
        FancyInput,
        FancySelect,
        FancyVSelect,
        PopupRegisterGift,
        BtnSign,
        PopupDate,
        PopupTop
    },
    data: () => ({
        isChecking: false,
        checkdm: false,
        chooseDate: '',
        checkChooseDate: '',
        giftQuantity: 1,
        showRegisterGift: false,
        imgSrc: '',
        valid: '',
        showAlert: false,
        form: {
            account: '',
            name: '',
            gender: '',
            telephone: '',
            email: '',
            county: '',
            district: '',
            address: '',
            reg_storeno: '',
            id_card: '',
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            isSendDM: false
        },
        check: {
            account: '',
            name: '',
            gender: '',
            telephone: '',
            email: '',
            county: '',
            district: '',
            address: '',
            reg_storeno: '',
            id_card: '',
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            isSendDM: false
        },
        msg: '儲存成功',
        shopOptions: [
            {
                text: '網路門市',
                value: '',
            },
        ],
        district_obj: {},
        zip_select_list: [
          {
            text: '區域+郵遞區號',
            value: '',
            selected: true,
            disabled: true,
          },
        ],
        country_list: [
          {
            text: '縣市',
            value: '',
            selected: true,
            disabled: true,
          },
        ],
    }),

    beforeCreate() {
        this.$store.state.isLoading = true
    },
    created() {
        var parent = this
        getUserInfo(localStorage.getItem('phone'), localStorage.getItem('password')).then(rsp=>JSON.parse(rsp)).then(function (rsp)
        {
          parent.form.name = rsp.c_name
          parent.form.account = localStorage.getItem('phone')
          try {
            parent.chooseDate = `${rsp.c_birth.slice(0, 4)}/${rsp.c_birth.slice(4, 6)}/${rsp.c_birth.slice(6, 8)}`
            parent.form.birthday_year = parent.check.birthday_year = rsp.c_birth.slice(0, 4)
            parent.form.birthday_month = parent.check.birthday_month = rsp.c_birth.slice(4, 6)
            parent.form.birthday_day = parent.check.birthday_day = rsp.c_birth.slice(6, 8)
            parent.form.gender = parent.check.gender = rsp.c_gender
            parent.form.telephone = parent.check.telephone = rsp.c_home_num
            parent.form.email = parent.check.email = rsp.c_email
            parent.form.county = parent.check.county = rsp.c_county
            parent.form.district = parent.check.district = rsp.c_postcode
            parent.form.address = parent.check.address = rsp.c_address
            parent.form.reg_storeno = parent.check.reg_storeno = rsp.reg_storeno == '9999' ? '' : rsp.reg_storeno
            parent.form.id_card = parent.check.id_card = rsp.id_card
            parent.form.isSendDM = parent.check.isSendDM = rsp.isSendDM.toLowerCase() === 'true'
            setTimeout(function () {
              parent.form.district = rsp.c_postcode
            }, 100)
          } catch (error) {
            console.log(error)
          }

          console.log(parent.form)
        })

        KgGetStore(localStorage.getItem('phone')).then(function (rsp) {
            for (let i in rsp) {
                parent.shopOptions.push({
                    text: rsp[i].STO_Name,
                    value: rsp[i].STO_Name,
                })
            }
        })
        fetch('/data/index.json').then((res)=>res.json()).then((data)=>{
          for (let i in data) {
            parent.district_obj[data[i].city] = [
              {
                text: '區域+郵遞區號',
                value: '',
                selected: true,
                disabled: true,
              },
            ]
            for (let j in data[i].district) {
              parent.district_obj[data[i].city].push({
                text: data[i].district[j].zip + data[i].district[j].name,
                value: data[i].district[j].zip,
              })
            }
            parent.country_list.push({
              text: data[i].city,
              value: data[i].city,
            })
            parent.zip_select_list = parent.district_obj[parent.form.county]
          }
        })
    },
    mounted() {
        this.removebox();
        this.$nextTick(() => {
            this.$store.state.isLoading = false
        })
    },
    methods: {
        removebox() {
          let checkbox = document.querySelectorAll('.v-input--selection-controls__ripple')
          let sizeWrap = document.querySelectorAll('.v-input--selection-controls__input')
          let size = document.querySelectorAll('.v-input--selection-controls__input i')
          let slot = document.querySelectorAll('.v-input__slot .v-input--selection-controls__input')
          
          checkbox.forEach((item)=>{
              item.style.display='none';
          })
          size.forEach((item)=>{
              item.style.width='14px';
              item.style.height='14px';
              item.style.transform='scale(0.8)'
          })
          sizeWrap.forEach((item)=>{
              item.style.width='14px';
              item.style.height='14px';
          })
          slot.forEach((item)=>{
              item.style.transform='translateY(1px)';
          })
        },
        handleBlur(error, item) {
          return error && !!item
        },
        handleCustomEvent(data) {
            this.form.birthday_year = data.slice(0, 4)
            this.form.birthday_month = data.slice(4, 6)
            this.form.birthday_day = data.slice(6, 8)
            this.chooseDate = `${this.form.birthday_year}/${this.form.birthday_month}/${this.form.birthday_day}`;
        },
        handleRegisterGift() {
            this.$router.push('/info/coupon?type=use')
        },
        daysInMonth(year, month) {
            console.log('daysInMonth', year, month)
            return new Date(year, month, 0).getDate()
        },
        handleSave() {
            var parent = this
            const valid = this.$refs.form.validate()
            parent.isChecking = true
            if (valid) {
                parent.$store.state.isLoading = true
                updateUserInfo(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    this.form.name,
                    `${this.form.birthday_year}${this.form.birthday_month}${this.form.birthday_day}`,
                    this.form.gender,
                    this.form.telephone,
                    this.form.email,
                    this.form.county,
                    this.form.district,
                    this.form.address,
                    localStorage.getItem('member_no'),
                    this.form.reg_storeno,
                    this.form.id_card,
                    this.form.isSendDM ? '1' : '0'
                ).then(function (rsp) {
                    console.log(rsp);
                    
                    if (rsp) {
                        checkRegisterCoupon(
                            localStorage.getItem('phone'),
                            localStorage.getItem('password')
                        ).then(function (rsp) {
                            parent.$store.state.isLoading = false

                            if (rsp && rsp.status == '1') {
                                parent.showRegisterGift = true
                                parent.giftQuantity = rsp.quantity
                            } else {
                                // parent.msg = '修改成功'
                                // parent.imgSrc = '@/assets/images/imgTick.svg'
                                // parent.showAlert = true
                                // setTimeout(() => {
                                //     parent.showAlert = false
                                //     parent.$router.push('/member');
                                // }, 3000)
                                parent.$store.state.isShowAlert = 'memberinfo'
                                parent.$router.push('/member');
                            }
                        })
                    } else {
                        parent.$store.state.isLoading = false
                        parent.msg = '修改失敗，請再試一次'
                        parent.imgSrc = '@/assets/images/imgWarning.svg'
                        parent.showAlert = true
                        setTimeout(() => {
                            parent.showAlert = false
                        }, 3000)
                    }
                })
            }
        },
        validateEmail(email) {
            return (
                email.length <= 100 &&
                String(email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
            )
        },
    },
    watch: {
      'form.county': function () {
        this.zip_select_list = this.district_obj[this.form.county]
        this.form.district = ''
      },
    },
    computed: {
        canNext: function () {
            return this.form.birthday_year === this.check.birthday_year && this.form.birthday_month === this.check.birthday_month && this.form.birthday_day === this.check.birthday_day && this.form.gender === this.check.gender && this.form.telephone === this.check.telephone && this.form.email === this.check.email && this.form.county === this.check.county && this.form.district === this.check.district && this.form.address === this.check.address && this.form.reg_storeno === this.check.reg_storeno && this.form.id_card === this.check.id_card && this.form.isSendDM === this.check.isSendDM
        },
    },
}
</script>

<style lang="scss" scoped>
.btn {
  margin-top: auto;
  margin-bottom: 40px;
}
.customMain {
  height: 100%;
}
.member-container {
    margin: 12px 0;
    p.desc {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
    }
    .btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 52px;
    }
}
</style>
