<script>
export default {
    name: "BtnNext",
    props: {
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<template>
    <v-btn class="btn_next" icon v-bind="{ ...$attrs }" v-on="$listeners">
        <v-img alt="logo" src="@/assets/images/btn_next.svg" />
    </v-btn>
</template>

<style lang="scss" scoped>

</style>
