<template>
    <!-- -->
    <div class="photo_wrapper">
        <div class="photo" :style="{ 'background-image': `url(${imgSrc})` }" />
        <p
            style="
                font-size: 16px;
                font-weight: bolder;
                color: #6f6f6f;
                font-family: 'Noto Sans TC';
            "
        >
            {{ title }}
        </p>
        <button
            class="style-btn style-l-btn style-light-btn"
            @click="$router.push('/salon/order/edit')"
            style="border-radius: 18px; margin: 0 auto; margin-top: 30px"
        >
            前往預約<img
                src="../assets/images/white-foot.png"
                style="width: 24px; height: 24px"
            />
        </button>
    </div>
</template>

<script>
export default {
    name: 'BabyPhoto',
    props: {
        imgSrc: {
            type: String,
        },
        title: {
            type: String,
        },
    },

    methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.photo_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .photo {
        width: 173px;
        height: 133px;
        // border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 0 auto 24px;
        @media screen and (max-width: 374px) {
            width: 180px;
            height: 180px;
        }
    }

    h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $color_main_first;
    }
}
</style>
