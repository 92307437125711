/* eslint-disable prettier/prettier */
<template>
    <LayoutWithNav>
        <div v-if="!$store.state.isLoading" class="home">
            <div v-if="imgsCount" class="banner">
                <VueSlickCarousel
                    :arrows="false"
                    :dots="true"
                    :autoplay="true"
                    :autoplaySpeed="4000"
                >
                    <div
                        v-for="(img, idx) of bannerList"
                        :key="idx"
                        class="banner d_flex"
                    >
                        <img :src="img" alt="" />
                    </div>
                </VueSlickCarousel>
            </div>
            <div class="booking d_flex bg_orange">
                <div class="d_flex" @click="redirect('/salon/order/edit')">
                    <img src="../assets/images/ic30Reservation.svg" alt="" />
                    <span>立即預約</span>
                </div>
                <div class="d_flex" @click="redirect('/salon/history')">
                    <img src="../assets/images/ic30Contract.svg" alt="" />
                    <span>美容日誌</span>
                </div>
            </div>
            <div class="func d_flex">
                <div
                    v-for="(el, idx) in funcList"
                    :key="idx"
                    tabindex="0"
                    @click="redirect(el.href)"
                >
                    <div
                        class="funcImg"
                        :style="{ 'background-color': el.color }"
                    >
                        <img
                            :src="
                                require(`../assets/images/imgImgHome0${
                                    idx + 1
                                }.svg`)
                            "
                            :alt="el.name"
                        />
                    </div>

                    <p>{{ el.name }}</p>
                    <span v-if="el.notify" class="notify">{{ el.notify }}</span>
                </div>
            </div>
            <div class="product_list">
                <h3>本月職人推薦</h3>
                <div class="d_flex">
                    <div
                        v-for="(el, idx) in prodList"
                        :key="idx"
                        @click="openProdLB(el)"
                        class="item"
                    >
                        <ProductItem :data="el" :verticle="true" />
                    </div>
                </div>
            </div>
            <!-- <div class="gift" v-if="ifGiftShow">
                <img
                    src="../assets/images/imgGift.png"
                    @click="lbGiftOpen = true"
                    alt=""
                    width="72"
                />
                <img
                    src="../assets/images/btn_close.png"
                    class="btn_close"
                    alt=""
                    @click="giftClose = true"
                />
            </div> -->
        </div>
        <LB :open="lbGiftOpen" @closeLb="closeGiftLb" class="lb">
            <h4><font>註冊好禮輕鬆領</font></h4>
            <div class="d_flex">
                <div class="step st1">
                    <img src="../assets/images/imgImgHomeLB01.svg" alt="" />
                    <p class="f_green">步驟1</p>
                    <p class="f_grey">填寫會員資料</p>
                    <button
                        @click="loginActivity(1)"
                        :class="{ not_yet: !signUp1, done: signUp1 }"
                    >
                        {{ signUp1 ? '' : '前往填寫' }}
                    </button>
                </div>
                <div class="step st2">
                    <img src="../assets/images/imgImgHomeLB02.svg" alt="" />
                    <p class="f_green">步驟2</p>
                    <p class="f_grey">填寫寵物資料</p>
                    <button
                        @click="loginActivity(2)"
                        :class="{ not_yet: !signUp2, done: signUp2 }"
                    >
                        {{ signUp2 ? '' : '前往填寫' }}
                    </button>
                </div>
            </div>
            <!-- <v-btn
                :class="{ inactive: signUp1 && signUp2 }"
                class="get_gift"
                @click="getCoupon()"
            >
                領好禮
            </v-btn> -->
            <div
                class="row"
                style="justify-content: center; margin-top: 13px; height: 20px"
            >
                <FancyCheck
                    v-model="isSevenDays"
                    type="checkbox"
                    style="margin-right: -6px"
                />
                <span style="font-size: 13px; margin-top: 3px"
                    >七日內不再提醒</span
                >
            </div>
        </LB>
        <LB :open="lbProdOpen" @closeLb="closeLb" class="lb">
            <h4><font>本月職人推薦</font></h4>
            <div class="product">
                <div class="detail_wrapper d_flex">
                    <h5>{{ lbProdData.title }}</h5>
                    <div class="W100 mb-4 mt-4">
                        <h6>{{ lbProdData.type }}</h6>
                        <span class="price">{{ lbProdData.price }}</span>
                    </div>
                </div>
                <div class="img_wrapper">
                    <img :src="lbProdData.imgSrc" alt="" />
                </div>
            </div>
        </LB>
    </LayoutWithNav>
</template>

<script>
// @ is an alias to /src
import LayoutWithNav from '@/layouts/LayoutWithNav.vue';
import LB from '@/components/LB.vue';
import ProductItem from '@/components/ProductItem.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import FancyCheck from '@/components/FancyCheck';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import {
    getBannerList,
    checkPersonalData,
    checkPetData,
    getRegPre,
    checkReg,
    getHomePageArticle,
    getRecommendList,
    getRecommendImage,
    getCouponList,
    getPersonalCouponList,
    updateDeviceToken,
} from '@/apis/api.js';
export default {
    name: 'Home',
    components: {
        LayoutWithNav,
        LB,
        ProductItem,
        VueSlickCarousel,
        FancyCheck,
    },
    data: () => ({
        isSevenDays: false,
        refreshKey: 0,
        funcList: [
            { name: '寵物美容', color: '#FDF8FD', href: '/salon/order/edit' },
            {
                name: '泳池預約',
                color: '#F0F8FB',
                href: {
                    path: '/salon/order/edit',
                    query: { type: '游泳' },
                },
            },
            {
                name: '線上DM',
                color: '#F9ECD5',
                href: {
                    path: 'dm/',
                },
            },
            { name: '毛孩小工具', color: '#F5FDEC', href: '/tools/' },
            { name: '門市據點', color: '#FAF9F1', href: '/location/' },
            {
                name: '優惠票券',
                color: '#FCF7F3',
                href: '/info/coupon',
                notify: 0,
            },
            {
                name: '美容集點',
                color: '#EEF9FB',
                href: {
                    path: 'point',
                    query: { id: '67890', type: '寵知識' },
                },
            },
            // {
            //     name: '獸醫聊天室',
            //     color: '#FBF5F5',
            //     href: {
            //         path: 'info/news',
            //         query: { id: 'acbtr', type: '獸醫聊天室' },
            //     },
            // },
        ],
        prodList: [],
        // ifGiftShow: false,
        lbProdOpen: false,
        lbProdData: {},
        signUp1: true,
        signUp2: true,
        canGetCoupon: false,
        bannerList: [],
        transitionName: 'right-in',
        imgsCount: 0,
        couponUngetList: [],
        couponUndoneList: [],
    }),
    computed: {
        couponNotificationCount() {
            return this.couponUndoneList.length;
        },
        lbGiftOpen() {
            this.refreshKey;
            let openTime = parseInt(localStorage.getItem('giftOpenTime'));
            if (!openTime) openTime = 0;
            let nowTime = new Date().getTime();
            if (this.signUp1 && this.signUp2) {
                return false;
            } else {
                if (nowTime >= openTime) return true;
                else return false;
            }
            // return true
        },
    },
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        var parent = this;
        // 先綁fcm_token/device_id
        if (parent.$route.query.fcm_token)
            localStorage.setItem('fcm_token', parent.$route.query.fcm_token);
        // 有登入狀態可以綁手機
        if (localStorage.getItem('phone')) {
            updateDeviceToken(
                localStorage.getItem('phone'),
                localStorage.getItem('fcm_token')
            ).then(function (rsp) {
                console.log('updateDeviceToken', rsp);
            });
        }

        // Banner list
        getBannerList().then((result) => {
            if (result) {
                parent.bannerList = result.result.map(function (obj) {
                    return obj.pic_url.replace('http:', 'https:');
                });
                if (parent.bannerList.length > 8)
                    parent.bannerList = parent.bannerList.slice(0, 8);
                parent.imgsCount = parent.bannerList.length;
            }
            setTimeout(function () {
                parent.$store.state.isLoading = false;
            }, 1000);
        });

        // 寵知識三種
        getHomePageArticle().then(function (rsp) {
            var data = JSON.parse(rsp);
            console.log('getHomePageArticle', data);
            for (let i in data) {
                // if (data[i].article == 'petfood') {
                //     parent.funcList[2].href.query.category_id = 'knowledge';
                //     parent.funcList[2].href.query.article_id =
                //         data[i].content_id;
                // }
                // else if (data[i].article == 'petknow') {
                //     parent.funcList[6].href.query.category_id = 'knowledge';
                //     parent.funcList[6].href.query.article_id =
                //         data[i].content_id;
                // }
                if (data[i].article == 'doctorchat') {
                    parent.funcList[7].href.query.category_id = 'veterinary';
                    parent.funcList[7].href.query.article_id =
                        data[i].content_id;
                }
            }
            console.log(rsp);
        });
        // 職人推薦
        getRecommendList().then(function (rsp) {
            parent.prodList = [];
            for (let i in rsp) {
                getRecommendImage(rsp[i].id).then(function (image) {
                    setTimeout(function () {
                        parent.prodList.push({
                            sort: rsp[i].sort,
                            title: rsp[i].p_name,
                            price: rsp[i].price,
                            type: rsp[i].tag,
                            imgSrc: image,
                        });
                        if (
                            parent.$route.query.item_id &&
                            parent.$route.query.item_id == rsp[i].id
                        ) {
                            parent.openProdLB({
                                sort: rsp[i].sort,
                                title: rsp[i].p_name,
                                price: rsp[i].price,
                                type: rsp[i].tag,
                                imgSrc: image,
                            });
                        }
                        if (i == rsp.length - 1) {
                            parent.prodList.sort((a, b) =>
                                a.sort < b.sort ? -1 : b.sort > a.sort ? 1 : 0
                            );
                        }
                    }, 100 * i);
                });
            }
        });
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            // this.$router.push('/login');
            // parent.signUp1 = true;
            // parent.signUp2 = true;
            // parent.canGetCoupon = false;
        } else {
            checkReg(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (rsp) {
                if (!rsp) {
                    parent.signUp1 = true;
                    parent.signUp2 = true;
                    parent.canGetCoupon = false;
                } else {
                    checkPersonalData(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password')
                    ).then(function (rsp) {
                        if (rsp) {
                            parent.canGetCoupon = true;
                            parent.signUp1 = true;
                        } else {
                            parent.canGetCoupon = true;
                            parent.signUp1 = false;
                        }
                    });
                    checkPetData(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password')
                    ).then(function (rsp) {
                        if (rsp) {
                            parent.canGetCoupon = true;
                            parent.signUp2 = true;
                        } else {
                            parent.canGetCoupon = true;
                            parent.signUp2 = false;
                        }
                    });
                }
            });

            var newDateObj = new Date();
            var today =
                newDateObj.getFullYear().toString() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1).toString()
                    : newDateObj.getMonth() + 1
                ).toString() +
                (newDateObj.getDate() < 10
                    ? ('0' + newDateObj.getDate()).toString()
                    : newDateObj.getDate()
                ).toString();

            getCouponList(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (all_coupon_list) {
                if (all_coupon_list.length && all_coupon_list) {
                    parent.couponUngetList = [];
                    parent.couponUndoneList = [];
                    parent.couponDoneList = [];
                    getPersonalCouponList(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password')
                    ).then(function (personal_coupon_list) {
                        console.log(all_coupon_list, personal_coupon_list);
                        for (let i in all_coupon_list) {
                            for (let j in personal_coupon_list) {
                                if (
                                    all_coupon_list[i].tNo ==
                                    personal_coupon_list[j].tNo
                                ) {
                                    all_coupon_list[i].endDate =
                                        personal_coupon_list[j].endDate;
                                }
                            }
                        }
                        let per_check_index = [];
                        for (let i in all_coupon_list) {
                            var endDateTime = new Date(
                                all_coupon_list[i].endDate.slice(0, 4) +
                                    '/' +
                                    all_coupon_list[i].endDate.slice(4, 6) +
                                    '/' +
                                    all_coupon_list[i].endDate.slice(6, 8)
                            ).getTime();
                            var nowDateTime = new Date().getTime();
                            var expired_days =
                                (nowDateTime - endDateTime) /
                                (1000 * 60 * 60 * 24);
                            if (
                                all_coupon_list[i].startDate <= today &&
                                all_coupon_list[i].endDate >= today
                            ) {
                                var have = false;
                                all_coupon_list[i].type = '優惠票卷';
                                for (let j in personal_coupon_list) {
                                    if (
                                        per_check_index.indexOf(j) == -1 &&
                                        all_coupon_list[i].tNo ==
                                            personal_coupon_list[j].tNo
                                    ) {
                                        have = true;
                                        per_check_index.push(j);
                                        console.log(
                                            'per_check_index',
                                            per_check_index
                                        );
                                        if (
                                            personal_coupon_list[j].isUse == '1'
                                        ) {
                                            all_coupon_list[i].endDate = personal_coupon_list[j].endDate;
                                            parent.couponDoneList.push(
                                                all_coupon_list[i]
                                            );
                                        } else {
                                            console.log('match', j);
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            all_coupon_list[i].pcid =
                                                personal_coupon_list[j].pcid;
                                            let temp_coupon = JSON.parse(
                                                JSON.stringify(
                                                    all_coupon_list[i]
                                                )
                                            );
                                            temp_coupon.index = j;
                                            parent.couponUndoneList.push(
                                                temp_coupon
                                            );
                                        }
                                    }
                                    if (j == personal_coupon_list.length - 1) {
                                        if (
                                            !have &&
                                            all_coupon_list[i].isvisible !=
                                                'False'
                                        )
                                            if(expired_days >= 1){
                                                parent.couponUngetList.push(
                                                    all_coupon_list[i]
                                                );
                                            }else{
                                                parent.couponUndoneList.push(
                                                    all_coupon_list[i]
                                                );
                                            }
                                    }
                                }
                                if (
                                    (!personal_coupon_list.length ||
                                        !personal_coupon_list) &&
                                    all_coupon_list[i].isvisible != 'False'
                                )
                                    parent.couponUngetList.push(
                                        all_coupon_list[i]
                                    );
                            } else if (all_coupon_list[i].endDate < today) {
                                have = false;
                                all_coupon_list[i].type = '優惠票卷';
                                for (let j in personal_coupon_list) {
                                    if (
                                        all_coupon_list[i].tNo ==
                                        personal_coupon_list[j].tNo
                                    ) {
                                        if (
                                            personal_coupon_list[j].isUse == '1'
                                        ) {
                                            // 領取並用過且過期
                                            have = true;
                                            all_coupon_list[i].valid = '';
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            parent.couponUngetList.push(
                                                all_coupon_list[i]
                                            );
                                        } else {
                                            // 領取沒用過且過期
                                            have = true;
                                            all_coupon_list[i].valid =
                                                'expired';
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            if (expired_days < 30)
                                                parent.couponUngetList.push(
                                                    all_coupon_list[i]
                                                );
                                        }
                                    }
                                    if (j == personal_coupon_list.length - 1) {
                                        if (
                                            !have &&
                                            all_coupon_list[i].isvisible !=
                                                'False'
                                        ) {
                                            // 未領取且過期
                                            all_coupon_list[i].valid =
                                                'expired';
                                            if (expired_days < 30)
                                                parent.couponUngetList.push(
                                                    all_coupon_list[i]
                                                );
                                        }
                                    }
                                }
                                if (
                                    (!personal_coupon_list.length ||
                                        !personal_coupon_list) &&
                                    all_coupon_list[i].isvisible != 'False'
                                ) {
                                    all_coupon_list[i].valid = 'expired';
                                    if (expired_days < 30)
                                        parent.couponDoneList.push(
                                            all_coupon_list[i]
                                        );
                                }
                            }
                            if (i == all_coupon_list.length - 1) {
                                parent.couponUngetList.sort(function (a, b) {
                                    return (
                                        b.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '') -
                                        a.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '')
                                    );
                                });
                                parent.couponUndoneList = JSON.parse(
                                    JSON.stringify(parent.couponUndoneList)
                                ).sort(function (a, b) {
                                    return (
                                        parseInt(b.index) - parseInt(a.index)
                                    );
                                });
                                console.log(
                                    'parent.couponUndoneList',
                                    parent.couponUndoneList
                                );
                                parent.couponDoneList.sort(function (a, b) {
                                    return (
                                        b.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '') -
                                        a.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '')
                                    );
                                });
                            }
                        }
                    });
                }
            });
        }
    },
    mounted() {
        console.log(
            localStorage.getItem('member_no'),
            localStorage.getItem('password'),
            localStorage.getItem('phone')
        );
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.$store.state.isLoading = false;
        //     }, 1000);
        // });
    },
    methods: {
        loginActivity(type) {
            if (type == 1 && !this.signUp1) this.$router.push('/member/info');
            else if (type == 2 && !this.signUp2)
                this.$router.push('/register/pet?type=home');
            // this[`signUp${type}`] = true;
        },
        closeLb() {
            this.lbProdOpen = false;
        },
        closeGiftLb() {
            if (this.isSevenDays) {
                let openTime = new Date().getTime() + 1000 * 60 * 60 * 24 * 7;
                localStorage.setItem('giftOpenTime', openTime);
                this.refreshKey += 1;
            } else {
                let openTime = new Date().getTime() + 1000 * 60 * 10;
                localStorage.setItem('giftOpenTime', openTime);
                this.refreshKey += 1;
            }
        },
        openProdLB(data) {
            this.lbProdOpen = true;
            this.lbProdData = data;
        },
        redirect(link) {
            setTimeout(() => {
                this.$router.push(link);
            }, 500);
        },
        getCoupon() {
            var parent = this;
            if (this.signUp1 && this.signUp2) {
                getRegPre(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password')
                ).then(function (rsp) {
                    if (rsp) {
                        parent.redirect('/info/coupon?type=use');
                    }
                });
            }
        },
    },
    watch: {
        couponNotificationCount(newCount) {
            const couponItem = this.funcList.find(item => item.name === '優惠票券');
            if (couponItem) {
                couponItem.notify = newCount;
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.home {
    min-height: 100vh;
    background-color: #f7f5f4;
    .banner {
        width: 100%;
        img {
            width: 100vw;
            display: inline-block;
            vertical-align: text-top;
        }
    }
    .booking {
        width: 100%;
        justify-content: space-between;
        padding: 10px 16px;
        div {
            width: calc(50% - 8px);
            border-radius: 10px;
            background-color: #fff;
            padding: 15px 0;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                background-color: #ff9f48;
                border-radius: 50%;
                opacity: 0;
                pointer-events: none;
                transition: all ease-in-out 0.6s;
                transform: scale(2, 5) translate(-50%, -50%);
                transform-origin: left top;
            }
            &:active {
                &::after {
                    padding: 0;
                    margin: 0;
                    opacity: 0.1;
                    transition: 0s;
                    transform: scale(0, 0) translate(-50%, -50%);
                }
            }
            img {
                margin-right: 8px;
            }
            span {
                font-size: 14px;
            }
        }
    }
    .func {
        background-color: #fff;
        justify-content: start;
        padding: 16px 16px 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        > div {
            position: relative;
            width: 25%;
            margin-bottom: 12px;
            .funcImg {
                width: calc(100% - 20px);
                display: inline-block;
                margin: 0 auto 8px;
                border-radius: 50%;
                overflow: hidden;
                position: relative;

                img {
                    width: 100%;
                    vertical-align: top;
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 100%;
                    width: 100%;
                    background-color: #ff9f48;
                    border-radius: 50%;
                    opacity: 0;
                    pointer-events: none;
                    transition: all 0.5s;
                    transform: scale(1) translate(-50%, -50%);
                    transform-origin: left top;
                }
                &:active {
                    &::after {
                        padding: 0;
                        margin: 0;
                        opacity: 0.2;
                        transition: 0s;
                        transform: scale(0, 0) translate(-50%, -50%);
                    }
                }
            }

            p {
                margin-bottom: 0;
                line-height: 1.6;
                font-size: 14px;
                color: $color_main_black;
            }
            .notify {
                right: 12%;
                top: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                color: #fff;
                background-color: $color_main_first;
            }
        }
    }
    .product_list {
        width: calc(100% - 24px);
        margin: 8px auto 0;
        padding-bottom: 60px;
        background-color: #f1e4cf;
        border-radius: 24px;
        padding: 0 12px 12px;
        border: 2px solid #fff;

        h3 {
            background-image: url(~@/assets/images/imgImgHome11.svg);
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100%;
            width: 250px;
            line-height: 45px;
            color: #fff;
            font-size: 20px;
            margin: 0 auto 18px;
            font-weight: bold;
            &:before,
            &:after {
                content: '';
                background-image: url(~@/assets/images/icStar.svg);
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                width: 16px;
                height: 16px;
                margin: 0 8px;
            }
        }
        > .d_flex {
            width: 100%;
            justify-content: space-between;
            .item {
                width: calc(50% - 4px);
            }
        }
    }
    .gift {
        position: fixed;
        right: 8px;
        top: 70%;
        transform: translate(0, -50%);
        z-index: 10;
        .btn_close {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 20px);
            width: 24px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}
.lb {
    .d_flex {
        margin-top: 36px;
        width: 100%;
        justify-content: space-evenly;
        img {
            margin-bottom: 10px;
        }
        p,
        button {
            margin-top: 8px;
        }
        .f_green {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
        }
        .f_grey {
            font-size: 14px;
            line-height: 16px;
        }
        button {
            border: 2px solid #ffffff;
            display: inline-block;

            &.done {
                height: 20px;
                width: 20px;
                background-image: url(~@/assets/images/icCheck.svg);
                background-size: contain;
                text-indent: -10000px;
                overflow: hidden;
                border-radius: 10px;
            }
            &.not_yet {
                color: #fff;
                background-color: $color_main_first;
                font-size: 14px;
                line-height: 20px;
                border-radius: 15px;
                padding: 0 10px;
            }
        }
        .W100 {
            h6 {
                color: #fff;
                background-image: url(~@/assets/images/icTag.svg);
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 40px;
                font-size: 12px;
                padding-left: 4px;
                display: inline-block;
                width: 50px;
                text-align: left;
            }
        }
    }
    .get_gift {
        line-height: 40px;
        border-radius: 20px;
        color: #fff;
        background: #cccccc !important;
        border: 2px solid #ffffff;
        border-radius: 22px;
        font-size: 20px;
        width: 148px;
        margin-top: 22px;
        &.inactive {
            background-color: $color_main_first !important;
        }
    }
    .product {
        padding-top: 10px;
    }
    .detail_wrapper {
        flex-direction: column;
        * {
            font-size: 14px;
        }

        .price {
            color: $color_main_second;
            margin: 5px auto 20px;
            &:before {
                content: '$';
            }
        }
    }
    .img_wrapper {
        img {
            max-height: 35vh;
        }
    }
}
</style>
