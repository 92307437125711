<template>
    <div class="salon-photo-slides">
        <!-- Slides -->
        <v-slide-group
            class="salon-photo-slides-group"
            v-model="selectedSlide"
            active-class="clicked"
            mandatory
            show-arrows
        >
            <template v-slot:prev>
                <img
                    class="prevArrow"
                    :src="require(`@/assets/images/ic24ArrowR.svg`)"
                    alt=""
                />
            </template>
            <template v-slot:next>
                <img :src="require(`@/assets/images/ic24ArrowR.svg`)" alt="" />
            </template>
            <v-slide-item
                v-for="(slide, i) in slides"
                :key="i"
                v-slot="{ toggle }"
            >
                <img
                    class="mr-2"
                    @click="handleSlideClick(toggle)"
                    :src="slide"
                />
            </v-slide-item>
        </v-slide-group>

        <!-- Popup -->
        <v-dialog class="fancy" v-model="openDialog">
            <div class="dialog_box salon-photo-dialog">
                <v-container fluid class="dialog_container">
                    <div class="d_flex flex-row-reverse mb-4">
                        <img
                            width="24"
                            height="24"
                            class=""
                            src="@/assets/images/ic24Delete.svg"
                            alt=""
                            @click="openDialog = false"
                        />
                    </div>
                    <img
                        width="289"
                        height="294"
                        class="mb-n1 salon-big-photo"
                        :src="slides[selectedSlide]"
                        alt=""
                    />

                    <p class="img-desc">
                        * 按下按鈕可分享或下載圖片（線上相片僅保存3個月）
                    </p>

                    <div class="footer d-flex justify-center">
                        <FancyButton
                            class="btn"
                            block
                            @click="shareViaWebShare()"
                        >
                            <img
                                width="24"
                                height="24"
                                class="mr-5"
                                src="@/assets/images/ic24Share.svg"
                                alt=""
                            />
                            分享
                        </FancyButton>
                    </div>
                </v-container>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import FancyButton from '@/components/FancyButton.vue';

export default {
    name: 'SalonPhotoSlides',
    components: {
        FancyButton,
    },
    props: {
        slides: { type: Array },
    },
    data: () => ({
        selectedSlide: '0',
        openDialog: false,
    }),
    methods: {
        handleSlideClick(toggle) {
            toggle();
            this.$nextTick(() => {
                this.openDialog = true;
            });
        },
        async shareViaWebShare() {
            var parent = this;
            let u = navigator.userAgent;
            let is_android =
                u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let is_ios =
                u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) != null ? true : false;
            console.log('platform', u, is_ios, is_android);
            if (!is_android) {
                parent.webkit.messageHandlers.ToApp.postMessage(
                    parent.slides[parent.selectedSlide]
                );
                parent.openDialog = false;
                // const response = await fetch(parent.slides[parent.selectedSlide].replace("http://testcms.greattreepets.com",""));
                // const blob = await response.blob();
                // const filesArray = [
                //     new File(
                //         [blob],
                //         parent.slides[parent.selectedSlide],
                //         {
                //             type: "image/jpeg",
                //             lastModified: new Date().getTime()
                //         }
                //     )
                // ];
                // const shareData = {
                //     files: filesArray,
                // };
                // navigator.share(shareData);
            } else {
                // this.AndroidShareHandler.share(parent.slides[parent.selectedSlide]);
                parent.AndroidShareHandler.share(
                    parent.slides[parent.selectedSlide]
                );
                parent.openDialog = false;
            }
        },
    },
    computed: {
        webShareApiSupported() {
            return navigator.share;
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
div.v-dialog {
    > .dialog_box {
        &.salon-photo-dialog {
            padding: 24px 20px;
        }
        .salon-big-photo {
            border-radius: 10px;
        }
        p.img-desc {
            width: 100%;
            color: $color_main_grey_dark;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }
    }
}

.salon-photo-slides {
    background-color: #f9f6f4;
}
.salon-photo-slides-group {
    &.v-slide-group.v-item-group {
        .v-slide-group__prev {
            margin-right: 12px;
            img {
                border-radius: 8px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
        .v-slide-group__next {
            margin-left: 12px;
        }
        .v-slide-group__wrapper {
            img {
                height: 55px;
                width: auto;
                border: 1px solid #ebd9cc;
                border-radius: 4px;
            }
        }
    }
}
</style>
