<template>
    <div class="paddingTop50">
        <div class="back">
            <img
                src="../assets/images/ic24ArrowL.svg"
                alt=""
                @click="handler()"
            />
            <div class="title1-medium" style="align-content:center; height:100%; width:calc(100% - 62px)">{{ title }}</div>
            <a v-if="showRight" class="save" @click.prevent="handleRight">
                {{ labelRight }}
            </a>
            <!-- <a v-if="showSave" class="save" @click.prevent="handleSave">
                儲存
            </a> -->
            <a v-if="showDelete" :style="{pointerEvents: isCarrier ? 'auto':'none'}" class="delete" @click.prevent="handleDelete" >
                <img :src="isCarrier?require('@/assets/images/ic24TDelete.svg'):require('@/assets/images/ic24TNDelete.svg')" alt="">
            </a>
            <img
                style="pointer-events: none; opacity: 0;"
                v-show="!showDelete && !showRight"
                src="../assets/images/ic24ArrowL.svg"
                alt=""
                @click="handler()"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackOnTop',
    props: {
        title: {
            type: String,
            default: '',
        },
        showRight: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        labelRight: {
            type: String,
            default: '儲存',
        },
        showSave: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        isCarrier: {
            type: String,
            default: '',
        },
    },
    emits: ['click:right', 'click:save', 'click:delete'],
    methods: {
        handleRight() {
            this.$emit('click:right');
        },
        handleSave() {
            this.$emit('click:delete');
        },
        handleDelete() {
            this.$emit('click:delete');
        },
        handler() {
            if (this.url == '') this.$router.go(-1);
            else this.$router.push(this.url);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.back {
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    background-image: url('~@/assets/images/Bg.png');
    background-size: cover;
    background-position-y: top;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0px;
    z-index: 2;
    > img {
        height: 31px;
        margin-left: -6px;
    }

    a {
        &.save,
        &.delete {
            flex: 1;
            text-align: right;
        }
    }
}
.paddingTop50 {
    padding-top: 60px;
    width: 100%;
}
</style>
