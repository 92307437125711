import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: true,
        isChoose: true,
        isForget: false,
        isShowAlert: ''
    },
    mutations: {
        setIsChoose(state, value) {
            state.isChoose = value;
        },
        setIsForget(state, value) {
            state.isForget = value;
        },
    },
    actions: {},
    modules: {},
});
