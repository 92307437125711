<template>
    <v-main class="customMain">
        <BackOnTop title="忘記密碼" />
        <v-container fluid class="fp-container mb-4" style="padding-left: 32px; padding-right: 31px; margin-top: 14px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <FancyInput
                    v-model="phone"
                    label="請輸入手機號碼"
                    :is-required="true"
                    iconName="ic24Name.svg"
                    placeholder="請輸入"
                    :sizes="18"
                    :rules="[() => err_msg || !!phone || '請輸入正確手機']"
                    :isError="handleBlur(/^09\d{8}$/.test(phone) && err_msg.length === 0, phone)"
                    hide-details="true"
                    :wrongHint="err_msg.length !== 0 ? '此手機號碼非註冊會員，請回上一頁註冊會員':'請輸入正確手機'"
                    :isChecking="isChecking"
                    inputEl="forgetPhone"
                    required
                />
            </v-form>
        </v-container>
        <BtnSign :canClick="canNext" text="下一步" :style="{pointerEvents: canNext ? 'auto':'none', color: canNext ? 'white':'#6F6F6F', backgroundColor: canNext ? '#FF9F4B':'#F2F2F2'}" class="btn" @click="handleNext" />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import BtnSign from '@/components/BtnSign.vue';
import FancyInput from '@/components/FancyInput.vue';
import { checkPhoneExist } from '@/apis/api.js';
export default {
    name: 'ForgotPasswordIndex',
    components: { BackOnTop, BtnSign, FancyInput },
    data: () => ({
        isChecking: false,
        valid: '',
        phone: '',
        err_msg: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        handleBlur(error, item) {
            return error && !!item
        },
        handleNext() {
            const valid = this.$refs.form.validate();
            var parent = this;
            if (valid) {
                checkPhoneExist(this.phone).then(function (rsp) {
                    if (rsp) {
                        parent.$router.push(
                            '/forgot-password/otp?phone=' + parent.phone
                        );
                    } else {
                        parent.err_msg =
                            '此手機號碼非註冊會員，請回上一頁註冊會員';
                        parent.$refs.form.validate();
                    }
                });
            }
        },
    },
    computed: {
        canNext: function () {
            return !!this.phone
        },
    },
    watch: {
        phone: function () {
            this.err_msg = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.customMain {
    background-image: url('~@/assets/images/dog.png');
    background-size: contain;
    background-position: bottom left;
    height: 100%;
}
.btn {
  margin-top: auto;
  margin-bottom: 40px;
}
.fp-container {
    margin: 12px 0;
    // padding: 10px 0 0 0;
    p.desc {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
    }
}
</style>
