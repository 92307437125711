<template>
  <FancyDialog class="dialog" :isPadding="false" v-bind="{ ...$attrs }" v-on="$listeners">
    <!-- <div style="background-color: #0D0C0CB2; position: fixed; width: 100vh; height: 100vh; top: 0;z-index:-1;"></div> -->
    <v-container v-if="type === 'error'" fluid class="dialog_container">
      <img
        v-if="imgSrc == '@/assets/images/imgTick.svg'"
        width="90"
        height="90"
        class="mb-1"
        src="@/assets/images/imgTick.svg"
        alt=""
      />
      <img
        v-if="imgSrc == '@/assets/images/imgWarning.svg'"
        width="90"
        height="90"
        class="mb-1"
        src="@/assets/images/imgWarning.svg"
        alt=""
      />
      <p class="title mb-6">{{ errorMsg }}</p>
      <div class="footer d-flex justify-center">
        <FancyButton class="btn" label="確定" @click="handleClick" />
      </div>
    </v-container>
    <v-container v-if="type === 'password'" fluid class="dialog_container">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 28px;
          align-items: start;
          padding: 0 16px;
        "
      >
        <p class="large-title2-semibold" style="color: #ff9f4b">寄送預設密碼成功</p>
        <p class="body-regular" style="color: #0d0c0c">請使用預設密碼登入後，修改新密碼</p>
      </div>
      <div style="display: flex; gap: 16px;">
        <Button
          class="cancel_btn"
          text="取消"
          @click.prevent="handleClick"
        />
        <Button
          class="sure_btn"
          text="確認"
          bolder
          @click.prevent="handleClick"
        />
      </div>
    </v-container>
    <v-container v-if="type === 'logout'" fluid class="dialog_container">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 28px;
          align-items: start;
          padding: 0 16px;
        "
      >
        <p class="large-title2-semibold" style="color: #ff9f4b">確定登出?</p>
        <p class="body-regular" style="color: #0d0c0c">登出後將要重新登入</p>
      </div>
      <div style="display: flex; gap: 16px;">
        <Button
          class="cancel_btn"
          text="取消"
          @click.prevent="handleClick"
        />
        <Button
          class="sure_btn"
          text="確認"
          bolder
          @click.prevent="logout"
        />
      </div>
    </v-container>
    <v-container v-if="type === 'dmember'" fluid class="dialog_container">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 28px;
          align-items: start;
          padding: 0 16px;
        "
      >
        <p class="large-title2-semibold" style="color: #ff9f4b">刪除會員帳號申請</p>
        <p class="body-regular" style="color: #0d0c0c">若確定刪除帳號，請點擊「填寫表單」</p>
      </div>
      <div style="display: flex; gap: 16px;">
        <Button
          class="cancel_btn"
          text="取消"
          @click.prevent="handleClick"
        />
        <Button
          class="sure_btn"
          text="填寫表單"
          bolder
          @click.prevent="openWindow"
        />
      </div>
    </v-container>
    <v-container v-if="type === 'delete'" fluid class="dialog_container">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 28px;
          align-items: start;
          padding: 0 16px;
        "
      >
        <p class="large-title2-semibold" style="color: #ff9f4b">確定刪除?</p>
        <p class="body-regular" style="color: #0d0c0c">一旦刪除將無法復原，需重新新增</p>
      </div>
      <div style="display: flex; gap: 16px;">
        <Button
          class="cancel_btn"
          text="取消"
          @click.prevent="handleClick"
        />
        <Button
          text="確認"
          class="sure_btn"
          bolder
          @click.prevent="invoiceDelete"
        />
      </div>
    </v-container>
    <v-container v-if="type === 'success'" fluid class="dialog_container">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 28px;
          align-items: start;
          padding: 0 16px;
        "
      >
        <p class="large-title2-semibold" style="color: #ff9f4b">註冊成功</p>
        <p class="body-regular" style="color: #0d0c0c">請使用註冊的帳號密碼登入</p>
      </div>
      <div style="display: flex; gap: 16px;">
        <Button
          text="取消"
          style="
            background-color: #f2f2f2;
            color: #6f6f6f;
            border-radius: 18px;
            padding: 16px;
            width: 50%;
          "
          @click.prevent="handleClick"
        />
        <Button
          text="確認"
          bolder
          style="
            background-color: #ff9f4b;
            color: #fff;
            border-radius: 18px;
            padding: 16px;
            width: 50%;
          "
          @click.prevent="handleClick"
        />
      </div>
    </v-container>
  </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue'
import FancyButton from '@/components/FancyButton.vue'
import Button from '@/components/Atom/Button.vue'
export default {
  components: {
    FancyDialog,
    FancyButton,
    Button,
  },
  name: 'PopupAlert',
  props: {
    errorMsg: {
      type: String,
    },
    imgSrc: {
      type: String,
      default: '',
    },
    /**
     * @type {error, password, logout, dmember, delete, success}
     */
    type: {
      type: String,
      default: 'error',
    },
  },
  emits: ['input', 'click:confirm'],
  data: () => ({}),
  methods: {
    logout() {
      var parent = this
      this.showlogoutLB = false
      localStorage.removeItem('phone')
      localStorage.removeItem('password')
      localStorage.removeItem('member_no')
      setTimeout(function () {
        parent.$router.push('/login')
      }, 1000)
    },
    handleClick() {
      this.$emit('input', false)
    },
    openWindow() {
      window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSelxsUKoebdACMW-CV0NYxMzYT6DkkuyrWI_KNeCYW0nTlWDA/viewform'
      this.$emit('input', false)
    },
    invoiceDelete() {
      this.$emit('click:confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.sure_btn {
  background-color: #ff9f4b;
  color: #fff;
  border-radius: 18px;
  padding: 16px;
  width: 50%;
  &:active {
    background-color: #D16200;
  }
}
.cancel_btn {
  background-color: #f2f2f2;
  color: #6f6f6f;
  border-radius: 18px;
  padding: 16px;
  width: 50%;
  &:active {
    background-color: #D16200;
    color: white;
  }
}
div.dialog_container {
  position: relative;
  z-index: 1;
  padding: 32px 16px 24px 16px;
  p.title {
    color: $color_main_first;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  p.desc {
    font-size: 14px;
    line-height: 24px;
    color: $color_main_black;
  }
  > .footer {
    .btn {
      height: 50px;
      width: 100%;
      @media screen and (max-width: 374px) {
        max-width: 118px;
      }
      @media screen and (min-width: 375px) {
        max-width: 145.5px;
      }
    }
  }
}
</style>
