<template>
    <v-checkbox
        class="fancy check_contract"
        color="#FF9F4B"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        :input-value="value"
        @change="(changed) => $emit('input', changed)"
    >
        <template v-slot:label>
            <div style="display:flex;align-items:center;font-size: 12px;font-family:Noto Sans TC;">
                <p :style="{color: handleInput()?'#363230':'#D16200'}" style="margin:0;">我同意</p>
                <span
                    :style="{ textDecoration: 'none', color: '#D16200', fontSize: '12px', fontFamily: 'Noto Sans TC' }"
                    target="_blank"
                    @click="$router.push(contractLink)"
                >
                    服務條款
                </span>
                <span style="font-size: 10px;font-family:Noto Sans TC;color:#909090">
                    為了確保您的權益，請務必詳閱服務條款
                </span>
            </div>
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
export default {
    name: 'FancyCheckContract',
    inheritAttrs: false,
    props: {
        label: String,
        contractLink: String,
        value: null,
        wrong: Boolean,
        isChecking: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isFirst: true,
    }),
    computed: {
        commonAttrs() {
            return {};
        },
    },
    methods: {
        handleInput(){
            if(this.hideCheck) { return }
            if(this.isFirst && !this.isChecking){
                return this.isFirst
            }
            return this.isError
        },
    }
};
</script>

<style lang="scss">
div.fancy.check_contract {
    &.v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 0px !important;

        > .v-input__control {
            align-items: start !important;
            justify-content: start !important;
            > .v-input__slot {
                width: auto;
                .v-label {
                    > p {
                        margin-bottom: 0;
                        color: #909090;
                        font-size: 16px;
                        line-height: 19px;
                        > a {
                            color: $color_main_first;
                            text-decoration: underline;
                        }
                    }
                }
                > .v-input--selection-controls__input {
                    .v-icon {
                        &.error--text {
                            // color: $color_main_first !important;
                        }
                    }
                }
            }
            > .v-messages {
                color: #909090;
                font-size: 12px;

                &.error--text {
                    color: $color_main_red !important;
                    > .v-messages__wrapper {
                        padding-left: 0px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        &:before {
                            content: '';
                            background-image: url('~@/assets/images/ic24Warning.svg');
                            background-size: 12px 12px;
                            height: 12px;
                            width: 12px;
                            margin-right: 4px;
                        }
                        > .v-messages__message {
                            font-size: 8px;
                            color: $color_main_red;
                        }
                    }
                }

                * {
                    line-height: 1.6 !important;
                }
            }
        }
    }
}
</style>
