<template>
    <v-main class="customMain" ref="customMain">
        <BackOnTop title="簡訊驗證" />
        <PopupTop :is-show="showAlert" :errorMsg="errorMsg" />
        <div class="dflex">
            <v-container class="fp-container otp px-8 mb-4">
                <p class="subject mb-4 large-title-medium">輸入您<br />收到的驗證碼</p>
                <p class="desc mb-5 body-regular">
                    已傳送到您的手機{{ formatPhone }}<br />
                    若還沒有收到請按重新傳送<br />
                    若無法收到驗證碼，請聯繫大樹寵物官網客服
                </p>
                <p class="resend mb-8 body-regular" :class="{ cd: isCD }" @click="cdTimer(true)">
                    重新發送驗證碼
                    {{ isCD ? '00:' + cd.toString().padStart(2, '0') : '' }}
                </p>

                <FancyOTP v-model="otp" />
            </v-container>
        </div>
        <BtnSign
            :canClick="canNext" 
            :style="{pointerEvents: canNext ? 'auto':'none', color: canNext ? 'white':'#6F6F6F', backgroundColor: canNext ? '#FF9F4B':'#F2F2F2'}"
            class="btn mt-9"
            text="送出"
            @click="checkVerifyCode()"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue'
import BtnSign from '@/components/BtnSign.vue'
import PopupTop from '@/components/Popup/PopupTop.vue'
import FancyOTP from '@/components/FancyOTP.vue'
import { sendVerifyCode, checkVerifyCode, resetPassword } from '@/apis/api.js'
export default {
    name: 'ForgotPasswordOTP',
    components: { BackOnTop, BtnSign, FancyOTP, PopupTop },
    beforeCreate() {
        this.$store.state.isLoading = true
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false
        })
    },
    data: () => ({
        otp: '',
        imgSrc: '',
        isSuccessOpened: false,
        isCD: true,
        cd: 60,
        showAlert: false,
        errorMsg: '',
        phone: '0912345678',
    }),
    created() {
        this.cdTimer(true)
        sendVerifyCode(this.$route.query.phone).then(function (rsp) {
            if (rsp) {
                console.log('發送成功');
            }
        });
    },
    methods: {
        cdTimer(start) {
            var parent = this
            if (start && !this.isCD) {
                sendVerifyCode(this.$route.query.phone).then(function (rsp) {
                    if (rsp) {
                        console.log('發送成功')
                        parent.showAlert = true
                        parent.errorMsg = '已重新寄送，請重新輸入'
                        setTimeout(() => {
                            parent.showAlert = false
                        }, 3000)
                    }
                })
                this.isCD = true
                this.cd = 60
            }
            if (this.cd > 0) {
                setTimeout(() => {
                    this.cd -= 1
                    this.cdTimer()
                }, 1000)
            }

            if (this.cd == 0) {
                this.isCD = false
            }
        },
        checkVerifyCode() {
            var parent = this
            checkVerifyCode(this.$route.query.phone, parent.otp).then(function (rsp) {
                if (rsp) {
                    console.log('驗證成功')
                    resetPassword(parent.$route.query.phone).then(function (rsp) {
                        if (rsp) {
                            // parent.$router.push(
                            //     '/forgot-password/reset?phone=' + parent.$route.query.phone
                            // )
                            parent.$store.commit('setIsForget',true)
                            parent.$router.push('/login');
                        }
                    })
                    // parent.$router.push('/login');
                } else {
                    parent.showAlert = true
                    parent.errorMsg = '驗證失敗，已重新寄送，請重新輸入'
                    // parent.imgSrc = '@/assets/images/imgWarning.svg';
                    parent.$store.state.isLoading = false
                    setTimeout(() => {
                        parent.showAlert = false
                    }, 3000)
                }
            })
        },
    },
    computed: {
        formatPhone() {
            return this.$route.query.phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 - $2 - $3')
        },
        canNext: function () {
            return !!this.otp;
        },
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.customMain {
    background-image: url('~@/assets/images/dog.png');
    background-size: contain;
    background-position: bottom left;
    height: 100% !important;
}
.btn {
  margin-top: auto;
  margin-bottom: 40px;
}
.dflex {
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}
.fp-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    &.otp {
        text-align: left;
        p {
            &.subject {
                font-size: 22px;
                color: #0d0c0c;
            }
            &.desc {
                font-size: 14px;
                color: #6f6f6f;
            }
            &.resend {
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                color: #ff453a;
                text-decoration: underline;
                &.cd {
                    color: #d9d9d9;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
