<template>
    <div class="service-box">
        <v-row cols="12">
            <v-col
                cols="6"
                class="text-left service-col"
                v-for="(service, idx) in serviceList"
                :key="idx"
            >
                <img
                    width="8"
                    height="8"
                    src="@/assets/images/dot.svg"
                    alt=""
                />
                <p>{{ service }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SalonServiceBox',
    props: {
        serviceList: {
            type: Array,
        },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.service-box {
    background: #f9f6f4;
    border-radius: 16px;
    padding: 24px 36px 12px;
}
.service-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 12px 0;
    img {
        margin: 0 12px 0 0;
    }
}
p {
    color: $color_main_first;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.row + .row {
    margin-top: 8px;
}
</style>
