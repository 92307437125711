<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="資料確認" />
        <SalonSubtitle textLeft="即將完成囉! 請確認以下資料~" />
        <v-container fluid class="salon-container pt-4 px-4 text-left">
            <SalonInfoBox :infoList="infos" />
            <h2>預約服務項目</h2>
            <SalonServiceBox class="s-box" :serviceList="services" />
            <FancyCheckContract
                v-model="isAgreeMemberContract"
                contractLink="/member/contract"
                hint="為了確保您的自身權益，請務必詳閱服務條款"
                :rules="[
                    () =>
                        !!isAgreeMemberContract ||
                        '為了確保您的自身權益，請務必詳閱服務條款',
                ]"
                persistent-hint
            />
            <SalonBottomButton label="完成" @click="goNext()" />
        </v-container>
        <PopupOrderSuccess
            v-model="showOrderSuccess"
            @click:confirm="$router.push('/')"
        />
        <PopupOrderError
            v-model="showOrderError"
            @click:confirm="switchOrderError(false)"
        />
        <PopupOrderFail
            v-model="showOrderFail"
            :phone="phone"
            @click:confirm="switchOrderFail(false)"
        />
        <PopupOrderUnable
            v-model="showOrderUnable"
            :phone="phone"
            @click:confirm="switchOrderUnable(false)"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import SalonSubtitle from '@/components/SalonSubtitle.vue';
import SalonBottomButton from '@/components/SalonBottomButton.vue';
import SalonInfoBox from '@/components/SalonInfoBox.vue';
import SalonServiceBox from '@/components/SalonServiceBox.vue';
import FancyCheckContract from '@/components/FancyCheckContract.vue';
import PopupOrderSuccess from '@/components/Popup/PopupOrderSuccess.vue';
import PopupOrderFail from '@/components/Popup/PopupOrderFail.vue';
import PopupOrderError from '@/components/Popup/PopupOrderError.vue';
import PopupOrderUnable from '@/components/Popup/PopupOrderUnable.vue';

import { getUserInfo, updateReserve, KgGetStore } from '@/apis/api.js';

export default {
    name: 'SalonReservedConfirm',
    components: {
        BackOnTop,
        PageTitleBar,
        SalonSubtitle,
        SalonBottomButton,
        SalonInfoBox,
        SalonServiceBox,
        FancyCheckContract,
        PopupOrderSuccess,
        PopupOrderFail,
        PopupOrderError,
        PopupOrderUnable,
    },
    data: () => ({
        showOrderSuccess: false,
        isAgreeMemberContract: false,
        showOrderError: false,
        showOrderFail: false,
        showOrderUnable: false,
        infos: [
            { key: '會員姓名', value: 'Scott' },
            { key: '聯絡電話', value: '0912344543' },
            { key: '寶貝姓名', value: '大白' },
            { key: '預約門市', value: '八德興豐店' },
            { key: '預約日期', value: '2021/06/18   14:00 ' },
        ],
        services: ['剪毛', '剪指甲', '剪毛', '(加購)spa護膚'],
        query: {},
        phone: '',
        serviceValueDict: {
            洗澡: {
                app: 0,
                kg: [1],
            },
            // '草本SPA':{
            //     app:7,
            //     kg:[2]
            // },
            剪毛: {
                app: 1,
                kg: [3],
            },
            游泳: {
                app: 2,
                kg: [4],
            },
            '洗澡+SPA': {
                app: 3,
                kg: [1, 2],
            },
            '洗澡+游泳': { app: 4, kg: [1, 4] },
            '剪毛+SPA': { app: 5, kg: [2, 3] },
            '剪毛+游泳': { app: 6, kg: [3, 4] },
        },
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    created() {
        var parent = this;
        this.query = this.$route.query;
        this.services = decodeURIComponent(this.query.type).split('+');
        if (this.query.PBS_Svr05 == 'Y') {
            this.services.push('基礎美容');
        }
        this.infos = [
            { key: '會員姓名', value: '' },
            { key: '聯絡電話', value: this.query.phone },
            {
                key: '寶貝姓名',
                value: decodeURIComponent(this.query.baby).split(' ')[1],
            },
            {
                key: '預約門市',
                value: decodeURIComponent(this.query.store).split(' ')[1],
            },
            {
                key: '預約日期',
                value: this.query.date + '\t' + this.query.time + ':00',
            },
        ];
        getUserInfo(
            localStorage.getItem('phone'),
            localStorage.getItem('password')
        ).then(function (rsp) {
            if (rsp) {
                parent.infos[0].value = rsp.c_name;
            }
        });
        KgGetStore(localStorage.getItem('phone')).then(function (rsp) {
            if (rsp) {
                console.log(rsp);
                for (let i in rsp) {
                    if (
                        rsp[i].STO_No ==
                        decodeURIComponent(parent.query.store).split(' ')[0]
                    ) {
                        parent.phone = `+886${rsp[i].STO_Tel.replace('(', '')
                            .replace(')', '')
                            .replace('-', '')
                            .replace('0', '')}`;
                    }
                }
            }
        });
    },
    methods: {
        goNext() {
            // var valid = this.$refs.form.validate();
            var parent = this;
            // 幾天前可修改預約
            var num = 0;
            var numberOfMlSeconds = new Date().getTime();
            var newDateObj = new Date(
                numberOfMlSeconds + 1000 * 60 * 60 * 24 * num
            );
            var start_date = `${newDateObj.getFullYear()}${
                newDateObj.getMonth() + 1 < 10 ? '0' : ''
            }${newDateObj.getMonth() + 1}${
                newDateObj.getDate() < 10 ? '0' : ''
            }${newDateObj.getDate()}`;
            console.log('start_date', start_date);
            if (this.isAgreeMemberContract && !this.$store.state.isLoading) {
                this.$store.state.isLoading = true;
                if (start_date < this.query.ori_c_resv_date.replace(/-/g, '')) {
                    updateReserve(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password'),
                        localStorage.getItem('member_no'),
                        this.infos[0].value,
                        decodeURIComponent(this.query.baby).split(' ')[0],
                        decodeURIComponent(this.query.baby).split(' ')[1],
                        this.query.date.replace(/-/g, ''),
                        decodeURIComponent(this.query.store).split(' ')[0],
                        this.query.time,
                        this.serviceValueDict[
                            decodeURIComponent(this.query.type)
                        ].app,
                        this.query.reserve_no,
                        this.query.ori_store_no,
                        this.query.PBS_Svr05
                    ).then(function (rsp) {
                        parent.$store.state.isLoading = false;
                        if (rsp == 0) {
                            parent.switchOrderSuccess(true);
                        } else if (rsp == 1) {
                            parent.switchOrderFail(true);
                        } else if (rsp == 2) {
                            parent.switchOrderError(true);
                        } else {
                            parent.switchOrderUnable(true);
                        }
                    });
                } else {
                    parent.$store.state.isLoading = false;
                    parent.switchOrderUnable(true);
                }
            }
        },
        switchOrderFail(isShow) {
            this.showOrderFail = isShow;
        },
        switchOrderSuccess(isShow) {
            this.showOrderSuccess = isShow;
        },
        switchOrderError(isShow) {
            this.showOrderError = isShow;
        },
        switchOrderUnable(isShow) {
            this.showOrderUnable = isShow;
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
    h2 {
        margin: 28px 0 23px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
    .s-box {
        margin: 0 0 35px 0;
    }
}
</style>
