<template>
    <v-radio-group
        class="fancy"
        :class="{ column: flexDirection === 'column' }"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        :value="value"
        @change="(changed) => $emit('input', changed)"
    >
        <v-radio
            v-for="(item, index) in items"
            :key="index"
            :label="item.label"
            :value="item.value"
        >
        </v-radio>

        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-radio-group>
</template>

<script>
export default {
    name: 'FancyRadio',
    inheritAttrs: false,
    props: {
        iconName: String,
        items: Array,
        value: null,
        flexDirection: String,
    },
    mounted() {
        // console.log(this.$attrs);
    },
    computed: {
        commonAttrs() {
            return {};
        },
    },
};
</script>

<style lang="scss" scoped>
div.fancy {
    &.v-input--radio-group {
        > .v-input__control {
            > .v-input__slot {
                margin-bottom: 0;
                width: 100%;
                > .v-input--radio-group__input {
                    > .v-radio {
                        margin-right: 12px;
                        .v-input--selection-controls__input {
                            margin-right: 4px;
                            .v-icon {
                                color: $color_main_first;
                            }
                        }
                        .v-label {
                            color: #000000;
                        }
                    }
                }
            }
            > .v-input--radio-group__details {
                padding-left: 4px !important;
                margin-bottom: 0px;
                > .v-messages {
                    > .v-messages__wrapper {
                        padding-left: 32px;
                    }
                    &.error--text {
                        color: $color_main_red !important;
                        > .v-messages__wrapper {
                            padding-left: 0px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-items: center;
                            &:before {
                                content: '';
                                background-image: url('~@/assets/images/ic24Warning.svg');
                                background-size: 12px 12px;
                                height: 12px;
                                width: 12px;
                                margin-right: 4px;
                            }
                            > .v-messages__message {
                                font-size: 8px;
                                color: $color_main_red;
                            }
                        }
                    }
                }
            }
        }
        &.column {
            .v-input--radio-group__input {
                flex-direction: column;
                .v-radio {
                    margin-bottom: 4px;
                }
            }
            .v-messages {
                margin: 4px 0 0;
            }
        }

        &.f_sm {
            .v-label {
                color: #0d0c0c;
                font-size: 14px;
            }
            .v-radio {
                margin-bottom: 4px !important;
            }
        }
    }
}
</style>
