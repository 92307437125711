<template>
    <v-main>
        <BackOnTop title="寵物美容住宿游泳服務條款" />
        <v-container
            class="contract-container pt-3 px-8 mt-3 mb-4"
            style="text-align: left; margin: 0 auto"
        >
            <div style="width: 90%; margin: 0 auto">
                <div
                    class="version style-title-m"
                    style="color: #ff9f4b; margin-bottom: 20px"
                >
                    版本2.1_20220309
                </div>
                <div class="style-body-regular">
                    <p class="mb-6">
                        感謝您接受 [大樹寵物]
                        提供的美容、住宿、游泳服務項目，為確保雙方的權利及義務，請您仔細詳閱、瞭解以下服務條款內容:
                    </p>
                    <ul>
                        <li>
                            本人已 (年滿二十歲) 同意將寵物送至大樹寵物
                            (以下簡稱貴公司)接受美容、住宿、游泳服務項目，並委由貴公司依其專業全權處理相關事宜，因此，本人已確認自行所提供之個人資料，寵物名稱，寵物狀況..等相關資訊均完整及正確無誤。
                        </li>
                        <li>
                            寵物如有包括但不限於先天性疾病、傳染疾病、心臟等疾病或老年退化等特別情形，本人應在自家寵物接受美容、住宿、游泳服務前據實告知貴公司寵物相關情況，如有任何隱匿、遺漏不為說明或為不實說明之情事，以致寵物於服務期間內發生意外或死亡等事故，須由本人自負所有費用及責任，貴公司如因此受有任何損害，亦由本人負責賠償。*雙方如有爭議時，以桃園地方法院為第一審管轄法院訴訟。
                        </li>
                        <li>
                            寵物於服務過程中如有任何緊急情事，本人無條件同意貴公司得將寵物送至貴公司配合的動物醫院，並由本人負擔因此所生之任何費用(包括但不限於診療、手術及住院費用等)。
                        </li>
                        <li>
                            寵物游泳服務前注意須知，以下內容說明:
                            <ul>
                                <li>
                                    寵物游泳前須充分瞭解【大樹寵物】美容、住宿、游泳條款與須知說明。
                                </li>
                                <li>
                                    初次使用游泳服務需先加入會員，並請攜帶寵物施打疫苗手冊，超過一年未施打疫苗(含狂犬病)將無法使用游泳服務。
                                </li>
                                <li>
                                    寵物半年內有使用過體內外驅蟲藥，並定期使用除蚤藥品，48小時內使用體內外用藥的寵物不得下水游泳。
                                </li>
                                <li>
                                    寵物身體狀況，包括但不限於身上有外傷傷口，皮膚病，寄生蟲及蟲卵，生理期，疫苗施打未滿7日，曾有哮喘，心血管病史，癲癇病史，半年內有骨折或脫臼病史，為了寵物自身安全嚴禁下水游泳。
                                </li>
                                <li>
                                    寵物游泳下水前須讓工作人員充分瞭解寵物狀況或個性
                                    <br />(包括但不限於-是否怕水，膽小，怕生，與其他注意事項告知….等)
                                </li>
                                <li>
                                    寵物游泳下水前須空腹3小時，避免發生胃扭轉等的狀況。
                                </li>
                                <li>
                                    寵物游泳下水前須完成排泄，避免因緊張導致腹瀉等狀況；若因此造成本館營業或設施備損失，【大樹寵物】有權要求飼主須負擔相關費用(以當次消費費用最高兩倍計之)。
                                </li>
                                <li>
                                    寵物下水游泳過程中，發現身體不適症狀或攻擊伴游人員之行為，將立即停止游泳服務。
                                </li>
                                <li>
                                    寵物游泳服務開放預約制，預約當日保留10分鐘緩衝，若超過預約時間，將以現場預約狀況延期或取消。
                                </li>
                                <li>
                                    同時段僅能有一隻寵物使用游泳服務，最多兩隻(需為一家人)。
                                </li>
                                <li>
                                    寵物游泳上限時間為15分鐘，過程已消耗大量體力，恕不接受加價超時。
                                </li>
                                <li>
                                    寵物游泳結束後，貴公司備有烘箱提供寵物毛髮烘乾服務，應在游泳前本人應主動告知美容室作業人員，若有特定因素本人有義務告知不能使用烘箱，並同意改為洗澡及手吹等美容服務進行加價作業，若本人不同意前述服務變更，貴公司可無條件拒絕服務。<br />
                                    備註:烘箱僅提供寵物毛髮至八分乾，烘乾時可能造成寵物毛髮乾燥打結，建議飼主返家後立即替愛犬梳洗並吹乾。
                                </li>
                            </ul>
                        </li>
                        <li>
                            貴公司應給予美容、住宿、游泳之寵物最細心呵護照料及服務，惟本人瞭解活體動物之事故實非人力能掌控，同意貴公司就寵物年老所生的事故不負賠償責任，其餘事故之賠償責任上限則為新臺幣二萬元整。
                        </li>
                        <li>
                            本人若未於貴公司通知的帶領時間內領回寵物，本人應負擔寵物因此所生的費用(包括但不限於保管住宿照顧費及飼料費.等)；本人若未經貴公司事前同意，而逾前述帶領時間達1日(含)以上者，如電話不通，不聞不問該寵物視同棄養，貴公司得逕行將該寵物交付政府主管機關，並依照任意棄養相關法規送辦。
                        </li>
                        <li>
                            本人同意不棄養寵物，如違反動物保護法第五條第三項規定，本人願接受政府主管機關之動物保護法第二十九條規定處以新臺幣三萬以上十五萬以下罰緩。<br />
                            (備註:動物保護法如有更新或更改條款之規定，依照最新相關事宜依法送辦)。
                        </li>
                        <li>
                            貴公司有權修改、更新本服務條款並逕行於網站公告之。本人應於接受美容、住宿、游泳服務前先行檢視本服務條款的所有內容，本人如於本服務條款修改、更新後繼續接受美容、住宿、游泳服務(無論係簽署服務同意書或服務單)者，即視為本人已詳閱、瞭解並同意該等修改、更新。
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <FancyButton
                label="同意"
                width="150"
                height="48"
                @click="$router.push('/register/pet')"
            /> -->
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
// import FancyButton from '@/components/FancyButton.vue';

export default {
    name: 'MemberContract',
    components: { BackOnTop },
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.contract-container {
    background-image: url('~@/assets/images/logo_background.png');
    background-size: 75%;
    background-position-x: right;
    background-position-y: 35vh;
}
.member-container {
    padding-bottom: 36px;
    * {
        font-size: 14px;
        line-height: 1.4;
    }
    .version {
        text-align: left;
    }
    h5 {
        font-weight: bold;
        font-size: 20px;
        color: $color_main_black;
    }
    h6 {
        font-weight: bold;
    }
    .contract {
        color: $color_main_black;
        font-size: 14px !important;
        line-height: 24px !important;
        text-align: left;
        margin-bottom: 36px;
        > ul {
            list-style-type: cjk-ideographic;
            counter-reset: li;
            padding-left: 30px;
            list-style: none;
            > li {
                counter-increment: li;
                list-style-type: cjk-ideographic;
                position: relative;
                margin-bottom: 10px;

                > ul {
                    counter-reset: a;
                    padding-left: 30px;
                    list-style: none;

                    > li {
                        position: relative;
                        margin-bottom: 10px;

                        &::before {
                            counter-increment: a;
                            content: '(' counter(a, cjk-ideographic) ')';
                            position: absolute;
                            left: 0;
                            transform: translate(-120%, 0);
                        }
                    }
                }
            }
        }
    }
}
</style>
