<template>
    <v-container>
        <BackOnTop :url="url" title="美容日誌" />

        <div
            class="col-12"
            style="
                z-index: 3;
                position: fixed;
                top: 50px;
                background-color: white;
                padding-top: 16px;
                padding-left: 32px;
                padding-right: 32px;
            "
        >
            <div class="col-12 row">
                <div
                    :class="`col-6 ${
                        tabSelect == 'reserve'
                            ? 'style-active-tab'
                            : 'style-inactive-tab'
                    }`"
                    style="background-color: white"
                    @click="
                        tabSelect = 'reserve';
                        currentPage = 1;
                        isScrollDown = false;
                    "
                >
                    我的預約
                </div>
                <div
                    :class="`col-6 ${
                        tabSelect == 'record'
                            ? 'style-active-tab'
                            : 'style-inactive-tab'
                    }`"
                    style="background-color: white"
                    @click="
                        tabSelect = 'record';
                        currentPage = 1;
                        isScrollDown = false;
                    "
                >
                    美容紀錄
                </div>
            </div>
            <!-- <transition> -->
            <div
                class="row"
                :style="`margin-bottom: 16px;opacity:${isScrollDown ? 0 : 1}`"
            >
                <div style="width: 47.5%; margin-right: 5%">
                    <FancySelectBottom
                        v-if="timeSelectOptions.length"
                        defaultLabel="由近到遠"
                        v-model="timeSelect"
                        :options="timeSelectOptions"
                        :initial="0"
                        :icon="require('@/assets/images/order-icon.svg')"
                        isTime
                    />
                </div>
                <div style="width: 47.5%">
                    <FancySelectBottom
                        v-if="petList.length"
                        defaultLabel="請選擇寶貝"
                        v-model="petSelect"
                        :options="petList"
                        isTime
                    />
                </div>
            </div>
            <!-- </transition> -->
        </div>
        <v-container
            fluid
            class="salon-container px-8 mb-4"
            :style="'margin-top: 130px;'"
        >
            <v-container
                fluid
                class="d_flex justify-space-between mb-6"
                style="padding-bottom: 20px"
            >
                <div
                    class="col-12"
                    v-if="
                        tabSelect == 'reserve' &&
                        (reserve_list.length || beauty_list.length) &&
                        !$store.state.isLoading
                    "
                >
                    <div class="col-12" ref="listPage" @scroll="handleScroll">
                        <div
                            class="col-12"
                            v-for="(info, index) in beauty_list"
                            :key="index"
                        >
                            <div
                                :class="`style-salon-history-beauty ${getRecordStatusClass(
                                    info.status
                                )}`"
                                @click.prevent="$router.push(info.url)"
                            >
                                <div class="col-12" style="margin-bottom: 18px">
                                    <div
                                        class="row"
                                        style="
                                            justify-content: space-between;
                                            width: 100%;
                                        "
                                    >
                                        <div class="style-title-m">
                                            {{ info.service_list.join('、') }}
                                            {{ info.name }}
                                        </div>
                                        <div
                                            :class="`style-body-regular style-salon-status ${getStatusClass(
                                                info.status
                                            )}`"
                                        >
                                            {{ info.status }}
                                        </div>
                                    </div>
                                    <div
                                        class="style-body-regular"
                                        style="letter-spacing: 0.56px"
                                    >
                                        {{ info.time }}
                                        {{
                                            info.hour.split(':')[0] >= 12
                                                ? '下午'
                                                : '上午'
                                        }}{{ info.hour }}
                                    </div>
                                    <div class="style-body-regular">
                                        門市 {{ info.store }}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="style-body-regular"
                                        :style="`margin-bottom:-15px`"
                                    >
                                        <div
                                            v-for="(
                                                PBS_DTL_INFO, index
                                            ) in info.PBS_DTL_INFO"
                                            class="service_item"
                                            style="display: flex"
                                            :key="index"
                                        >
                                            <div
                                                style="
                                                    max-height: 20px;
                                                    min-height: 20px;
                                                "
                                            >
                                                <div>
                                                    <div
                                                        :style="`width: 8px;height: 8px;background-color: ${
                                                            PBS_DTL_INFO.PBS_SvrBegTime
                                                                ? '#ff9f4b'
                                                                : '#ccc'
                                                        };border-radius: 100%;position:relative;z-index:2`"
                                                    ></div>
                                                    <div
                                                        :style="`background-color: ${
                                                            !PBS_DTL_INFO.PBS_SvrBegTime ||
                                                            (PBS_DTL_INFO.PBS_SvrBegTime &&
                                                                info
                                                                    .PBS_DTL_INFO[
                                                                    index + 1
                                                                ] &&
                                                                !info
                                                                    .PBS_DTL_INFO[
                                                                    index + 1
                                                                ]
                                                                    .PBS_SvrBegTime)
                                                                ? '#ccc'
                                                                : '#FF9F4B'
                                                        };z-index:1;width: 2px;height: 18px;position:relative;left: 3px;bottom: 2px;${
                                                            index ==
                                                            info.PBS_DTL_INFO
                                                                .length -
                                                                1
                                                                ? 'opacity:0;'
                                                                : ''
                                                        }`"
                                                    ></div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    PBS_DTL_INFO.PBS_SvrBegTime
                                                "
                                                :style="
                                                    info.PBS_DTL_INFO[
                                                        index + 1
                                                    ] &&
                                                    !info.PBS_DTL_INFO[
                                                        index + 1
                                                    ].PBS_SvrBegTime
                                                        ? 'width:65px;position:relative;bottom:6px;color:#FF9F48;font-weight:700;font-size:14px;padding-left: 13px;padding-right: 18px;max-height: 20px;min-height: 20px;'
                                                        : 'width:65px;position:relative;bottom:4px;font-size:12px;padding-left: 13px;padding-right: 18px;max-height: 20px;min-height: 20px;'
                                                "
                                            >
                                                {{
                                                    PBS_DTL_INFO.PBS_SvrBegTime.slice(
                                                        8,
                                                        10
                                                    )
                                                }}:{{
                                                    PBS_DTL_INFO.PBS_SvrBegTime.slice(
                                                        10,
                                                        12
                                                    )
                                                }}
                                            </div>
                                            <div
                                                v-else
                                                style="
                                                    width: 65px;
                                                    padding-left: 13px;
                                                    padding-right: 18px;
                                                    position: relative;
                                                    bottom: 14px;
                                                    max-height: 20px;
                                                    min-height: 20px;
                                                "
                                            ></div>
                                            <div
                                                :style="
                                                    info.PBS_DTL_INFO[
                                                        index + 1
                                                    ] &&
                                                    !info.PBS_DTL_INFO[
                                                        index + 1
                                                    ].PBS_SvrBegTime &&
                                                    info.PBS_DTL_INFO[index]
                                                        .PBS_SvrBegTime
                                                        ? 'position:relative;bottom:8px;color:#FF9F48;font-weight:700;font-size:14px;max-height: 20px;min-height: 20px;'
                                                        : 'position:relative;bottom:6px;font-size:12px;max-height: 20px;min-height: 20px;'
                                                "
                                            >
                                                {{ PBS_DTL_INFO.PBS_WrkName }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12"
                            v-for="(info, index) in reserve_list"
                            :key="index"
                        >
                            <div
                                :class="`style-salon-history-reserve ${getRecordStatusClass(
                                    info.status
                                )}`"
                                @click.prevent="$router.push(info.url)"
                            >
                                <div
                                    class="row"
                                    style="
                                        justify-content: space-between;
                                        width: 100%;
                                    "
                                >
                                    <div class="style-title-m">
                                        {{ info.service_list.join('、') }}
                                        {{ info.name }}
                                    </div>
                                    <div
                                        :class="`style-body-regular style-salon-status ${getStatusClass(
                                            info.status
                                        )}`"
                                    >
                                        {{ info.status }}
                                    </div>
                                </div>
                                <div
                                    class="style-body-regular"
                                    style="letter-spacing: 0.56px"
                                >
                                    {{ info.time }}
                                    {{
                                        info.hour.split(':')[0] >= 12
                                            ? '下午'
                                            : '上午'
                                    }}{{ info.hour }}
                                </div>
                                <div class="style-body-regular">
                                    門市 {{ info.store }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12"
                    v-else-if="
                        tabSelect == 'record' &&
                        history.length &&
                        !$store.state.isLoading
                    "
                >
                    <div
                        class="col-12"
                        v-for="(info, index) in history"
                        :key="index"
                    >
                        <div
                            :class="`style-salon-history-record `"
                            @click.prevent="$router.push(info.url)"
                        >
                            <div style="display: flex; align-items: center">
                                <div
                                    class="style-order-history-img"
                                    :style="{
                                        'background-image': `url(${info.img})`,
                                    }"
                                ></div>
                                <div>
                                    <div class="style-title-m">
                                        {{ info.service_list.join('、') }}
                                        {{ info.name }}
                                    </div>
                                    <div
                                        class="style-body-regular"
                                        style="
                                            letter-spacing: 0.56px;
                                            color: #6f6f6f;
                                            margin-top: 8px;
                                        "
                                    >
                                        {{ info.time }}
                                        {{
                                            info.hour.split(':')[0] >= 12
                                                ? '下午'
                                                : '上午'
                                        }}{{ info.hour }}
                                    </div>
                                    <div
                                        class="style-body-regular"
                                        style="color: #6f6f6f; margin-top: 8px"
                                    >
                                        門市 {{ info.store }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BabyPhoto
                    v-else-if="!$store.state.isLoading"
                    :imgSrc="require('@/assets/images/new-logo.png')"
                    title="尚未有美容紀錄"
                />
            </v-container>
        </v-container>
        <PopupGeneralAlert
            v-model="showAlert"
            errorTitle="伺服器忙碌中"
            :errorMsg="['抱歉，目前伺服器忙碌中，請稍後重試。']"
            @click:confirm="showAlert = false"
        />
    </v-container>
</template>

<script>
import BabyPhoto from '@/components/BabyPhoto.vue';
import FancySelectBottom from '@/components/NewStyle/FancySelectBottom.vue';
import BackOnTop from '@/components//NewStyle/BackOnTop.vue';
import PopupGeneralAlert from '@/components/NewPopup/PopupGeneralAlert.vue';

import {
    KgGetStore,
    KgGetReserveList,
    KgGetPetList,
    KgGetReserve,
    getReserveList,
    KgGetBeauty,
    syncPetData,
    isPetExist,
    KgGetSheetList,
    // getPetImage,
    getPictList,
} from '@/apis/api.js';
export default {
    name: 'SalonHistory',
    components: {
        BabyPhoto,
        FancySelectBottom,
        PopupGeneralAlert,
        BackOnTop,
    },
    data: () => ({
        showAlert: false,
        currentPage: 1,
        tabSelect: 'reserve',
        url: '/',
        prev: '<',
        next: '>',
        storeDict: {},
        sheet: true,
        selectedTime: 0,
        timeSelect: '1',
        isScrollDown: false,
        optionsTime: ['7天', '14天', '1個月', '2個月'],
        selectedType: '',
        optionsType: ['全部', '單點', '套餐'],
        selectedStatus: '',
        optionsStatus: ['未確認', '已預約', '已取消', '美容中', '已完成'],
        petDict: {},
        petSelect: '',
        statusDict: {
            0: '未確認',
            3: '已預約',
            4: '已取消',
            S2: '已完成',
            S3: '已完成',
            S1: '美容中',
        },
        ori_history: [],
        ori_reserve_list: [],
        history: [],
        reserve_list: [],
        initial: false,
        own_reserve_list: [],
        timeSelectOptions: [
            {
                text: '由近到遠',
                value: '1',
            },
            {
                text: '由遠到近',
                value: '2',
            },
        ],
        petList: [],
        petImageObj: {},
        ori_beauty_list: [],
        beauty_list: [],
        history_page: 1,
        reserve_page: 1,
        start_date: '',
        end_date: '',
        special_list: [],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            var parent = this;
            // 起始日 結束日為前後各60天
            var numberOfMlSeconds = new Date().getTime();
            var newDateObj = new Date(
                numberOfMlSeconds - 1000 * 60 * 60 * 24 * 60
            );
            this.start_date =
                newDateObj.getFullYear().toString() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1).toString()
                    : (newDateObj.getMonth() + 1).toString()) +
                (newDateObj.getDate() < 10
                    ? ('0' + newDateObj.getDate()).toString()
                    : newDateObj.getDate().toString());
            newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
            this.end_date =
                newDateObj.getFullYear().toString() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1).toString()
                    : (newDateObj.getMonth() + 1).toString()) +
                (newDateObj.getDate() < 10
                    ? '0' + newDateObj.getDate().toString()
                    : newDateObj.getDate().toString());
            setTimeout(function () {
                if (parent.$store.state.isLoading) {
                    parent.showAlert = true;
                }
            }, 1000 * 7);
            console.log(this.$route.query.selectedTime);
            syncPetData(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function () {
                KgGetStore(localStorage.getItem('phone')).then(function (rsp) {
                    for (let i in rsp) {
                        parent.storeDict[rsp[i].STO_No] = rsp[i].STO_Name;
                    }
                    KgGetPetList(
                        localStorage.getItem('phone'),
                        localStorage.getItem('member_no')
                    ).then(function (rsp) {
                        for (let i in rsp) {
                            isPetExist(
                                localStorage.getItem('phone'),
                                localStorage.getItem('password'),
                                rsp[i].PET_SeqNo
                            ).then(function (petExistRsp) {
                                if (petExistRsp) {
                                    parent.petList.push({
                                        text: rsp[i].PET_Name,
                                        value: rsp[i].PET_SeqNo,
                                    });
                                    parent.petDict[rsp[i].PET_SeqNo] =
                                        rsp[i].PET_Name;
                                    parent.petImageObj[
                                        rsp[i].PET_SeqNo
                                    ] = require('@/assets/images/imgCard' +
                                        rsp[i].PET_Type +
                                        '.jpg');
                                }
                            });
                        }
                        getReserveList(
                            localStorage.getItem('phone'),
                            localStorage.getItem('password'),
                            60
                        ).then(function (rsp) {
                                if(rsp.errorMsg){
                                    parent.$store.state.isLoading = false;
                                    return
                                }
                                parent.own_reserve_list = rsp.map(function (obj) {
                                    return obj.c_pbsapptno + obj.e_storeno;
                                });
                                if (parent.$route.query.selectedTime != undefined) {
                                parent.selectedType =
                                    parent.$route.query.selectedType ==
                                    undefined
                                        ? ''
                                        : parent.$route.query.selectedType;
                                parent.selectedStatus =
                                    parent.$route.query.selectedStatus ==
                                    undefined
                                        ? ''
                                        : parent.$route.query.selectedStatus;
                                parent.selectedTime =
                                    parent.$route.query.selectedTime;
                                parent.fetchSheetList();
                                parent.initial = true;
                            } else {
                                parent.$store.state.isLoading = false;
                                parent.fetchSheetList();
                                parent.initial = true;
                            }
                        });
                    });
                });
            });
        }
    },
    methods: {
        handldPage(num) {
            this.currentPage += num;
        },
        changePage(num) {
            this.$refs.listPage.scrollTo(0, 0);
            this.currentPage = num;
        },
        fetchSheetList() {
            let store_no_list = Object.keys(this.storeDict);
            this.special_list = [];
            let parent = this;
            var promises = store_no_list.map(function (store_no) {
                return KgGetSheetList(
                    localStorage.getItem('phone'),
                    localStorage.getItem('member_no'),
                    store_no,
                    parent.start_date,
                    parent.end_date
                ).then(function (rsp) {
                    if (rsp) {
                        console.log('fetchSheetList', rsp);
                        rsp = rsp.filter(function (obj) {
                            if (obj.PBS_ApptNo) {
                                return false;
                            } else {
                                return true;
                            }
                        });
                        rsp = rsp.map(function (obj) {
                            obj.STO_No = store_no;
                            return obj;
                        });
                        parent.special_list = parent.special_list.concat(rsp);
                        return true;
                    } else {
                        return false;
                    }
                });
            });
            Promise.all(promises).then(function (results) {
                console.log(results);
                parent.special_list = JSON.parse(
                    JSON.stringify(parent.special_list)
                ).map(function (obj) {
                    console.log('map obj', obj);
                    obj.PBS_CheckInDate = obj.PBS_ShtDate;
                    obj.PBS_Date = obj.PBS_ShtDate;
                    obj.PBS_ApptBegTime = obj.CRT_Date.slice(8, 12);
                    obj.PBS_ApptNo = obj.PBS_ShtNo;
                    obj.PBS_No = obj.PBS_ShtNo;
                    if (['R0', 'R1'].indexOf(obj.PBS_ShtStatus) != -1) {
                        obj.PBS_ApptStatus = 3;
                    } else if (['R3', 'R2'].indexOf(obj.PBS_ShtStatus) != -1) {
                        obj.PBS_ApptStatus = 'S1';
                    } else if (['R4', 'R5'].indexOf(obj.PBS_ShtStatus) != -1) {
                        obj.PBS_ApptStatus = 4;
                    }
                    return obj;
                });
                parent.fetchList();
            });
        },
        handleScroll: function (e) {
            var currentScrollPosition = e.srcElement.scrollTop;
            if (currentScrollPosition > this.scrollPosition) {
                console.log('Scrolling down');
                this.isScrollDown = false;
            } else {
                this.isScrollDown = false;
            }
            this.scrollPosition = currentScrollPosition;
        },
        getRecordStatusClass(status) {
            let obj = {
                未確認: 'style-salon-history-record-pending',
                已取消: 'style-salon-history-record-cancel',
                已預約: 'style-salon-history-record-reserve',
                美容中: 'style-salon-history-record-beauty',
            };
            return obj[status];
        },
        getStatusClass(status) {
            let obj = {
                未確認: 'style-status-pending',
                已取消: 'style-status-cancel',
                已預約: 'style-status-reserve',
                美容中: 'style-status-beauty',
            };
            return obj[status];
        },
        filterList() {
            console.log('filterList');
            const parent = this;
            if (this.petSelect) {
                this.history = JSON.parse(
                    JSON.stringify(this.ori_history)
                ).filter(function (obj) {
                    if (obj.pet_id == parent.petSelect) {
                        return true;
                    } else {
                        return false;
                    }
                });
                this.reserve_list = JSON.parse(
                    JSON.stringify(this.ori_reserve_list)
                ).filter(function (obj) {
                    if (obj.pet_id == parent.petSelect) {
                        return true;
                    } else {
                        return false;
                    }
                });
                this.beauty_list = JSON.parse(
                    JSON.stringify(this.ori_beauty_list)
                ).filter(function (obj) {
                    if (obj.pet_id == parent.petSelect) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } else {
                this.history = JSON.parse(JSON.stringify(this.ori_history));
                this.reserve_list = JSON.parse(
                    JSON.stringify(this.ori_reserve_list)
                );
                this.beauty_list = JSON.parse(
                    JSON.stringify(this.ori_beauty_list)
                );
            }

            // 計算頁數
            this.history_page = Math.ceil(this.history.length / 8);
            this.reserve_page = Math.ceil(this.reserve_list.length / 8);

            // 日期排序
            if (this.timeSelect === '1') {
                this.history = JSON.parse(JSON.stringify(this.history)).sort(
                    function (a, b) {
                        if (
                            a.time.replace(/\\/g, '') <
                            b.time.replace(/\\/g, '')
                        ) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                );
                this.reserve_list = JSON.parse(
                    JSON.stringify(this.reserve_list)
                ).sort(function (a, b) {
                    if (a.time.replace(/\\/g, '') < b.time.replace(/\\/g, '')) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                this.beauty_list = JSON.parse(
                    JSON.stringify(this.beauty_list)
                ).sort(function (a, b) {
                    if (a.time.replace(/\\/g, '') < b.time.replace(/\\/g, '')) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            } else {
                this.history = JSON.parse(JSON.stringify(this.history)).sort(
                    function (a, b) {
                        if (
                            a.time.replace(/\\/g, '') >
                            b.time.replace(/\\/g, '')
                        ) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                );
                this.reserve_list = JSON.parse(
                    JSON.stringify(this.reserve_list)
                ).sort(function (a, b) {
                    if (a.time.replace(/\\/g, '') > b.time.replace(/\\/g, '')) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                this.beauty_list = JSON.parse(
                    JSON.stringify(this.beauty_list)
                ).sort(function (a, b) {
                    if (a.time.replace(/\\/g, '') > b.time.replace(/\\/g, '')) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
        },
        fetchList() {
            console.log('fetchList', this.own_reserve_list);
            this.$store.state.isLoading = true;
            var parent = this;
            var start_date = this.start_date;
            var end_date = this.end_date;
            var status;

            // 不過濾狀態
            status = '';
            KgGetReserveList(
                localStorage.getItem('phone'),
                localStorage.getItem('member_no'),
                status,
                start_date,
                end_date
            ).then(function (rsp) {
                // 我的預約
                parent.ori_reserve_list = [];
                // 美容紀錄
                parent.ori_history = [];
                // 美容中
                parent.ori_beauty_list = [];
                console.log(start_date, end_date);
                console.log(rsp, status);
                if (rsp.length == 0 || !rsp) {
                    parent.$store.state.isLoading = false;
                } else {
                    rsp = rsp.concat(parent.special_list);
                    var promises = rsp.map(function (tObj, i) {
                        if (
                            parent.own_reserve_list.indexOf(
                                rsp[i].PBS_ApptNo + rsp[i].STO_No
                            ) != -1 ||
                            ((status == '' || status == 4) &&
                                parent.own_reserve_list.indexOf(
                                    rsp[i].PBS_ApptNo + rsp[i].STO_No
                                ) != -1 &&
                                rsp[i].PBS_ApptStatus == 4) ||
                            rsp[i].PBS_ShtType == 'R'
                        ) {
                            return KgGetReserve(
                                localStorage.getItem('phone'),
                                localStorage.getItem('member_no'),
                                rsp[i].STO_No,
                                rsp[i].PBS_ApptNo
                            ).then(function (inner_rsp) {
                                if (!inner_rsp && rsp[i].PBS_ShtType == 'R') {
                                    inner_rsp = rsp[i];
                                    console.log('inner_rsp', inner_rsp);
                                }
                                if (
                                    inner_rsp.PBS_ApptBegTime ||
                                    rsp[i].PBS_ShtType == 'R'
                                ) {
                                    var hour =
                                        inner_rsp.PBS_ApptBegTime.slice(0, 2) +
                                        ':' +
                                        inner_rsp.PBS_ApptBegTime.slice(2, 4);

                                    var date = inner_rsp.PBS_CheckInDate;
                                    if (!date) date = inner_rsp.PBS_ApptDate;
                                    let obj = {
                                        status: parent.statusDict[
                                            inner_rsp.PBS_ApptStatus
                                        ],
                                        name: parent.petDict[rsp[i].PET_SeqNo],
                                        pet_id: rsp[i].PET_SeqNo,
                                        time: [
                                            date.slice(0, 4),
                                            date.slice(4, 6),
                                            date.slice(6, 8),
                                        ].join('/'),
                                        hour: hour,
                                        store: parent.storeDict[rsp[i].STO_No],
                                        rating: '',
                                        showNotRating: false,
                                        id: rsp[i].PBS_ApptNo,
                                        work_id: rsp[i].PBS_No,
                                        store_id: rsp[i].STO_No,
                                        service_list: [],
                                        PBS_DTL_INFO: [],
                                        PBS_ShtType: rsp[i].PBS_ShtType
                                            ? rsp[i].PBS_ShtType
                                            : '',
                                        img: '',
                                    };
                                    obj.rating = inner_rsp.PBS_EvalRemak;

                                    if (!obj.rating) obj.showNotRating = true;
                                    if (inner_rsp.PBS_Svr01 == 'Y') {
                                        obj.service_list.push('洗澡');
                                    }

                                    if (inner_rsp.PBS_Svr02 == 'Y') {
                                        obj.service_list.push('SPA');
                                    }

                                    if (inner_rsp.PBS_Svr03 == 'Y') {
                                        obj.service_list.push('剪毛');
                                    }
                                    if (inner_rsp.PBS_Svr04 == 'Y') {
                                        obj.service_list.push('游泳');
                                    }

                                    if (inner_rsp.PBS_ApptStatus == '0')
                                        obj.url =
                                            '/salon/reserved?id=' +
                                            obj.id +
                                            '&store_id=' +
                                            obj.store_id +
                                            '&selectedTime=' +
                                            parent.selectedTime +
                                            '&selectedType=' +
                                            parent.selectedType +
                                            '&selectedStatus=' +
                                            parent.selectedStatus +
                                            '&PBS_ShtType=' +
                                            obj.PBS_ShtType;
                                    if (inner_rsp.PBS_ApptStatus == '3')
                                        obj.url =
                                            '/salon/reserved?id=' +
                                            obj.id +
                                            '&store_id=' +
                                            obj.store_id +
                                            '&selectedTime=' +
                                            parent.selectedTime +
                                            '&selectedType=' +
                                            parent.selectedType +
                                            '&selectedStatus=' +
                                            parent.selectedStatus +
                                            '&PBS_ShtType=' +
                                            obj.PBS_ShtType;
                                    if (inner_rsp.PBS_ApptStatus == '4')
                                        obj.url =
                                            '/salon/reserved?is_cancel=true&id=' +
                                            obj.id +
                                            '&store_id=' +
                                            obj.store_id +
                                            '&selectedTime=' +
                                            parent.selectedTime +
                                            '&selectedType=' +
                                            parent.selectedType +
                                            '&selectedStatus=' +
                                            parent.selectedStatus +
                                            '&PBS_ShtType=' +
                                            obj.PBS_ShtType;
                                    if (
                                        inner_rsp.PBS_ApptStatus == 'S1' ||
                                        inner_rsp.PBS_ApptStatus == 'S2' ||
                                        inner_rsp.PBS_ApptStatus == 'S3'
                                    ) {
                                        // 確認美容中是否已完成(未按下整張單據完工)
                                        return KgGetBeauty(
                                            localStorage.getItem('phone'),
                                            rsp[i].STO_No,
                                            inner_rsp.PBS_No,
                                            inner_rsp.PBS_Date
                                        ).then(function (beauty_rsp) {
                                            // 美容項目
                                            console.log(
                                                'beauty_rsp',
                                                beauty_rsp.PBS_DTL_INFO
                                            );
                                            obj.PBS_DTL_INFO = JSON.parse(
                                                JSON.stringify(
                                                    beauty_rsp.PBS_DTL_INFO
                                                )
                                            );
                                            if (
                                                beauty_rsp.PBS_SvrStatus == 2 ||
                                                beauty_rsp.PBS_SvrStatus == 3
                                            ) {
                                                // 等於已完成
                                                obj.status = '已完成';
                                                if (obj.rating)
                                                    obj.url =
                                                        '/salon/completed?PET_SeqNo=' +
                                                        rsp[i].PET_SeqNo +
                                                        '&PBS_No=' +
                                                        inner_rsp.PBS_No +
                                                        '&id=' +
                                                        obj.id +
                                                        '&store_id=' +
                                                        obj.store_id +
                                                        '&selectedTime=' +
                                                        parent.selectedTime +
                                                        '&selectedType=' +
                                                        parent.selectedType +
                                                        '&selectedStatus=' +
                                                        parent.selectedStatus +
                                                        '&PBS_ShtType=' +
                                                        obj.PBS_ShtType +
                                                        '&PBS_Date=' +
                                                        obj.time.replace(
                                                            /\//g,
                                                            ''
                                                        );
                                                if (!obj.rating)
                                                    obj.url =
                                                        '/salon/process/rating?PET_SeqNo=' +
                                                        rsp[i].PET_SeqNo +
                                                        '&PBS_No=' +
                                                        inner_rsp.PBS_No +
                                                        '&id=' +
                                                        obj.id +
                                                        '&store_id=' +
                                                        obj.store_id +
                                                        '&selectedTime=' +
                                                        parent.selectedTime +
                                                        '&selectedType=' +
                                                        parent.selectedType +
                                                        '&selectedStatus=' +
                                                        parent.selectedStatus +
                                                        '&PBS_ShtType=' +
                                                        obj.PBS_ShtType +
                                                        '&PBS_Date=' +
                                                        obj.time.replace(
                                                            /\//g,
                                                            ''
                                                        );
                                            } else {
                                                // obj.url =
                                                //     '/salon/process?PET_SeqNo=' +
                                                //     rsp[i].PET_SeqNo +
                                                //     '&PBS_No=' +
                                                //     inner_rsp.PBS_No +
                                                //     '&id=' +
                                                //     obj.id +
                                                //     '&store_id=' +
                                                //     obj.store_id +
                                                //     '&selectedTime=' +
                                                //     parent.selectedTime +
                                                //     '&selectedType=' +
                                                //     parent.selectedType +
                                                //     '&selectedStatus=' +
                                                //     parent.selectedStatus;
                                            }
                                            if (
                                                (inner_rsp.PBS_ApptStatus ==
                                                    'S2' ||
                                                    inner_rsp.PBS_ApptStatus ==
                                                        'S3') &&
                                                obj.rating
                                            )
                                                obj.url =
                                                    '/salon/completed?PET_SeqNo=' +
                                                    rsp[i].PET_SeqNo +
                                                    '&PBS_No=' +
                                                    inner_rsp.PBS_No +
                                                    '&id=' +
                                                    obj.id +
                                                    '&store_id=' +
                                                    obj.store_id +
                                                    '&selectedTime=' +
                                                    parent.selectedTime +
                                                    '&selectedType=' +
                                                    parent.selectedType +
                                                    '&selectedStatus=' +
                                                    parent.selectedStatus +
                                                    '&PBS_ShtType=' +
                                                    obj.PBS_ShtType +
                                                    '&PBS_Date=' +
                                                    obj.time.replace(/\//g, '');
                                            if (
                                                (inner_rsp.PBS_ApptStatus ==
                                                    'S2' ||
                                                    inner_rsp.PBS_ApptStatus ==
                                                        'S3') &&
                                                !obj.rating
                                            )
                                                obj.url =
                                                    '/salon/process/rating?PET_SeqNo=' +
                                                    rsp[i].PET_SeqNo +
                                                    '&PBS_No=' +
                                                    inner_rsp.PBS_No +
                                                    '&id=' +
                                                    obj.id +
                                                    '&store_id=' +
                                                    obj.store_id +
                                                    '&selectedTime=' +
                                                    parent.selectedTime +
                                                    '&selectedType=' +
                                                    parent.selectedType +
                                                    '&selectedStatus=' +
                                                    parent.selectedStatus +
                                                    '&PBS_ShtType=' +
                                                    obj.PBS_ShtType +
                                                    '&PBS_Date=' +
                                                    obj.time.replace(/\//g, '');
                                            if (
                                                ['已完成'].indexOf(
                                                    obj.status
                                                ) != -1
                                            ) {
                                                // obj.status = '美容中'
                                                // parent.ori_beauty_list.push(obj);
                                                return getPictList(
                                                    obj.store_id,
                                                    obj.work_id,
                                                    localStorage.getItem(
                                                        'member_no'
                                                    ),
                                                    obj.pet_id
                                                ).then(function (rsp) {
                                                    console.log(
                                                        'getPictList',
                                                        rsp
                                                    );
                                                    if (rsp && rsp.after) {
                                                        if (
                                                            typeof rsp.after ==
                                                            'object'
                                                        ) {
                                                            obj.img =
                                                                rsp.after[0];
                                                        } else {
                                                            obj.img = rsp.after;
                                                        }
                                                    } else {
                                                        obj.img =
                                                            parent.petImageObj[
                                                                obj.pet_id
                                                            ];
                                                    }
                                                    parent.ori_history.push(
                                                        obj
                                                    );
                                                    return obj;
                                                });
                                            } else if (
                                                ['美容中'].indexOf(
                                                    obj.status
                                                ) != -1
                                            ) {
                                                parent.ori_beauty_list.push(
                                                    obj
                                                );
                                                return obj;
                                            } else {
                                                // parent.ori_history.push(obj);
                                                parent.ori_reserve_list.push(
                                                    obj
                                                );
                                                return obj;
                                            }
                                        });
                                    } else {
                                        if (
                                            ['已完成'].indexOf(obj.status) != -1
                                        ) {
                                            // obj.status = '美容中'
                                            // parent.ori_beauty_list.push(obj);
                                            return getPictList(
                                                obj.store_id,
                                                obj.work_id,
                                                localStorage.getItem(
                                                    'member_no'
                                                ),
                                                obj.pet_id
                                            ).then(function (rsp) {
                                                console.log('getPictList', rsp);
                                                if (rsp && rsp.after) {
                                                    if (
                                                        typeof rsp.after ==
                                                        'object'
                                                    ) {
                                                        obj.img = rsp.after[0];
                                                    } else {
                                                        obj.img = rsp.after;
                                                    }
                                                } else {
                                                    obj.img =
                                                        parent.petImageObj[
                                                            obj.pet_id
                                                        ];
                                                }
                                                parent.ori_history.push(obj);
                                                return obj;
                                            });
                                        } else if (
                                            ['美容中'].indexOf(obj.status) != -1
                                        ) {
                                            parent.ori_beauty_list.push(obj);
                                            return obj;
                                        } else {
                                            // parent.ori_history.push(obj);
                                            parent.ori_reserve_list.push(obj);
                                            return obj;
                                        }
                                    }
                                } else {
                                    return false;
                                }
                            });
                        } else {
                            return false;
                        }
                    });
                    Promise.all(promises).then(function (results) {
                        console.log(results);
                        // parent.ori_reserve_list = parent.ori_reserve_list.slice(0,8)

                        parent.filterList();
                        setTimeout(function () {
                            parent.$store.state.isLoading = false;
                        }, 1000);
                    });
                }
            });
        },
    },
    watch: {
        timeSelect() {
            this.filterList();
        },
        petSelect() {
            this.filterList();
        },
        // selectedTime() {
        //     if (this.initial) this.fetchList();
        // },
        // selectedType() {
        //     if (this.initial) this.fetchList();
        // },
        // selectedStatus() {
        //     if (this.initial) this.fetchList();
        // },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
}

.v-enter-active,
.v-leave-active {
    transition: opacity 1s;
}

.v-enter,
.v-leave-to {
    opacity: 0;
}
</style>
