<template>
    <v-rating v-bind="{ ...$attrs }" v-on="$listeners">
        <template v-slot:item="props">
            <img
                width="30"
                height="30"
                class=""
                :src="
                    require(`@/assets/images/${
                        props.isFilled ? 'ic30Star.svg' : 'ic30StarDark.svg'
                    }`)
                "
                alt=""
                @click="props.click"
            />
        </template>
    </v-rating>
</template>

<script>
export default {
    name: 'SalonRating',
    props: {},
    data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
