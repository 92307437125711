<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      default: "Button",
    },
    bolder: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<template>
  <div
    :class="bolder ? 'body-semibold' : 'body-medium'"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
    style="
      border-radius: 18px;
      align-content: center;
      cursor: pointer;
    "
  >
    {{ text }}
  </div>
</template>

<style scoped></style>
