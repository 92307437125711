<!-- eslint-disable prettier/prettier -->
<script>
export default {
  name: 'FancyVSelect',
  inheritAttrs: true,
  props: {
    iconName: String,
    options: Array,
    sizes: {
      type: Number,
      default: 24,
    },
    label: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    },
    wrongHint: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isChecking: {
      type: Boolean,
      default: false,
    },
    hideCheck: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFirst: true,
    selectedItem: null,
    isOpen: false,
    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
  }),
  watch: {
    selectedItem() {
      this.$emit('input', this.options[this.selectedItem].value)
    },
  },
  methods: {
    isInput(){
      this.isFirst = false
    },
    handleInput(){
      if(this.hideCheck) { return }
      if(this.isFirst && !this.isChecking){
        return this.isFirst
      }
      return this.isError
    },
    selectFocus() {
      this.isOpen = true
    },
  },
  computed: {
    commonAttrs() {
      return {
        outlined: true,
        shaped: true,
      }
    },
  },
}
</script>

<template>
  <div
    style="cursor: pointer; position: relative; margin-bottom: 12px;"
    @click="
      () => {
        $store.commit('setIsChoose', false);
        isInput()
      }
    "
  >
    <div
      class="body-regular"
      style="
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
      "
    >
      <span v-show="isRequired" class="body-regular" style="color: #ff453a"> * </span>
      {{ label }}
      <img :src="require(`@/assets/images/${iconName}`)" alt="" />
    </div>
    <div :class="{'redBorder': !handleInput()}" class="select-wrapper dash-regular-16px" style="position: relative">
      <p v-if="date">{{ date }}</p>
      <p v-else :class="{'redText': !handleInput()}" class="placeholder">2000/01/01</p>
      <div
        style="
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
        "
      >
        <img :width="sizes" :height="sizes" :src="handleInput() ? require(`@/assets/images/ic24Arrow.svg`) : require(`@/assets/images/ic24Arrowred.svg`)" alt="" />
      </div>
    </div>
    <div class="caption-regular" style="color: #ff4040; text-align: start; overflow: hidden; transition: 0.3s;display: flex; align-items: flex-end;" :style="{height: handleInput()?'0px':'22px', opacity: handleInput()?'0':'1'}">{{ wrongHint }}</div>
  </div>
</template>
<style lang="scss" scoped>
.redText {
  color: #FF453A !important;
}
.redBorder {
  border: solid 1px #FF453A;
}
.placeholder {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 300;
}
.select-wrapper {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  border-radius: 14px;
  width: 100%;
  height: 40px;
}
</style>
