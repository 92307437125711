<template>
  <LayoutWithNav>
    <PopupTop :is-show="showAlert" :errorMsg="errorMsg" />
    <v-main class="banner" v-if="!$store.state.isLoading">
      <div ref="banner_img" class="banner_img" :style="{ clipPath: `path('${path}')`}"></div>
      <v-container class="member-container mb-4">
        <div class="member-box">
          <div class="photo">
            <div
              class="photo--avatar"
              :style="{
                'background-image': 'url(' + this.image + ')',
              }"
            >
              <div class="btn" style="width: 14px; height: 14px">
                <label for="file">
                  <img
                    width="14"
                    height="14"
                    class="avatar"
                    :src="require(`@/assets/images/ic24CameraNew.svg`)"
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="image/*"
                  @change="handleFileUpload()"
                  hidden
                />
              </div>
            </div>
            <p class="name large-title-semibold">
              {{ name }}
            </p>
          </div>
          <div class="member-box--user">
            <div style="display: flex; flex-direction: column; gap: 10px; width: 304px; background-color: #fff; border-radius: 14px; padding:16px 0px 12px 0px; font-size: 14px;">
              <barcode
                :value="memberNo"
                :text="`${member ? '會員條碼' : '手機載具'} ${memberNo}`"
                height="72px"
                fontSize="14"
                font="Noto Sans TC"
                fontOptions="regular"
                textMargin="6"
                :margin="1"
                :margin-right="20"
                :margin-left="20"
                :format="member ? 'CODE128' : 'CODE39'"
                :option="{ width: '100px', height: '50px' }"
                background="transparent"
                style="
                  display: flex;
                  justify-content: center;
                  transform-origin : center center;
                  font-family: Noto Sans TC;
                  font-weight: 400;
                  border-radius: 14px;
                  background-color: transparent;"
              >
                Show this if the rendering fails.
              </barcode>
              <div class="switchCode" @click="switchCode">
                <img
                  class="item-img"
                  height="12"
                  width="12"
                  :src="carrier.length > 0 && this.carrier !== '' ? require(`@/assets/images/ic24refresh.svg`) : require(`@/assets/images/ic24Add.svg`)"
                  alt=""
                />
                <p>{{ `${!member ? '切換會員條碼' : carrier.length > 0 && this.carrier !== ''?'切換手機載具' : '新增手機載具'}` }}</p>
              </div>
            </div>
          </div>
        </div>

        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item class="item" color="#333" @click.prevent="$router.push('/member/info')">
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24Persondata.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">個人資料修改</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
            <v-list-item
              class="item"
              color="#333"
              @click.prevent="$router.push('/pet?type=member')"
            >
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24Name.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">寶貝管理</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
            <v-list-item class="item" color="#333" @click.prevent="$router.push('/Invoice/index')">
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24Invoice.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">手機載具設定</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
            <v-list-item
              class="item"
              color="#333"
              @click.prevent="$router.push('/member/password')"
            >
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24Key.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">密碼修改</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
            <v-list-item v-if="false" class="item" color="#333" @click.prevent="showPhoneLB = true">
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24Phone.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">客服電話</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
            <v-list-item
              class="item"
              color="#333"
              @click.prevent="$router.push('/register/contract?url=member')"
            >
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24ContractBlack.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">會員服務條款</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>

            <v-list-item class="item" color="#333" @click.prevent="clickType('dmember')">
              <div class="item-content">
                <img
                  class="item-img"
                  height="24"
                  width="24"
                  :src="require(`@/assets/images/ic24DeleteBlack.svg`)"
                  alt=""
                />
                <p class="item-text body-regular">刪除會員帳號申請</p>
              </div>
              <img class="item-arrow" src="~@/assets/images/ic24ArrowL.svg" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <BtnSign class="" text="登出" @click.prevent="clickType('logout')" />
      </v-container>
    </v-main>
    <PopupAlert v-model="combinedModel" :type="type" />
    <PopupPhoneCall v-model="showPhoneLB" />
  </LayoutWithNav>
</template>

<script>
import LayoutWithNav from '@/layouts/LayoutWithNav.vue'
import BtnSign from '@/components/BtnSign.vue'
import PopupPhoneCall from '@/components/Popup/PopupPhoneCall.vue'
import PopupAlert from '@/components/Popup/PopupAlert.vue'
import PopupTop from '@/components/Popup/PopupTop.vue'
import VueBarcode from 'vue-barcode'
import { getUserInfo, uploadMemberImage, getMemberImage, GetCarrier } from '@/apis/api.js'
export default {
  name: 'MemberIndex',
  components: {
    LayoutWithNav,
    BtnSign,
    PopupAlert,
    PopupPhoneCall,
    PopupTop,
    barcode: VueBarcode,
  },
  data: () => ({
    type: '',
    name: '',
    file: '',
    path: '',
    image: '',
    showDeleteLB: false,
    showlogoutLB: false,
    showPhoneLB: false,
    memberNo: '',
    member: true,
    carrier: '',
    showAlert: false,
    errorMsg: ''
  }),
  beforeCreate() {
    this.$store.state.isLoading = true
  },
  mounted() {
    window.addEventListener('resize', this.bannerRadius)
  },
  created() {
    if (
      !localStorage.getItem('phone') ||
      !localStorage.getItem('password') ||
      !localStorage.getItem('member_no')
    ) {
      this.$router.push('/login')
    } else {
      var parent = this
      if (
        !localStorage.getItem('phone') ||
        !localStorage.getItem('password') ||
        !localStorage.getItem('member_no')
      )
        this.$router.push('/login')
      getUserInfo(localStorage.getItem('phone'), localStorage.getItem('password')).then(function (rsp){
        return JSON.parse(rsp)
      }).then(function (rsp) {
        parent.name = rsp.c_name
      })
      getMemberImage(localStorage.getItem('phone'), localStorage.getItem('password')).then(
        function (rsp) {
          console.log(rsp)
          if (rsp) parent.image = rsp
          else parent.image = require(`@/assets/images/imgMemberM.svg`)
          setTimeout(function () {
            parent.$store.state.isLoading = false
          }, 500)
        }
      )
      GetCarrier(localStorage.getItem('phone'),localStorage.getItem('password'))
        .then(rsp => JSON.parse(rsp))
        .then(rsp => {
          parent.carrier = rsp.carrier ? rsp.carrier : ''
        })
      setTimeout(function () {
        parent.$store.state.isLoading = false
      }, 3000)
      if (localStorage.getItem('member_no')) this.memberNo = localStorage.getItem('member_no')
    }
  },
  computed: {
    combinedModel: {
      get() {
        return this.showDeleteLB || this.showlogoutLB
      },
      set(value) {
        this.showDeleteLB = value
        this.showlogoutLB = value
      },
    },
  },
  methods: {
    bannerRadius() {
      let self = this

      const width = self.$refs.banner_img.getBoundingClientRect().width
      const height = 296
      self.path = `M0 0 L${width} 0 L${width} ${height - 10} Q${width - width / 4} ${height} ${width / 2} ${height} Q${width / 4} ${height} 0 ${height - 10} Z`
    },
    logout() {
      var parent = this
      this.showlogoutLB = false
      localStorage.removeItem('phone')
      localStorage.removeItem('password')
      localStorage.removeItem('member_no')
      setTimeout(function () {
        parent.$router.push('/login')
      }, 1000)
    },
    getImage() {},
    handleFileUpload() {
      this.$store.state.isLoading = true
      this.file = this.$refs.file.files[0]
      var href = URL.createObjectURL(this.file)
      var parent = this
      uploadMemberImage(localStorage.getItem('phone'), this.file).then(function (rsp) {
        parent.image = href
        console.log('uploadMemberImage', rsp)
        setTimeout(function () {
          parent.$store.state.isLoading = false
        }, 500)
      })
    },
    switchCode() {
      if(this.carrier.length > 0 && this.carrier !== ''){
        this.member = !this.member
        this.memberNo = this.member ? localStorage.getItem('member_no') : this.carrier
      }else{
        this.$router.push('/Invoice/index')
      }
    },
    clickType(e) {
      if (e === 'dmember') {
        this.type = 'dmember'
        this.showDeleteLB = true
      } else if (e === 'logout') {
        this.type = 'logout'
        this.showlogoutLB = true
      }
    },
  },
  watch: {
    '$store.state.isLoading'(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.bannerRadius()
          if(this.$store.state.isShowAlert === 'password'){
            this.$store.state.isShowAlert = ''
            this.showAlert = true
            this.errorMsg = '修改成功'
            setTimeout(() => {
              this.showAlert = false
            }, 3000)
          }else if(this.$store.state.isShowAlert === 'invoice'){
            this.$store.state.isShowAlert = ''
            this.showAlert = true
            this.errorMsg = '刪除成功'
            setTimeout(() => {
              this.showAlert = false
            }, 3000)
          }else if(this.$store.state.isShowAlert === 'invoiceset') {
            this.$store.state.isShowAlert = ''
            this.showAlert = true
            this.errorMsg = '修改成功'
            setTimeout(() => {
              this.showAlert = false
            }, 3000)
          }else if(this.$store.state.isShowAlert === 'memberinfo') {
            this.$store.state.isShowAlert = ''
            this.showAlert = true
            this.errorMsg = '修改成功'
            setTimeout(() => {
              this.showAlert = false
            }, 3000)
          }
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.banner_img {
  position: absolute;
  width: 100%;
  height: 296px;
  background-size: cover;
  background-image: url(~@/assets/images/bannerMember.png);
}
.member-container {
  .member-box {
    width: 304px;
    margin: 70px auto 30px;
    border-radius: 16px;
    // height: 235px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    & .photo {
      display: flex;

      align-items: center;
      height: fit-content;
      &--avatar {
        position: relative;
        margin: 0 6px 0 0px;
        width: 40px;
        height: 40px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 33.33px;
        border: solid 1px #fff;
        box-shadow: -0.33px 0.67px 3.33px -0.67px #4b251814, 0.33px 0.33px 1.33px -0.67px #4b25181f;
        .btn {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      & .name {
        color: white;
      }
    }
    &--user {
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      top: 53.5px;
      & .switchCode {
        // position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff9f4b;
        font-size: 10px;
        font-weight: 400;
        // transform: translateY(-50%);
        gap: 6px;
      }
    }
  }
  .v-list {
    width: 312px;
    margin: 0 auto 24px;
    &.v-list--flat {
      background-color: transparent;
    }
    .v-item-group {
      .v-list-item {
        &.item {
          color: $color_main_black;
          height: 52px !important;
          display: flex;
          width: 100%;
          justify-content: space-between;
          & .item-content {
            display: flex;
            align-items: center;
            gap: 24px;
          }
          &.v-item--active {
            color: $color_main_black;
          }
        }
        img.item-img {
          margin: 0 12px 0 20px;
        }
        img.item-arrow {
          transform: rotate(180deg);
          margin-left: auto;
        }
        p.item-text {
          padding: 0;
          font-size: 14px;
          line-height: 16px;
          color: #0d0c0c;
        }
      }
    }
  }
}
</style>
