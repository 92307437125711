<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container">
            <div style="text-align: left; margin-bottom: 12px">
                <div class="popup-title" style="margin-bottom: 10px">
                    確定重複預約?
                </div>
                <div class="popup-desc" style="margin-bottom: 12px">
                    如需重複預約，將取消原時段預約，<br />以此預約服務為準
                </div>
                <div
                    class="popup-desc"
                    style="
                        display: inline-flex;
                        align-items: center;
                        margin-bottom: 8px;
                    "
                >
                    <div
                        v-if="data.img"
                        class="style-confirm-img"
                        :style="{
                            border: '3px solid #FFDAAD',
                            margin: '0 auto',
                            'background-image': `url(${data.img})`,
                            width: '40px',
                            height: '40px',
                            'border-radius': '100%',
                            'background-size': 'cover',
                            'background-position': 'center',
                            'margin-right': '8px',
                        }"
                    ></div>
                    <div style="font-size: 12px; font-weight: 700">
                        {{ data.pet_name }}
                    </div>
                </div>
                <div
                    class="popup-desc"
                    style="margin-bottom: 8px; color: #ff9f4b"
                >
                    原預約:
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 8px;
                        font-weight: 400;
                    "
                >
                    服務: {{ data.services.join(' ') }}
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 8px;
                        font-weight: 400;
                    "
                >
                    時段: {{ data.time.split(' ')[0] }}
                    {{
                        data.time.split(' ')[1].split(':')[0] >= 12
                            ? '下午'
                            : '上午'
                    }}{{ data.time.split(' ')[1] }}
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 12px;
                        font-weight: 400;
                    "
                >
                    門市: {{ data.store_name }}
                </div>
                <div
                    class="popup-desc"
                    style="margin-bottom: 8px; color: #999999"
                >
                    新預約:
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 8px;
                        font-weight: 400;
                    "
                >
                    服務: {{ services.join(' ') }}
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 8px;
                        font-weight: 400;
                    "
                >
                    時段:
                    {{ infos[4].value.replace(/-/g, '/').split('\t')[0] }}
                    {{
                        infos[4].value
                            .replace(/-/g, '/')
                            .split('\t')[1]
                            .split(':')[0] >= 12
                            ? '下午'
                            : '上午'
                    }}{{ infos[4].value.replace(/-/g, '/').split('\t')[1] }}
                </div>
                <div
                    class="popup-desc"
                    style="
                        font-size: 12px;
                        margin-bottom: 18px;
                        font-weight: 400;
                    "
                >
                    門市: {{ infos[3].value }}
                </div>
            </div>
            <div
                class="footer d-flex justify-center"
                style="margin-bottom: 8px"
            >
                <button
                    class="style-btn style-m-btn style-light-btn"
                    @click="$emit('click:cancel')"
                    style="margin: 8px"
                >
                    取消
                </button>
                <button
                    class="style-btn style-m-btn style-dark-btn"
                    @click="$emit('click:confirm')"
                    style="margin: 8px"
                >
                    確認
                </button>
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
export default {
    components: {
        FancyDialog,
    },
    name: 'PopupOrderDulpicate',
    props: {
        serviceList: {
            type: Array,
        },
        data: {
            type: Object,
        },
        infos: {
            type: Array,
        },
        services: {
            type: Array,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 24px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
