<template>
    <v-otp-input
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
    v-model="otpValue"
    ref="otpContent"
    >
    </v-otp-input>
</template>

<script>
export default {
    name: 'FancyOTP',
    props:['isError'],
    inheritAttrs: false,
    data: () => ({
        otpValue : '',
    }),
    computed: {
        commonAttrs() {
            return {
                plain: true,
                ['hide-spin-buttons']: true,
                type: 'number',
                length: '6',
            };
        },
        optLength(){
            return this.otpValue.length;
        },
    },
    methods: {
        handleDialog(value) {
            this.$emit('input', value);
        },
        handleOTP(){
            let parent = this
            const otpEl = this.$refs.otpContent.$refs;
            
            if (parent.isError === true) {
                otpEl.input.forEach(input => {
                    input.parentElement.parentElement.classList.add('error-value')
                })
            }
        }
    },
    mounted() {
        this.handleOTP();
        this.$watch('optLength', () => {
            const otpEl = this.$refs.otpContent.$refs;
            if (otpEl) {
                otpEl.input.forEach(input => {
                    input.parentElement.parentElement.classList.remove('has-value')
                })
                for(let i = 0; i < this.optLength; i++){
                    otpEl.input[i].parentElement.parentElement.classList.add('has-value')
                }
            }
        });
    },
};
</script>
<style lang="scss">
div.v-otp-input {
    > .v-input {
        padding: 0 12px;
    }
    .v-text-field {
        &:hover{
            border-color: none !important;
        }
        > .v-input__control {
            caret-color: #FF9F4B !important;
            > .v-input__slot {
                background-color: transparent !important;
                &:hover::before {
                    border: solid 1px #4B2518 !important;
                }
                > .v-text-field__slot {
                    height: 40px !important;
                    > input {
                        color: #FF9F4B;
                        padding-top: 10px;
                        padding-bottom: 14px;
                        // margin-bottom: 4px;
                        height: 43px !important;
                        font-family: Noto Sans TC !important;
                        font-size: 34px !important;
                        line-height: 43.2px !important;
                        letter-spacing: 1.44px !important;
                        font-weight: 700 !important;
                    }
                }
                &:before {
                    border-color: #D9D9D9;
                }
                &.has-value::before {
                    border: solid 1px #4B2518 !important;
                }
                &.error-value::before {
                    border: solid 1px #ff453a;
                }
                &:after {
                    border: none;
                }
            }
        }
    }
}
</style>
