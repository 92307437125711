var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.iconName ? 'style-text-input-high' : 'style-text-input-high',staticStyle:{"text-align":"left","display":"flex","align-items":"center","background-color":"white"},on:{"click":function($event){_vm.isOpen = true}}},[(_vm.iconName)?_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"width":"24","height":"24","src":require(`@/assets/images/${_vm.iconName}`),"alt":""}}):_vm._e(),_c('div',{style:(_vm.handleLabel() == '請選擇寶貝'
                    ? `color: #cccccc;`
                    : !_vm.selectedItem
                    ? `color:#999999;`
                    : `color: #0D0C0C;`)},[_vm._v(" "+_vm._s(_vm.handleLabel())+" ")]),_c('div',{staticStyle:{"margin-left":"auto","margin-top":"5px","margin-right":"10px"}},[_c('img',{attrs:{"width":"20","height":"20","src":_vm.icon
                        ? _vm.icon
                        : require(`@/assets/images/arrow-down-icon.svg`),"alt":""}})])]),_c('v-bottom-sheet',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{staticClass:"select-sheet text-center pa-6",staticStyle:{"padding-bottom":"50px"}},[_c('div',{staticClass:"d_flex justify-space-between mb-8"},[_c('p',[_vm._v(_vm._s(_vm.defaultLabel))]),_c('a',{on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("完成")])]),_c('v-card-text',{staticClass:"select-sheet-card-text"},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.options),function(option,i){return _c('v-list-item',{key:i,staticClass:"select-sheet-list-item",attrs:{"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(option.text)}})],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }