<script>
export default {
    name: 'BtnSign',
    props: {
        text: {
            type: String,
            default: '',
        },
        useIcon: {
            type: Boolean,
            default: true,
        },
        customStyle: {
            type: Object,
            default: () => ({
                color: 'white'
            }),
        },
        canClick: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
<template>
    <div class="btn-container" v-bind="{ ...$attrs }" v-on="$listeners" :style="customStyle">
        <p class="body-semibold">{{ text }}</p>
        <v-btn class="btn_next" style="width: 24px; height: 24px" icon>
            <slot>
                <v-img v-if="useIcon" alt="logo" :src="canClick?require('@/assets/images/btn_keep.svg'):require('@/assets/images/btn_keepGray.svg')" />
            </slot>
        </v-btn>
    </div>
</template>

<style lang="scss" scoped>
.btn-container {
    cursor: pointer;
    background-color: #ff9f4b;
    padding: 12px 16px;
    width: 218px;
    height: 48px;
    margin: 0 auto;
    border-radius: 18px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    &:active {
        background-color: #D16200;
    }
}
</style>
