<template>
    <v-checkbox
        class="fancy"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        :input-value="value"
        @change="(changed) => $emit('input', changed)"
    >
        <template v-slot:label>
            <a
                v-if="link"
                :style="{ 'text-decoration': 'underline' }"
                target="_blank"
                :href="link"
                @click.stop
            >
                {{ label }}
            </a>
            <span v-else> {{ label }}</span>
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
export default {
    name: 'FancyCheck',
    inheritAttrs: false,
    props: {
        label: String,
        link: String,
        value: null,
    },
    computed: {
        commonAttrs() {
            return {};
        },
    },
};
</script>

<style lang="scss" scoped>
div.fancy {
    &.v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
        > .v-input__control {
            > .v-input__slot {
                .v-label {
                    color: $color_main_first;
                }
                > .v-input--selection-controls__input {
                    .v-icon {
                        color: $color_main_first;
                        &.error--text {
                            color: $color_main_first !important;
                        }
                    }
                }
            }
            > .v-messages {
                margin-left: 32px;
                &.error--text {
                    color: $color_main_red !important;
                    > .v-messages__wrapper {
                        padding-left: 0px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        &:before {
                            content: '';
                            background-image: url('~@/assets/images/ic24Warning.svg');
                            background-size: 12px 12px;
                            height: 12px;
                            width: 12px;
                            margin-right: 4px;
                        }
                        > .v-messages__message {
                            font-size: 8px;
                            color: $color_main_red;
                        }
                    }
                }
            }
        }
    }
}
</style>
