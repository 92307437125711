<template>
    <div>
        <div
            :class="
                iconName ? 'style-text-input-high' : 'style-text-input-high'
            "
            style="
                text-align: left;
                display: flex;
                align-items: center;
                background-color: white;
            "
            @click="isOpen = true"
        >
            <img
                width="24"
                height="24"
                :src="require(`@/assets/images/${iconName}`)"
                v-if="iconName"
                style="margin-right: 5px"
                alt=""
            />
            <div
                :style="
                    handleLabel() == '請選擇寶貝'
                        ? `color: #cccccc;`
                        : !selectedItem
                        ? `color:#999999;`
                        : `color: #0D0C0C;`
                "
            >
                {{ handleLabel() }}
            </div>
            <div style="margin-left: auto; margin-top: 5px; margin-right: 10px">
                <img
                    width="20"
                    height="20"
                    :src="
                        icon
                            ? icon
                            : require(`@/assets/images/arrow-down-icon.svg`)
                    "
                    alt=""
                />
            </div>
        </div>
        <v-bottom-sheet scrollable persistent v-model="isOpen">
            <v-card
                class="select-sheet text-center pa-6"
                style="padding-bottom: 50px"
            >
                <div class="d_flex justify-space-between mb-8">
                    <p>{{ defaultLabel }}</p>
                    <a @click="isOpen = false">完成</a>
                </div>
                <v-card-text class="select-sheet-card-text">
                    <v-list nav dense>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            mandatory
                        >
                            <v-list-item
                                class="select-sheet-list-item"
                                :ripple="false"
                                v-for="(option, i) in options"
                                :key="i"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="option.text"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
export default {
    name: 'FancySelectBottom',
    inheritAttrs: false,
    props: {
        options: Array,
        defaultLabel: String,
        isTime: Boolean,
        iconName: String,
        initial: {
            type: Number,
            default: -1,
        },
        icon: {
            type: String,
            default: '',
        },
        refreshServiceItem: {
            type: Function,
        },
    },
    data: () => ({ selectedItem: null, isOpen: false }),
    watch: {
        selectedItem() {
            console.log(this.initial, this.selectedItem);
            this.$emit('input', this.options[this.selectedItem].value);
            if (this.refreshServiceItem) this.refreshServiceItem();
            this.handleLabel();
        },
    },
    mounted() {
        if (this.initial !== -1) {
            console.log(this.initial, 'initial');
            this.selectedItem = this.initial;
        }
    },
    methods: {
        handleLabel() {
            if (this.selectedItem == null) {
                return this.defaultLabel;
            }
            return this.options[this.selectedItem].text;
        },
    },
    computed: {
        commonAttrs() {
            return {
                persistent: true,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
div.select-button {
    cursor: pointer;
    width: 103px;
    height: 48px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $color_main_black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px 0px 16px;
    background: #f9f6f4;
    border-radius: 16px;
}

.v-sheet {
    &.select-sheet {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: #ffffff;
        border-radius: 12px 12px 0px 0px;
        padding-bottom: 80px !important;
        margin-bottom: 0px !important;
        .v-card__text.select-sheet-card-text {
            height: 180px;
            padding: 0;
        }
    }
}

.v-list-item {
    &.select-sheet-list-item {
        color: $color_main_black;
        &.v-item--active {
            background: #fffbf5;
            color: #ff9f4b;
        }
        &:before {
            background: none !important;
        }
        .v-list-item__content {
            padding: 5px 0;
        }
        .v-list-item__title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
</style>
