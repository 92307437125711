/* eslint-disable prettier/prettier */
<template>
  <v-main class="customMain">
    <PopupDate @click:close="handleDateClose" v-show="!$store.state.isChoose" @customEvent="handleCustomEvent" />
    <Banner img-name="bannerSign.png" />
    <PageTitleBar isRequired :title="step === 1 ? '帳號密碼' : '基本資料'" />
    <v-container class="register-container pl-8 mb-4 mt-4" style="padding-right: 31px">
      <PopupAlert v-model="showAlert" :errorMsg="errorMsg" :imgSrc="imgSrc" />
      <v-form ref="form" class="" v-model="valid" lazy-validation>
        <div v-show="step === 1" style="display: flex; flex-direction: column">
          <v-col cols="12" sm="12" class="py-0">
            <FancyInput
              v-model="form.phone"
              label="電話(帳號)"
              placeholder="請輸入"
              iconName="ic24Phone.svg"
              :sizes="18"
              type="number"
              :rules="[() => phone_error_msg || !!form.phone || '請輸入正確手機']"
              :isError="handleBlur(/^09\d{8}$/.test(form.phone) && alreadyUse.length === 0, form.phone)"
              hide-details="true"
              :wrongHint="alreadyUse.length !== 0?'帳號已被註冊':'手機格式錯誤'"
              :isChecking="isChecking"
              inputEl="registerPhone"
              required
          />
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <FancyInput
              v-model="form.password"
              label="密碼"
              placeholder="請輸入"
              iconName="ic24Key.svg"
              :sizes="18"
              :setMargin="true"
              persistent-hint
              :isError="handleBlur(form.password.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null, form.password)"
              wrongHint="請輸入6-12位英文及數字混和的密碼"
              defaultHint="6-12位英文及數字混和的密碼"
              :isChecking="isChecking"
              inputEl="registerPassword"
              hide-details="true"
              type="password"
              required
          /></v-col>
          <v-col cols="12" sm="12" class="py-0">
            <FancyInput
              v-model="form.password2"
              label="再次輸入新密碼"
              placeholder="請輸入"
              iconName="ic24Key.svg"
              :sizes="18"
              :setMargin="true"
              persistent-hint
              :isError="handleBlur(!!form.password2 && form.password == form.password2, form.password2)"
              wrongHint="兩次密碼輸入不相同"
              defaultHint="請再次輸入跟上方相同的密碼"
              :isChecking="isChecking"
              inputEl="registerPassword2"
              hide-details="true"
              type="password"
              required
          /></v-col>
        </div>
        <div v-show="step === 2">
          <v-col cols="12" sm="12" class="py-0">
            <FancyInput
              v-model="form.name"
              label="姓名"
              placeholder="請輸入"
              iconName="ic24Name.svg"
              :sizes="18"
              :rules="[() => !!form.name || '請輸入會員名字']"
              :isError="handleBlur(true, form.name)"
              hide-details="true"
              wrongHint="請輸入會員名字"
              :isChecking="isChecking"
              inputEl="registerName"
              required
          /></v-col>
          <v-col cols="12" sm="12" class="py-0">
            <FancyVSelect
              label="生日"
              icon-name="ic24BirthdayBlack.svg"
              :sizes="18"
              :date="chooseDate"
              :rules="[() => !!chooseDate || '請選擇生日']"
              :isError="handleBlur(true, chooseDate)"
              hide-details="true"
              wrongHint="請選擇生日"
              :isChecking="isChecking"
              inputEl="registerBirthday"
              required
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <FancySelect
              v-model="form.gender"
              label="性別"
              iconName="ic24Gender.svg"
              :sizes="18"
              :options="[
                {
                  text: '請選擇',
                  value: '',
                  selected: false,
                  disabled: true,
                },
                { text: '女生', value: 'female' },
                { text: '男生', value: 'male' },
              ]"
              :items="[
                {
                  text: '請選擇',
                  value: '',
                  selected: false,
                  disabled: true,
                },
                { text: '女生', value: 'female' },
                { text: '男生', value: 'male' },
              ]"
              :rules="[() => !!form.gender || '請選擇性別']"
              :isError="handleBlur(true, form.gender)"
              hide-details="true"
              wrongHint="請選擇性別"
              :isChecking="isChecking"
              inputEl="registerGender"
              required
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <FancySelect
              inputEl="registerstoreno"
              v-model="form.reg_storeno"
              label="註冊門市"
              iconName="ic24Name.svg"
              hide-details="true"
              :sizes="18"
              hideCheck
              :items="shopOptions"
              :options="shopOptions"
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0 mb-2" style="display: flex">
            <FancyCheckContract
              :isChecking="isChecking"
              v-model="isAgreeRegisterContract"
              :contractLink="`/register/contract?phone=${form.phone}&name=${form.name}&password=${form.password}&birthday=${chooseDate}&gender=${form.gender}&reg_storeno=${form.reg_storeno}&county=${form.county}&district=${form.district}&address=${form.address}&isSendDM=${form.isSendDM}`"
              :rules="[
                () => !!isAgreeRegisterContract || '請詳閱並同意服務條款',
              ]"
              :wrong="!!isAgreeRegisterContract"
              hide-details="true"
              persistent-hint
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0 mb-5">
            <v-checkbox hide-details="true" color="#FF9F4B" v-model="form.isSendDM">
              <template #label>
                <div :style="{color: '#363230'}" style="font-family: Noto Sans TC; font-size: 12px;">
                  我願意收到
                  <!-- <span style="font-family: Noto Sans TC; color: #D16200" @click="()=>{$router.push(`/dm?from=register&phone=${form.phone}&name=${form.name}&password=${form.password}&birthday=${chooseDate}&gender=${form.gender}&reg_storeno=${form.reg_storeno}&county=${form.county}&district=${form.district}&address=${form.address}&isSendDM=${form.isSendDM}`)}">紙本DM</span> -->
                  <span style="font-family: Noto Sans TC; color: #D16200;">紙本DM</span>
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <div
            v-show="form.isSendDM"
            style="display: flex; flex-direction: column; gap: 16px; justify-content: center"
          >
            <div class="title2-medium" style="width: 100%; text-align: start; color: #0d0c0c">
              聯絡資料 <span style="color: #ff453a">*</span>
            </div>
            <div style="display: flex; column-gap: 10px; flex-wrap: wrap;">
              <FancySelect
                style="width: calc(50% - 5px)"
                v-model="form.county"
                label="居住縣市"
                iconName="ic24Location.svg"
                :sizes="18"
                :items="country_list"
                :options="country_list"
                :rules="[() => !form.isSendDM || !!form.county || '請選擇居住縣市']"
                :isError="handleBlur(true, !form.isSendDM || !!form.county)"
                hide-details="true"
                wrongHint="請選擇居住縣市"
                :isChecking="isChecking"
                inputEl="registerCounty"
              />
              <FancySelect
                style="width: calc(50% - 5px)"
                v-model="form.district"
                label="郵遞區號+區域"
                iconName=""
                :sizes="18"
                :items="zip_select_list"
                :options="zip_select_list"
                :rules="[() => !form.isSendDM || !!form.district || '請選擇郵遞區號+區域']"
                :isError="handleBlur(true, !form.isSendDM || !!form.district)"
                hide-details="true"
                wrongHint="請選擇郵遞區號+區域"
                :isChecking="isChecking"
                inputEl="registerDistrict"
              />
              <FancyInput
                style="width: 100%"
                v-model="form.address"
                label="居住地址"
                placeholder="請輸入"
                :sizes="18"
                iconName="ic24Location.svg"
                type="text"
                :rules="[() => !form.isSendDM || !!form.address || '請輸入居住地址']"
                :isError="handleBlur(true, !form.isSendDM || !!form.address)"
                hide-details="true"
                wrongHint="請輸入居住地址"
                :isChecking="isChecking"
                inputEl="registerAddress"
              />
            </div>
          </div>
        </div>
      </v-form>
    </v-container>
    <div class="btn">
      <div style="display:flex;justify-content:center;gap:8px">
        <Button
          v-show="step === 2"
          class="cancel_btn"
          style="width:132px;"
          text="上一步"
          @click="step = 1"
        />
        <BtnSign :canClick="canNext" :style="{pointerEvents: canNext ? 'auto':'none', width: step === 2?'132px':'218px', color: canNext ? 'white':'#6F6F6F', backgroundColor: canNext ? '#FF9F4B':'#F2F2F2'}" :customStyle="{color:'white',margin:'0'}" :text="step === 1 ? '下一步' : '註冊'" @click="goNext" />
      </div>
      <div
        class="dash-regular-14px"
        style="color: #6f6f6f; margin-top: 8px; cursor: pointer"
        @click.prevent="$router.push('/login')"
      >
        已經有帳號了嗎?
      </div>
    </div>
  </v-main>
</template>

<script>
import Button from '@/components/Atom/Button.vue'
import PageTitleBar from '@/components/PageTitleBar.vue'
import BtnSign from '@/components/BtnSign.vue'
import FancyInput from '@/components/FancyInput.vue'
import Banner from '@/components/Banner.vue'
import FancyCheckContract from '@/components/FancyCheckContract.vue'
import PopupAlert from '@/components/Popup/PopupAlert.vue'
import FancySelect from '@/components/FancySelect.vue'
import FancyVSelect from '@/components/FancyVSelect.vue'
import PopupDate from '@/components/Popup/PopupDate.vue'
import { KgQueryMember, checkPhoneExist, KgGetStore } from '@/apis/api.js'

export default {
  name: 'RegisterIndex',
  components: {
    PopupAlert,
    PageTitleBar,
    BtnSign,
    FancyInput,
    PopupDate,
    Banner,
    FancyCheckContract,
    FancySelect,
    FancyVSelect,
    Button,
  },
  data: () => ({
    isChecking: false,
    firstIn: true,
    chooseDate: '',
    step: 1,
    errorMsg: '',
    alreadyUse: '',
    showAlert: false,
    imgSrc: '',
    valid: true,
    form: {
      phone: '',
      name: '',
      password: '',
      password2: '',
      gender: '',
      birthday_year: '',
      birthday_month: '',
      birthday_day: '',
      reg_storeno: '',
      county: '',
      district: '',
      address: '',
      isSendDM: false,
    },
    isAgreeRegisterContract: false,
    phone_error_msg: '',
    shopOptions: [
      {
        text: '網路門市',
        value: '',
      },
    ],
    zip_select_list: [      
      {
        text: '區域+郵遞區號',
        value: '',
        selected: true,
        disabled: true,
      },
    ],
    district_obj: [
      {
        "city": "臺北市",
        "district": [
          { "name": "中正區", "zip": "100" },
          { "name": "大同區", "zip": "103" },
          { "name": "中山區", "zip": "104" },
          { "name": "松山區", "zip": "105" },
          { "name": "大安區", "zip": "106" },
          { "name": "萬華區", "zip": "108" },
          { "name": "信義區", "zip": "110" },
          { "name": "士林區", "zip": "111" },
          { "name": "北投區", "zip": "112" },
          { "name": "內湖區", "zip": "114" },
          { "name": "南港區", "zip": "115" },
          { "name": "文山區", "zip": "116" }
        ]
      },
      {
        "city": "新北市",
        "district": [
          { "name": "板橋區", "zip": "220" },
          { "name": "新莊區", "zip": "242" },
          { "name": "中和區", "zip": "235" },
          { "name": "永和區", "zip": "234" },
          { "name": "土城區", "zip": "236" },
          { "name": "樹林區", "zip": "238" },
          { "name": "三峽區", "zip": "237" },
          { "name": "鶯歌區", "zip": "239" },
          { "name": "三重區", "zip": "241" },
          { "name": "蘆洲區", "zip": "247" },
          { "name": "五股區", "zip": "248" },
          { "name": "泰山區", "zip": "243" },
          { "name": "林口區", "zip": "244" },
          { "name": "八里區", "zip": "249" },
          { "name": "淡水區", "zip": "251" },
          { "name": "三芝區", "zip": "252" },
          { "name": "石門區", "zip": "253" },
          { "name": "金山區", "zip": "208" },
          { "name": "萬里區", "zip": "207" },
          { "name": "汐止區", "zip": "221" },
          { "name": "瑞芳區", "zip": "224" },
          { "name": "貢寮區", "zip": "228" },
          { "name": "平溪區", "zip": "226" },
          { "name": "雙溪區", "zip": "227" },
          { "name": "新店區", "zip": "231" },
          { "name": "深坑區", "zip": "222" },
          { "name": "石碇區", "zip": "223" },
          { "name": "坪林區", "zip": "232" },
          { "name": "烏來區", "zip": "233" }
        ]
      },
      {
        "city": "桃園市",
        "district": [
          { "name": "桃園區", "zip": "330" },
          { "name": "中壢區", "zip": "320" },
          { "name": "平鎮區", "zip": "324" },
          { "name": "八德區", "zip": "334" },
          { "name": "楊梅區", "zip": "326" },
          { "name": "蘆竹區", "zip": "338" },
          { "name": "大溪區", "zip": "335" },
          { "name": "龍潭區", "zip": "325" },
          { "name": "龜山區", "zip": "333" },
          { "name": "大園區", "zip": "337" },
          { "name": "觀音區", "zip": "328" },
          { "name": "新屋區", "zip": "327" },
          { "name": "復興區", "zip": "336" }
        ]
      },
      {
        "city": "臺中市",
        "district": [
          { "name": "中區", "zip": "400" },
          { "name": "東區", "zip": "401" },
          { "name": "南區", "zip": "402" },
          { "name": "西區", "zip": "403" },
          { "name": "北區", "zip": "404" },
          { "name": "北屯區", "zip": "406" },
          { "name": "西屯區", "zip": "407" },
          { "name": "南屯區", "zip": "408" },
          { "name": "太平區", "zip": "411" },
          { "name": "大里區", "zip": "412" },
          { "name": "霧峰區", "zip": "413" },
          { "name": "烏日區", "zip": "414" },
          { "name": "豐原區", "zip": "420" },
          { "name": "后里區", "zip": "421" },
          { "name": "石岡區", "zip": "422" },
          { "name": "東勢區", "zip": "423" },
          { "name": "和平區", "zip": "424" },
          { "name": "新社區", "zip": "426" },
          { "name": "潭子區", "zip": "427" },
          { "name": "大雅區", "zip": "428" },
          { "name": "神岡區", "zip": "429" },
          { "name": "大肚區", "zip": "432" },
          { "name": "沙鹿區", "zip": "433" },
          { "name": "龍井區", "zip": "434" },
          { "name": "梧棲區", "zip": "435" },
          { "name": "清水區", "zip": "436" },
          { "name": "大甲區", "zip": "437" },
          { "name": "外埔區", "zip": "438" },
          { "name": "大安區", "zip": "439" }
        ]
      },
      {
        "city": "臺南市",
        "district": [
          { "name": "中西區", "zip": "700" },
          { "name": "東區", "zip": "701" },
          { "name": "南區", "zip": "702" },
          { "name": "北區", "zip": "704" },
          { "name": "安平區", "zip": "708" },
          { "name": "安南區", "zip": "709" },
          { "name": "永康區", "zip": "710" },
          { "name": "歸仁區", "zip": "711" },
          { "name": "新化區", "zip": "712" },
          { "name": "左鎮區", "zip": "713" },
          { "name": "玉井區", "zip": "714" },
          { "name": "楠西區", "zip": "715" },
          { "name": "南化區", "zip": "716" },
          { "name": "仁德區", "zip": "717" },
          { "name": "關廟區", "zip": "718" },
          { "name": "龍崎區", "zip": "719" },
          { "name": "官田區", "zip": "720" },
          { "name": "麻豆區", "zip": "721" },
          { "name": "佳里區", "zip": "722" },
          { "name": "西港區", "zip": "723" },
          { "name": "七股區", "zip": "724" },
          { "name": "將軍區", "zip": "725" },
          { "name": "學甲區", "zip": "726" },
          { "name": "北門區", "zip": "727" },
          { "name": "新營區", "zip": "730" },
          { "name": "後壁區", "zip": "731" },
          { "name": "白河區", "zip": "732" },
          { "name": "東山區", "zip": "733" },
          { "name": "六甲區", "zip": "734" },
          { "name": "下營區", "zip": "735" },
          { "name": "柳營區", "zip": "736" },
          { "name": "鹽水區", "zip": "737" },
          { "name": "善化區", "zip": "741" },
          { "name": "大內區", "zip": "742" },
          { "name": "山上區", "zip": "743" },
          { "name": "新市區", "zip": "744" },
          { "name": "安定區", "zip": "745" }
        ]
      },
      {
        "city": "高雄市",
        "district": [
          { "name": "新興區", "zip": "800" },
          { "name": "前金區", "zip": "801" },
          { "name": "苓雅區", "zip": "802" },
          { "name": "鹽埕區", "zip": "803" },
          { "name": "鼓山區", "zip": "804" },
          { "name": "旗津區", "zip": "805" },
          { "name": "前鎮區", "zip": "806" },
          { "name": "三民區", "zip": "807" },
          { "name": "楠梓區", "zip": "811" },
          { "name": "小港區", "zip": "812" },
          { "name": "左營區", "zip": "813" },
          { "name": "仁武區", "zip": "814" },
          { "name": "大社區", "zip": "815" },
          { "name": "岡山區", "zip": "820" },
          { "name": "路竹區", "zip": "821" },
          { "name": "阿蓮區", "zip": "822" },
          { "name": "田寮區", "zip": "823" },
          { "name": "燕巢區", "zip": "824" },
          { "name": "橋頭區", "zip": "825" },
          { "name": "梓官區", "zip": "826" },
          { "name": "彌陀區", "zip": "827" },
          { "name": "永安區", "zip": "828" },
          { "name": "湖內區", "zip": "829" },
          { "name": "鳳山區", "zip": "830" },
          { "name": "大寮區", "zip": "831" },
          { "name": "林園區", "zip": "832" },
          { "name": "鳥松區", "zip": "833" },
          { "name": "大樹區", "zip": "840" },
          { "name": "旗山區", "zip": "842" },
          { "name": "美濃區", "zip": "843" },
          { "name": "六龜區", "zip": "844" },
          { "name": "內門區", "zip": "845" },
          { "name": "杉林區", "zip": "846" },
          { "name": "甲仙區", "zip": "847" },
          { "name": "桃源區", "zip": "848" },
          { "name": "那瑪夏區", "zip": "849" },
          { "name": "茂林區", "zip": "851" },
          { "name": "茄萣區", "zip": "852" }
        ]
      },
      {
        "city": "基隆市",
        "district": [
          { "name": "仁愛區", "zip": "200" },
          { "name": "信義區", "zip": "201" },
          { "name": "中正區", "zip": "202" },
          { "name": "中山區", "zip": "203" },
          { "name": "安樂區", "zip": "204" },
          { "name": "暖暖區", "zip": "205" },
          { "name": "七堵區", "zip": "206" }
        ]
      },
      {
        "city": "新竹市",
        "district": [
          { "name": "東區", "zip": "300" },
          { "name": "北區", "zip": "300" },
          { "name": "香山區", "zip": "300" }
        ]
      },
      {
        "city": "嘉義市",
        "district": [
          { "name": "東區", "zip": "600" },
          { "name": "西區", "zip": "600" }
        ]
      },
      {
        "city": "新竹縣",
        "district": [
          { "name": "竹北市", "zip": "302" },
          { "name": "湖口鄉", "zip": "303" },
          { "name": "新豐鄉", "zip": "304" },
          { "name": "新埔鎮", "zip": "305" },
          { "name": "關西鎮", "zip": "306" },
          { "name": "芎林鄉", "zip": "307" },
          { "name": "寶山鄉", "zip": "308" },
          { "name": "竹東鎮", "zip": "310" },
          { "name": "五峰鄉", "zip": "311" },
          { "name": "橫山鄉", "zip": "312" },
          { "name": "尖石鄉", "zip": "313" },
          { "name": "北埔鄉", "zip": "314" },
          { "name": "峨眉鄉", "zip": "315" }
        ]
      },
      {
        "city": "苗栗縣",
        "district": [
          { "name": "苗栗市", "zip": "360" },
          { "name": "造橋鄉", "zip": "361" },
          { "name": "頭屋鄉", "zip": "362" },
          { "name": "公館鄉", "zip": "363" },
          { "name": "大湖鄉", "zip": "364" },
          { "name": "泰安鄉", "zip": "365" },
          { "name": "銅鑼鄉", "zip": "366" },
          { "name": "三義鄉", "zip": "367" },
          { "name": "西湖鄉", "zip": "368" },
          { "name": "卓蘭鎮", "zip": "369" },
          { "name": "竹南鎮", "zip": "350" },
          { "name": "頭份市", "zip": "351" },
          { "name": "三灣鄉", "zip": "352" },
          { "name": "南庄鄉", "zip": "353" },
          { "name": "獅潭鄉", "zip": "354" },
          { "name": "後龍鎮", "zip": "356" },
          { "name": "通霄鎮", "zip": "357" },
          { "name": "苑裡鎮", "zip": "358" }
        ]
      },
      {
        "city": "彰化縣",
        "district": [
          { "name": "彰化市", "zip": "500" },
          { "name": "芬園鄉", "zip": "502" },
          { "name": "花壇鄉", "zip": "503" },
          { "name": "秀水鄉", "zip": "504" },
          { "name": "鹿港鎮", "zip": "505" },
          { "name": "福興鄉", "zip": "506" },
          { "name": "線西鄉", "zip": "507" },
          { "name": "和美鎮", "zip": "508" },
          { "name": "伸港鄉", "zip": "509" },
          { "name": "員林市", "zip": "510" },
          { "name": "社頭鄉", "zip": "511" },
          { "name": "永靖鄉", "zip": "512" },
          { "name": "埔心鄉", "zip": "513" },
          { "name": "溪湖鎮", "zip": "514" },
          { "name": "大村鄉", "zip": "515" },
          { "name": "埔鹽鄉", "zip": "516" },
          { "name": "田中鎮", "zip": "520" },
          { "name": "北斗鎮", "zip": "521" },
          { "name": "田尾鄉", "zip": "522" },
          { "name": "埤頭鄉", "zip": "523" },
          { "name": "溪州鄉", "zip": "524" },
          { "name": "竹塘鄉", "zip": "525" },
          { "name": "二林鎮", "zip": "526" },
          { "name": "大城鄉", "zip": "527" },
          { "name": "芳苑鄉", "zip": "528" },
          { "name": "二水鄉", "zip": "530" }
        ]
      },
      {
        "city": "南投縣",
        "district": [
          { "name": "南投市", "zip": "540" },
          { "name": "中寮鄉", "zip": "541" },
          { "name": "草屯鎮", "zip": "542" },
          { "name": "國姓鄉", "zip": "544" },
          { "name": "埔里鎮", "zip": "545" },
          { "name": "仁愛鄉", "zip": "546" },
          { "name": "名間鄉", "zip": "551" },
          { "name": "集集鎮", "zip": "552" },
          { "name": "水里鄉", "zip": "553" },
          { "name": "魚池鄉", "zip": "555" },
          { "name": "信義鄉", "zip": "556" },
          { "name": "竹山鎮", "zip": "557" },
          { "name": "鹿谷鄉", "zip": "558" }
        ]
      },
      {
        "city": "雲林縣",
        "district": [
          { "name": "斗南鎮", "zip": "630" },
          { "name": "大埤鄉", "zip": "631" },
          { "name": "虎尾鎮", "zip": "632" },
          { "name": "土庫鎮", "zip": "633" },
          { "name": "褒忠鄉", "zip": "634" },
          { "name": "東勢鄉", "zip": "635" },
          { "name": "臺西鄉", "zip": "636" },
          { "name": "崙背鄉", "zip": "637" },
          { "name": "麥寮鄉", "zip": "638" },
          { "name": "斗六市", "zip": "640" },
          { "name": "林內鄉", "zip": "643" },
          { "name": "古坑鄉", "zip": "646" },
          { "name": "莿桐鄉", "zip": "647" },
          { "name": "西螺鎮", "zip": "648" },
          { "name": "二崙鄉", "zip": "649" },
          { "name": "北港鎮", "zip": "651" },
          { "name": "水林鄉", "zip": "652" },
          { "name": "口湖鄉", "zip": "653" },
          { "name": "四湖鄉", "zip": "654" },
          { "name": "元長鄉", "zip": "655" }
        ]
      },
      {
        "city": "嘉義縣",
        "district": [
          { "name": "太保市", "zip": "612" },
          { "name": "朴子市", "zip": "613" },
          { "name": "布袋鎮", "zip": "625" },
          { "name": "大林鎮", "zip": "622" },
          { "name": "民雄鄉", "zip": "621" },
          { "name": "溪口鄉", "zip": "623" },
          { "name": "新港鄉", "zip": "616" },
          { "name": "六腳鄉", "zip": "615" },
          { "name": "東石鄉", "zip": "614" },
          { "name": "義竹鄉", "zip": "624" },
          { "name": "鹿草鄉", "zip": "611" },
          { "name": "水上鄉", "zip": "608" },
          { "name": "中埔鄉", "zip": "606" },
          { "name": "竹崎鄉", "zip": "604" },
          { "name": "梅山鄉", "zip": "603" },
          { "name": "番路鄉", "zip": "602" },
          { "name": "大埔鄉", "zip": "607" },
          { "name": "阿里山鄉", "zip": "605" }
        ]
      },
      {
        "city": "屏東縣",
        "district": [
          { "name": "屏東市", "zip": "900" },
          { "name": "三地門鄉", "zip": "901" },
          { "name": "霧臺鄉", "zip": "902" },
          { "name": "瑪家鄉", "zip": "903" },
          { "name": "九如鄉", "zip": "904" },
          { "name": "里港鄉", "zip": "905" },
          { "name": "高樹鄉", "zip": "906" },
          { "name": "鹽埔鄉", "zip": "907" },
          { "name": "長治鄉", "zip": "908" },
          { "name": "麟洛鄉", "zip": "909" },
          { "name": "竹田鄉", "zip": "911" },
          { "name": "內埔鄉", "zip": "912" },
          { "name": "萬丹鄉", "zip": "913" },
          { "name": "潮州鎮", "zip": "920" },
          { "name": "泰武鄉", "zip": "921" },
          { "name": "來義鄉", "zip": "922" },
          { "name": "萬巒鄉", "zip": "923" },
          { "name": "崁頂鄉", "zip": "924" },
          { "name": "新埤鄉", "zip": "925" },
          { "name": "南州鄉", "zip": "926" },
          { "name": "林邊鄉", "zip": "927" },
          { "name": "東港鎮", "zip": "928" },
          { "name": "琉球鄉", "zip": "929" },
          { "name": "佳冬鄉", "zip": "931" },
          { "name": "新園鄉", "zip": "932" },
          { "name": "枋寮鄉", "zip": "940" },
          { "name": "枋山鄉", "zip": "941" },
          { "name": "春日鄉", "zip": "942" },
          { "name": "獅子鄉", "zip": "943" },
          { "name": "車城鄉", "zip": "944" },
          { "name": "牡丹鄉", "zip": "945" },
          { "name": "恆春鎮", "zip": "946" },
          { "name": "滿州鄉", "zip": "947" }
        ]
      },
      {
        "city": "宜蘭縣",
        "district": [
          { "name": "宜蘭市", "zip": "260" },
          { "name": "頭城鎮", "zip": "261" },
          { "name": "礁溪鄉", "zip": "262" },
          { "name": "壯圍鄉", "zip": "263" },
          { "name": "員山鄉", "zip": "264" },
          { "name": "羅東鎮", "zip": "265" },
          { "name": "三星鄉", "zip": "266" },
          { "name": "大同鄉", "zip": "267" },
          { "name": "五結鄉", "zip": "268" },
          { "name": "冬山鄉", "zip": "269" },
          { "name": "蘇澳鎮", "zip": "270" },
          { "name": "南澳鄉", "zip": "272" }
        ]
      },
      {
        "city": "花蓮縣",
        "district": [
          { "name": "花蓮市", "zip": "970" },
          { "name": "新城鄉", "zip": "971" },
          { "name": "秀林鄉", "zip": "972" },
          { "name": "吉安鄉", "zip": "973" },
          { "name": "壽豐鄉", "zip": "974" },
          { "name": "鳳林鎮", "zip": "975" },
          { "name": "光復鄉", "zip": "976" },
          { "name": "豐濱鄉", "zip": "977" },
          { "name": "瑞穗鄉", "zip": "978" },
          { "name": "萬榮鄉", "zip": "979" },
          { "name": "玉里鎮", "zip": "981" },
          { "name": "卓溪鄉", "zip": "982" },
          { "name": "富里鄉", "zip": "983" }
        ]
      },
      {
        "city": "臺東縣",
        "district": [
          { "name": "臺東市", "zip": "950" },
          { "name": "綠島鄉", "zip": "951" },
          { "name": "蘭嶼鄉", "zip": "952" },
          { "name": "延平鄉", "zip": "953" },
          { "name": "卑南鄉", "zip": "954" },
          { "name": "鹿野鄉", "zip": "955" },
          { "name": "關山鎮", "zip": "956" },
          { "name": "海端鄉", "zip": "957" },
          { "name": "池上鄉", "zip": "958" },
          { "name": "東河鄉", "zip": "959" },
          { "name": "成功鎮", "zip": "961" },
          { "name": "長濱鄉", "zip": "962" },
          { "name": "太麻里鄉", "zip": "963" },
          { "name": "金峰鄉", "zip": "964" },
          { "name": "大武鄉", "zip": "965" },
          { "name": "達仁鄉", "zip": "966" }
        ]
      },
      {
        "city": "澎湖縣",
        "district": [
          { "name": "馬公市", "zip": "880" },
          { "name": "西嶼鄉", "zip": "881" },
          { "name": "望安鄉", "zip": "882" },
          { "name": "七美鄉", "zip": "883" },
          { "name": "白沙鄉", "zip": "884" },
          { "name": "湖西鄉", "zip": "885" }
        ]
      },
      {
        "city": "金門縣",
        "district": [
          { "name": "金沙鎮", "zip": "890" },
          { "name": "金湖鎮", "zip": "891" },
          { "name": "金寧鄉", "zip": "892" },
          { "name": "金城鎮", "zip": "893" },
          { "name": "烈嶼鄉", "zip": "894" },
          { "name": "烏坵鄉", "zip": "896" }
        ]
      },
      {
        "city": "連江縣",
        "district": [
          { "name": "南竿鄉", "zip": "209" },
          { "name": "北竿鄉", "zip": "210" },
          { "name": "莒光鄉", "zip": "211" },
          { "name": "東引鄉", "zip": "212" }
        ]
      }
    ],
    country_list: [
      {
        text: '縣市',
        value: '',
        selected: true,
        disabled: true,
      },
    ],
    yearOptions: [
      {
        text: '請選擇',
        value: '',
        selected: true,
        disabled: true,
      },
    ],
    dayOptions: [
      {
        text: '日',
        value: '',
        selected: true,
        disabled: true,
      },
    ],
  }),
  beforeCreate() {
    this.$store.state.isLoading = true
  },
  created() {
    if(this.$route.query.read){
      this.step = 2
    }     
    if (this.$route.query.phone) {
      this.form = {
        phone: this.$route.query.phone,
        name: this.$route.query.name,
        password: this.$route.query.password,
        password2: this.$route.query.password,
        birthday: this.$route.query.birthday,
        gender: this.$route.query.gender,
        reg_storeno: this.$route.query.reg_storeno,
        county: this.$route.query.county,
        district: this.$route.query.district,
        address: this.$route.query.address,
        isSendDM: this.$route.query.isSendDM === 'true',
      }
      this.chooseDate = this.$route.query.birthday
    }
    // var now = new Date()
    // var date =
    //   now.getFullYear() +
    //   '-' +
    //   (now.getMonth() + 1 < 10 ? '0' : '') +
    //   (now.getMonth() + 1) +
    //   '-' +
    //   (now.getDate() < 10 ? '0' : '') +
    //   now.getDate()
    // this.form.birthday = date
    
    const parent = this
    KgGetStore('0989187330').then(function (rsp) {
      for (let i in rsp) {
        parent.shopOptions.push({
          text: rsp[i].STO_Name,
          value: rsp[i].STO_Name,
        })
      }
    })
    fetch('/data/index.json').then((res)=>res.json()).then((data)=>{
      for (let i in data) {
        // parent.district_obj[data[i].city] = [
        //   {
        //     text: '區域+郵遞區號',
        //     value: '',
        //     selected: true,
        //     disabled: true,
        //   },
        // ]
        // for (let j in data[i].district) {
        //   parent.district_obj[data[i].city].push({
        //     text: data[i].district[j].zip + data[i].district[j].name,
        //     value: data[i].district[j].zip,
        //   })
        // }
        parent.country_list.push({
          text: data[i].city,
          value: data[i].city,
        })
      }
    })
  },
  mounted() {
    this.removebox()
    this.$nextTick(() => {
      this.$store.state.isLoading = false
    })
    for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 100; i--) {
      this.yearOptions.push({
        text: `${i} (民${i - 1911})`,
        value: `${i}`,
      })
    }
  },
  methods: {
    removebox() {
      let checkbox = document.querySelectorAll('.v-input--selection-controls__ripple')
      let sizeWrap = document.querySelectorAll('.v-input--selection-controls__input')
      let size = document.querySelectorAll('.v-input--selection-controls__input i')
      let slot = document.querySelectorAll('.v-input__slot .v-input--selection-controls__input')
      
      checkbox.forEach((item)=>{
          item.style.display='none';
      })
      size.forEach((item)=>{
          item.style.width='14px';
          item.style.height='14px';
          item.style.transform='scale(0.8)'
      })
      sizeWrap.forEach((item)=>{
          item.style.width='14px';
          item.style.height='14px';
      })
      slot.forEach((item)=>{
          item.style.transform='translateY(1px)';
      })
    },
    handleDateClose() {
      return !!this.chooseDate
    },
    handleBlur(error, item) {
      return error && !!item
    },
    handleCustomEvent(data) {
      this.form.birthday_year = data.slice(0, 4)
      this.form.birthday_month = data.slice(4, 6)
      this.form.birthday_day = data.slice(6, 8)
      this.chooseDate = `${this.form.birthday_year}/${this.form.birthday_month}/${this.form.birthday_day}`;
    },
    daysInMonth(year, month) {
      console.log('daysInMonth', year, month)
      return new Date(year, month, 0).getDate()
    },
    goNext() {
      var parent = this
      if (this.step === 1) {
        checkPhoneExist(parent.form.phone).then(function (rsp) {
          parent.isChecking = true
          if (rsp) {
            console.log('帳號已註冊過')
            parent.alreadyUse = '帳號已被註冊'
            // parent.showAlert = true
          } else if (parent.phone_error_msg) {
            parent.errorMsg = parent.phone_error_msg
            // parent.showAlert = true
          } else if (!parent.form.phone) {
            parent.errorMsg = '請輸入正確手機'
            // parent.showAlert = true
          } else if(!/^09\d{8}$/.test(parent.form.phone)) {
            parent.errorMsg = '手機格式錯誤'
            // parent.showAlert = true
          } else if (
            !parent.form.password ||
            parent.form.password.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) == null
          ) {
            parent.errorMsg = '請輸入6-12位英文及數字混和的密碼'
            // parent.showAlert = true
          } else if (parent.form.password != parent.form.password2) {
            parent.errorMsg = '兩次密碼輸入不相同'
            // parent.showAlert = true
          } else {
            parent.step += 1
            parent.isChecking = false
          }
        })
        return
      }
      var form_data = this.form
      const valid = this.$refs.form.validate()
      // 補預設值
      // if (!form_data.gender) form_data.gender = 'female'
      parent.isChecking = true
      if (valid) {
        if (
          !this.form.birthday_year ||
          !this.form.birthday_month ||
          !this.form.birthday_day
        ) {
          this.errorMsg = '請選擇生日'
          this.showAlert = true
          return
        }
        checkPhoneExist(parent.form.phone).then(function (rsp) {
          if (rsp) {
            // 帳號已註冊過
            console.log('帳號已註冊過')
          } else {
            // 康技查會員
            KgQueryMember(form_data.phone, null).then(function (rsp) {
              console.log('queryMember', rsp)
              if (rsp || rsp == '') {
                if (rsp == true || rsp == 'true') {
                  // app會員
                  console.log('app會員')
                  parent.$router.push(
                    '/register/otp?type=new&phone=' +
                      form_data.phone +
                      '&name=' +
                      form_data.name +
                      '&password=' +
                      form_data.password +
                      '&birthday=' +
                      `${form_data.birthday_year}-${form_data.birthday_month}-${form_data.birthday_day}` +
                      '&gender=' +
                      form_data.gender +
                      '&county=' + form_data.county +
                      '&district=' + form_data.district +
                      '&address=' + form_data.address +
                      '&reg_storeno=' +
                      form_data.reg_storeno +
                      '&isSendDM=' + form_data.isSendDM
                  )
                } else {
                  // 門市會員 -> 啟用
                  console.log('門市會員 -> 啟用')
                  if (rsp.MEB_Name) form_data.name = rsp.MEB_Name
                  if (rsp.MEB_Birth) form_data.birthday = rsp.MEB_Birth
                  if (rsp.MEB_Sex) form_data.gender = rsp.MEB_Sex
                  parent.$router.push(
                    '/register/otp?type=old&phone=' +
                      form_data.phone +
                      '&name=' +
                      form_data.name +
                      '&password=' +
                      form_data.password +
                      '&birthday=' +
                      `${form_data.birthday_year}-${form_data.birthday_month}-${form_data.birthday_day}` +
                      '&gender=' +
                      form_data.gender +
                      '&county=' + form_data.county +
                      '&district=' + form_data.district +
                      '&address=' + form_data.address +
                      '&reg_storeno=' +
                      form_data.reg_storeno +
                      '&isSendDM=' + form_data.isSendDM
                  )
                }
              } else {
                console.log('KgQueryMember error')
                // register(form_data).then(function(rsp){
                //     console.log("register",rsp);
                //     parent.$router.push('/register/otp?type=new&phone=' + form_data.phone + "&name=" + form_data.name + "&password=" + form_data.password + "&birthday=" + form_data.birthday.replace("/","").replace("/","") + "&gender=" + form_data.gender);
                // })
              }
            })
          }
        })
      } else {
        if (this.phone_error_msg) {
          this.errorMsg = this.phone_error_msg
        } else if (!this.form.phone) {
          this.errorMsg = '請輸入正確手機'
        } else if(!/^09\d{8}$/.test(this.form.phone)) {
          this.errorMsg = '手機格式錯誤'
        } else if (
          !this.form.password ||
          this.form.password.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) == null
        ) {
          this.errorMsg = '請輸入6-12位英文及數字混和的密碼'
        } else if (this.form.password != this.form.password2) {
          this.errorMsg = '兩次密碼輸入不相同'
        } else if (!this.form.name) {
          this.errorMsg = '請輸入會員姓名'
        } else if (
          !this.form.birthday_year ||
          !this.form.birthday_month ||
          !this.form.birthday_day
        ) {
          this.errorMsg = '請選擇生日'
        }
        else if (!this.isAgreeRegisterContract) {
          this.errorMsg = '為了確保您的權益，請務必詳閱服務條款'
        }
        this.imgSrc = '@/assets/images/imgWarning.svg'
        // this.showAlert = true
      }
    },
  },
  computed: {
    canNext: function () {
      return !!this.form.phone && !!this.form.password && !!this.form.password2
    },
    // canSend: function () {
    //   if(this.form.isSendDM){
    //     return !!this.form.name && !!this.form.birthday && !!this.form.gender && !!this.form.county && !!this.form.district && !!this.form.address
    //   }else{
    //     return !!this.form.name && !!this.form.birthday && !!this.form.gender
    //   }
    // },
  },
  watch: {
    'form.phone': function () {
      this.phone_error_msg = ''
    },
    'form.county': function () {
      let list = this.district_obj.filter((item) => item.city === this.form.county)[0].district
      
      if(list.filter((item) => item.zip === this.$route.query.district).length === 0){
        this.form.district = ''
      }
      let newList = JSON.parse(JSON.stringify(list))
      let mapList = newList.map((item) => {
        return {
          text: item.zip+item.name,
          value: item.zip,
        }
      })
      mapList.unshift({
        text: '區域+郵遞區號',
        value: '',
        selected: true,
        disabled: true,
      })
      this.zip_select_list = mapList
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel_btn {
  background-color: #f2f2f2;
  color: #6f6f6f;
  border-radius: 18px;
  padding: 16px;
  &:active {
    background-color: #D16200;
    color: white;
  }
}
.btn {
  margin-top: auto !important;
  margin-bottom: 40px !important;
}
.customMain {
  height: 100% !important;
}
.register-container {
  margin: 12px 0;
  // padding: 10px 0 0 0;
}
</style>
