<template>
    <v-container>
        <p class="subject text-left px-4">單選服務</p>
        <v-container fluid class="d_flex px-1">
            <div
                v-for="(option, i) in options"
                :key="i"
                class="select_item service_option d_flex"
                :class="{ checked: checkDetect(option.title) }"
                @click="handleCheckStatus(option.title)"
            >
                <img
                    :src="require('@/assets/images/' + option.imgSrc)"
                    class="cover"
                    alt=""
                />
                <img
                    v-if="checkDetect(option.title)"
                    src="@/assets/images/lb_check.svg"
                    class="chk"
                    alt=""
                />
                <p>{{ option.title }}</p>
            </div>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'ServiceSelection',
    components: {},
    data: () => ({
        checkedList: [],
    }),
    created() {
        var parent = this;
        setInterval(function () {
            parent.checkedList = Object.assign([], parent.value);
        }, 100);
    },
    props: {
        options: {
            type: Array,
        },
        value: {
            type: Array,
        },
    },
    emits: ['input'],
    methods: {
        handleCheckStatus(checked) {
            /* Multi*/
            // const idx = this.checkedList.indexOf(checked);
            // if (idx > -1) {
            //     this.checkedList.splice(idx, 1);
            // } else {
            //     this.checkedList.push(checked);
            // }
            //
            /* Single*/
            this.checkedList = [checked];
            this.$emit('input', this.checkedList);
        },
        checkDetect(checked) {
            return this.checkedList.indexOf(checked) > -1;
        },
    },

    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
p.subject {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $color_main_grey_dark;
    > span {
        font-weight: 400;
        font-size: 12px;
    }
}
.select_item {
    position: relative;
    // padding-bottom: 100%;
    background-size: contain;
    border-radius: 16px;
    overflow: hidden;
    // background-color: #000;
    p,
    .chk {
        position: absolute;
        color: #fff;
        font-weight: bold;
        top: calc(50% + 35px);
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
    }
    .chk {
        border-radius: 50%;
        // border: 2px solid #fff;
        width: 38px;
        top: calc(50% - 5px);
    }
    .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    &.checked {
        .cover {
            opacity: 0.7;
        }
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.4) 100%
        );
    }
}
.service_option {
    width: calc(50% - 24px);
    margin: 12px auto;
}
</style>
