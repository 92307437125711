var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"login-main"},[_c('PopupAlert',{attrs:{"type":_vm.getType,"errorMsg":_vm.errorMsg,"imgSrc":_vm.imgSrc},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}}),_c('v-container',{staticClass:"login-container"},[_c('Banner',{attrs:{"img-name":"bannerLogin.png"}}),_c('div',{staticClass:"login-box"},[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"電話(帳號)","placeholder":"請輸入","is-required":true,"sizes":18,"rules":[
                                () =>
                                    _vm.accountMsg ||
                                    !!_vm.form.account ||
                                    '請輸入電話',
                            ],"iconName":"ic24Phone.svg","inputEl":"passWordAccount","is-error":!_vm.check.account,"hide-details":"true","isError":_vm.handleBlur(/^09\d{8}$/.test(_vm.form.account) && _vm.accountMsg.length === 0, _vm.form.account),"wrongHint":_vm.accountMsg.length !== 0 ? '帳號或密碼有誤':'請輸入正確手機',"isChecking":_vm.isChecking,"required":""},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"密碼","placeholder":"請輸入","is-required":true,"hint":"6-12位英文及數字混合的密碼","persistent-hint":"","type":"password","sizes":18,"rules":[
                                () =>
                                    _vm.passwordMsg ||
                                    !!_vm.form.password ||
                                    '請輸入密碼',
                            ],"iconName":"ic24Key.svg","inputEl":"passWordlogin","isError":_vm.handleBlur(_vm.form.password.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null && _vm.passwordMsg.length === 0, _vm.form.password),"hide-details":"true","wrongHint":_vm.passwordMsg.length !== 0 ? '帳號或密碼有誤':'請輸入6-12位英文及數字混和的密碼',"defaultHint":"6-12位英文及數字混合的密碼","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)],1)],1),_c('div',{staticClass:"login-footer d-flex justify-center"},[_c('BtnSign',{style:({pointerEvents: _vm.canNext ? 'auto':'none', color: _vm.canNext ? 'white':'#6F6F6F', backgroundColor: _vm.canNext ? '#FF9F4B':'#F2F2F2'}),attrs:{"canClick":_vm.canNext,"text":"登入"},on:{"click":function($event){$event.preventDefault();return _vm.goNext.apply(null, arguments)}}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"16px","margin-top":"24px"}},[_c('div',{staticClass:"login-box--forgot"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/register')}}},[_vm._v(" 還沒註冊嗎? ")])]),_c('div',{staticClass:"login-box--forgot"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/forgot-password')}}},[_vm._v(" 忘記密碼? ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }