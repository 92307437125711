<template>
    <div class="page_title_bar d_flex pl-8 pt-4 mb-4">
        <h1 class="title2-medium">{{ title }}</h1>
        <!-- <img
            v-if="hasLogo"
            class="logo"
            alt="logo"
            src="@/assets/images/logo.svg"
        /> -->
        <p v-if="isRequired" class="title2-medium" style="color:#FF453A">*</p>
    </div>
</template>

<script>
export default {
    name: 'PageTitleBar',
    props: {
        title: String,
        isRequired: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.page_title_bar {
    height: 20px;
    align-items: center;
    justify-content: start;
    > h1 {
        color: #0D0C0C;
        font-weight: bold;
        font-size: 26px;
        margin-right: 10px;
    }
}
</style>
