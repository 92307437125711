<template>
    <v-main>
        <BackOnTop :title="title" />

        <v-container
            fluid
            class="salon-container pt-3 px-8 mt-3 mb-4"
            v-if="!$store.state.isLoading"
        >
            <div
                class="style-title-m"
                style="text-align: left; font-size: 16px; margin-bottom: 24px"
            >
                預約時間<span style="" class="required-icon">*</span>
            </div>
            <div
                class="style-body-regular"
                style="text-align: left; margin-bottom: 8px"
            >
                選擇日期
            </div>
            <SalonDatePicker
                v-model="selectedDate"
                :startDate="startDate"
                :endDate="endDate"
                :disabled-dates="disabledDates"
            />
            <div
                class="style-body-regular"
                style="text-align: left; margin-bottom: 10px"
            >
                選擇時段
            </div>
            <div
                class="row"
                v-for="(condition, c_index) in [
                    [0, 1, 2],
                    [3, 4, 5],
                    [6, 7, 8],
                ]"
                :key="c_index"
            >
                <div
                    v-for="(timeInfo, index) in listTimes.filter(function (
                        obj,
                        i
                    ) {
                        return condition.indexOf(i) != -1;
                    })"
                    :key="index"
                    @click="
                        timeInfo.isFull ? '' : (selectedTime = timeInfo.id);
                        goNext();
                    "
                    :class="`style-btn ${
                        timeInfo.isFull
                            ? 'style-time-disable-btn'
                            : selectedTime == timeInfo.time
                            ? 'style-light-btn'
                            : 'style-border-btn'
                    }`"
                    :style="`${
                        index != condition.length - 1
                            ? 'margin-right: 12px;font-weight:400 !important;'
                            : 'font-weight: 400 !important;'
                    }`"
                >
                    {{ timeInfo.time }}
                </div>
            </div>
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
import SalonDatePicker from '@/components/NewStyle/SalonDatePicker.vue';
import {
    getReserveInfo,
    getQueryTotalOfServItemQtyADayIsZero,
} from '@/apis/api.js';

export default {
    name: 'SalonOrderTime',
    components: {
        BackOnTop,
        SalonDatePicker,
    },
    data: () => ({
        title: '美容預約',
        selectedDate: '',
        selectedTime: '',
        startDate: '',
        endDate: '',
        listTimes: [],
        query: {},
        store: '',
        typeDict: {
            洗澡: 'e_wash',
            剪毛: 'e_cut',
            草本SPA: 'e_spa',
            游泳: 'e_swim',
            '洗澡+SPA': 'e_washspa',
            '洗澡+游泳': 'e_washswim',
            '剪毛+SPA': 'e_cutspa',
            '剪毛+游泳': 'e_cutswim',
        },
        timeList: ['10', '11', '12', '13', '14', '15', '16', '17', '18'],
        disabledDates: [],
        service_index_list: [
            '洗澡',
            '剪毛',
            '游泳',
            '洗澡+SPA',
            '洗澡+游泳',
            '剪毛+SPA',
            '剪毛+游泳',
        ],
    }),
    mounted() {
        const parent = this;
        if (this.$route.query.reserve_no) {
            this.title = '修改預約';
        }
        let servitem = this.service_index_list.indexOf(
            decodeURIComponent(this.query.type).replace(' ', '+')
        );
        getQueryTotalOfServItemQtyADayIsZero(
            this.startDate.replace(/-/g, ' '),
            this.endDate.replace(/-/g, ' '),
            servitem,
            this.store
        ).then(function (rsp) {
            console.log(rsp);
            parent.disabledDates = rsp.map(function (obj) {
                return `${obj.date.slice(
                    0,
                    4
                )}-${obj.date.slice(4, 6)}-${obj.date.slice(6, 8)}`;
            });
            console.log(parent.disabledDates);
            parent.$store.state.isLoading = false;
        });
    },
    created() {
        this.query = this.$route.query;
        this.store = decodeURIComponent(this.query.store).split(' ')[0];
        var numberOfMlSeconds = new Date().getTime();
        var newDateObj = new Date();
        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 3);
        this.startDate =
            newDateObj.getFullYear() +
            '-' +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1)
                : newDateObj.getMonth() + 1) +
            '-' +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate()
                : newDateObj.getDate());
        this.selectedDate = this.startDate;

        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
        this.endDate =
            newDateObj.getFullYear() +
            '-' +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1)
                : newDateObj.getMonth() + 1) +
            '-' +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate()
                : newDateObj.getDate());
        console.log(this.startDate, this.endDate);
    },
    computed: {},
    methods: {
        goNext() {
            console.log(this.selectedDate, this.selectedTime);
            if (this.selectedDate && this.selectedTime) {
                this.query.date = this.selectedDate;
                this.query.time = this.selectedTime;
                this.$router.push({
                    path: '/salon/order/confirm',
                    query: this.query,
                });
            }
        },
    },
    watch: {
        selectedDate() {
            var parent = this;
            var time_list = this.timeList;
            parent.listTimes = [];
            for (let i in time_list) {
                getReserveInfo(
                    this.selectedDate.replace(/-/g, ''),
                    this.query.store.split(' ')[0],
                    time_list[i]
                ).then(function (rsp) {
                    var type = decodeURIComponent(parent.query.type).replace(
                        ' ',
                        '+'
                    );
                    parent.listTimes.push({
                        id: time_list[i],
                        time: time_list[i] + ':00',
                        text:
                            rsp[parent.typeDict[type]] == 0 ||
                            rsp[parent.typeDict[type]] == null
                                ? '額滿'
                                : '尚有' + rsp[parent.typeDict[type]] + '名額',
                        isFull:
                            rsp[parent.typeDict[type]] == 0 ||
                            rsp[parent.typeDict[type]] == null
                                ? true
                                : false,
                    });
                    parent.listTimes.sort(function (a, b) {
                        return a.id - b.id;
                    });
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
}
</style>
