<script>
export default {
  name: "PopupDate",
  props: {
    isSelect: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isHover: false,
      indexHover: '',
      chooseYear: false,
      preChoose: "",
      chooseDay: "",
      date: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      displayText: "",
      yourDay: 0,
      setTotal: "",
    };
  },
  methods: {
    defaultSet() {
      this.year = 2000;
      this.month = 1;
      this.yourDay = 1;
      this.date = new Date(this.year, this.month, this.yourDay);
      this.calendar();
      this.checkselectDay();
    },
    scrollToCurrentYear() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(`.yearWrapper .year_${this.year}`);
        wrapper.scrollIntoView({ block: 'center' })
      })
    },
    checkselectDay() {
      if(this.year === +this.isSelect.split('/')[0] && this.month === +this.isSelect.split('/')[1]) {
        let DateDiv = document.querySelectorAll(".DateDiv");
        DateDiv.forEach((item) => {
          item.style.backgroundColor = "transparent";
          item.style.color = "#000";
        });
        DateDiv[this.yourDay - 1].style.backgroundColor = "#FF9F4B";
        DateDiv[this.yourDay - 1].style.color = "#fff";
      } else if (this.year === this.setTotal[0] && this.month === this.setTotal[1]) {
        let DateDiv = document.querySelectorAll(".DateDiv");
        DateDiv.forEach((item) => {
          item.style.backgroundColor = "transparent";
          item.style.color = "#000";
        });
        DateDiv[this.yourDay - 1].style.backgroundColor = "#FF9F4B";
        DateDiv[this.yourDay - 1].style.color = "#fff";
      }
    },
    setYear(y) {
      const currentYear = new Date().getFullYear() + 1
      return currentYear - y
    },
    getYearMountDate() {
      let zeroMonth = this.month >= 10 ? this.month : "0" + this.month
      let zeroChooseDay = this.chooseDay >= 10 ? this.chooseDay : "0" + this.chooseDay
      this.setTotal = [this.year, this.month, this.chooseDay];
      let total = [this.year, zeroMonth, zeroChooseDay];
      let totalDate = total.join("");
      this.$emit("customEvent", totalDate);
    },
    calendar() {
      const firstDay = new Date(this.year, this.month - 1, 1);
      const lastDay = new Date(this.year, this.month, 0);
      const firstDayIndex = firstDay.getDay();
      const numberOfDays = lastDay.getDate();
      const nextDays = new Date(this.year, this.month - 1, numberOfDays).getDay();
      const prevLastDay = new Date(this.year, this.month, 0).getDate();
      
      this.displayText = {
        month: "long",
        year: "numeric",
      };

      const days = this.$refs.days;
      days.innerHTML = "";

      for (let i = 0; i < firstDayIndex; i++) {
        const div = document.createElement("div");
        div.textContent = prevLastDay - i;
        div.style.padding = "8px";
        div.style.color = "#909090";
        days.prepend(div);
      }

      for (let j = 1; j <= numberOfDays; j++) {
        const div = document.createElement("div");
        div.className = "DateDiv";
        const currDate = new Date(this.year, this.month, j);
        div.dataset.date = currDate.toDateString();
        div.textContent = j;
        days.appendChild(div);
        div.style.cursor = "pointer";
        div.style.padding = "8px";
        div.style.borderRadius = "100%";
        div.addEventListener("click", () => {
          div.style.backgroundColor = "#FF9F4B";
          div.style.color = "#fff";
          this.chooseDay = j;
          this.yourDay = this.chooseDay
          this.getYearMountDate();
          setTimeout(() => {
            this.$store.commit("setIsChoose", true);
          }, 200);
        });
        if (
          currDate.getFullYear() === new Date().getFullYear() &&
          currDate.getMonth() === new Date().getMonth() &&
          currDate.getDate() === new Date().getDate()
        ) {
          div.classList.add("current-date");
        }
      }

      for (let i = nextDays; i < 6; i++) {
        const div = document.createElement('div');
        div.textContent = i - nextDays + 1;
        div.style.padding = "8px";
        div.style.color = "#909090";
        days.appendChild(div);
      }
    },
    previousMonth() {
      if (this.month === 1) {
        this.month = 12;
        this.year--;
      } else {
        this.month--;
      }
      this.date.setMonth(this.month);
      this.calendar();
      this.checkselectDay();

    },
    nextMonth() {
      if (this.month === 12) {
        this.month = 1;
        this.year++;
      } else {
        this.month++;
      }
      this.date.setMonth(this.month);
      this.calendar();
      this.checkselectDay();
    },
    changeYear() {
      this.calendar();
      this.chooseYear = false;
      this.checkselectDay();
    },
    updateYear(selectedYear) {
      this.year = selectedYear
    }
  },
  mounted() {
    this.calendar();
    this.defaultSet()
    this.$watch('isSelect', () => {
      if (this.isSelect && this.isSelect.length > 6) {
        const dateArr = this.isSelect.split('/');
        this.year = parseInt(dateArr[0]);
        this.month = parseInt(dateArr[1]);
        this.yourDay = parseInt(dateArr[2]);
        this.date = new Date(this.year, this.month, this.yourDay);
        this.calendar();
        this.checkselectDay();
      }
    })
  },
  computed: {
    isCho() {
      return this.$store.state.isChoose;
    },
  },
  watch: {
    isCho(newVal) {
      let DateDiv = document.querySelectorAll(".DateDiv");
      if (newVal === false && this.chooseDay) {
        DateDiv.forEach((item) => {
          item.style.backgroundColor = "transparent";
          item.style.color = "#000";
        });
        DateDiv[this.chooseDay - 1].style.backgroundColor = "#FF9F4B";
        DateDiv[this.chooseDay - 1].style.color = "#fff";
      }
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div class="wrapper2">
      <div
        class="wrapper"
        @click="
          () => {
            $store.commit('setIsChoose', true);
          }
        "
      ></div>
      <div class="popup">
        <section id="frame">
          <div id="calendar" :style="{padding: chooseYear?'32px 16px 24px':'32px 16px'}">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              "
            >
              <div class="header-display">
                <div
                  id="display"
                  :class="[chooseYear?'title1-semibold':'underline dash-bold-16px']"
                  style="cursor: pointer;"
                  @click="() => {chooseYear = true; scrollToCurrentYear()}"
                >
                  <span :style="{color: chooseYear ? '#FF9F4B' : 'black'}">{{ year }}年</span> {{ month }}月
                </div>
              </div>
              <div
                style="display: flex; align-items: center; gap: 10px"
                v-show="!chooseYear"
              >
                <div class="arrow left" @click="previousMonth">
                  <img
                    style="transform: rotate(180deg)"
                    width="18"
                    height="18"
                    :src="require(`@/assets/images/ic24Arrow.svg`)"
                    alt=""
                  />
                </div>
                <div class="arrow right" @click="nextMonth">
                  <img
                    width="18"
                    height="18"
                    :src="require(`@/assets/images/ic24Arrow.svg`)"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              v-show="chooseYear"
              class="yearWrapper"
              style="
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                overflow-y: scroll;
                height: 170px;
                width: 100%;
              "
            >
              <div
                :class="`year_${setYear(i)}`"
                class="body-regular"
                :style="{ borderRadius: '8px', backgroundColor: year === setYear(i) ? '#FFFBF5' : 'transparent','color': year === setYear(i) ? '#FF9F4B' : 'black', display: 'flex',justifyContent: 'center',alignItems: 'center', height: '45px' }"
                v-for="(i) in 100"
                :key="i"
                @click="updateYear(setYear(i))"
              >
                {{ setYear(i) }}年
              </div>
            </div>
            <div
              class=""
              style="
                display: flex;
                gap: 16px;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 48px;
              "
              v-show="chooseYear"
            >
              <div
                class="body-medium"
                style="
                  width: 50%;
                  height: 100%;
                  background-color: #f2f2f2;
                  border-radius: 18px;
                  align-content: center;
                  color: #6f6f6f;
                  cursor: pointer;
                "
                @click="chooseYear = false"
              >
                取消
              </div>
              <div
                class="body-semibold"
                style="
                  width: 50%;
                  height: 100%;
                  background-color: #ff9f4b;
                  border-radius: 18px;
                  align-content: center;
                  color: white;
                  cursor: pointer;
                "
                @click="changeYear"
              >
                確認
              </div>
            </div>

            <div class="week body-regular" v-show="!chooseYear">
              <div>日</div>
              <div>一</div>
              <div>二</div>
              <div>三</div>
              <div>四</div>
              <div>五</div>
              <div>六</div>
            </div>
            <div id="days" ref="days" v-show="!chooseYear"></div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.wrapper2{
  touch-action: manipulation;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.underline{
  text-decoration: underline !important;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0d0c0cb2;
  z-index: 9998;
}
.wrapper2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0d0c0cb2;
  z-index: 9998;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 312px;
  min-height: 336px;
  display: flex;
  border-radius: 18px;
  box-shadow: 1px 1px 4px -2px #4b25181f;
  z-index: 9998;
}
#frame {
  width: 100%;
}
#calendar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.arrow {
  cursor: pointer;
  user-select: none;
}

.week,
#days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  color: #0d0c0c;
}

.inactive {
  color: #ccc;
}

.current-date {
  background-color: #ffeb3b;
  border-radius: 50%;
}
</style>
