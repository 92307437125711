<template>
  <v-main class="customMain">
    <BackOnTop title="密碼修改" @click:right="handleSave" />
    <PopupTop :is-show="showAlert" :errorMsg="errorMsg" />
    <v-container fluid class="member-container px-8 mb-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <FancyInput
          inputEl="passWordold"
          v-model="oldPassword"
          label="舊密碼"
          :sizes="18"
          iconName="ic24Name.svg"
          placeholder="請輸入"
          :hint="noPasswordValid && setErrorMode ? '輸入錯誤請，再輸入一次' : '6-12位英文及數字混和'"
          persistent-hint
          :rules="[
            v => !!v || '密碼為必填項',
          ]"
          type="password"
          isRequired
          required
          :isError="handleBlur(oldPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null && !isSendCheck, oldPassword)"
          hide-details="true"
          :wrongHint="oldPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null?'輸入錯誤請，再輸入一次':'請輸入6-12位英文及數字混和的密碼'"
          defaultHint="6-12位英文及數字混和"
          :isChecking="isChecking"
        />
        <FancyInput
          inputEl="passWordnew"
          v-model="newPassword"
          label="新密碼"
          :sizes="18"
          iconName="ic24Name.svg"
          placeholder="請輸入"
          hint="6-12位英文及數字混和"
          persistent-hint
          :rules="[
            () =>
              (!!newPassword &&
                newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null) ||
              '請輸入6-12位英文及數字混和',
          ]"
          type="password"
          isRequired
          required
          :isError="handleBlur(!!newPassword && newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null, newPassword)"
          hide-details="true"
          wrongHint="輸入錯誤請，再輸入一次"
          defaultHint="6-12位英文及數字混和"
          :isChecking="isChecking"
        />
        <FancyInput
          inputEl="passWordnewagain" 
          v-model="newPassword2"
          label="再次輸入新密碼"
          :sizes="18"
          iconName="ic24Name.svg"
          placeholder="請輸入"
          hint="請再次輸入跟上方相同的密碼"
          persistent-hint
          :rules="[
            () =>
              (!!newPassword2 &&
                newPassword == newPassword2 &&
                newPassword2.length >= 6 &&
                newPassword2.length <= 12) ||
              '兩次密碼輸入不相同',
          ]"
          type="password"
          isRequired
          required
          :isError="handleBlur(!!newPassword2 && newPassword == newPassword2 && newPassword2.length >= 6 && newPassword2.length <= 12, newPassword2)"
          hide-details="true"
          wrongHint="兩次密碼輸入不相同"
          defaultHint="請再次輸入跟上方相同的密碼"
          :isChecking="isChecking"
        />
      </v-form>
    </v-container>
    <BtnSign :canClick="canNext" :style="{pointerEvents: canNext ? 'auto':'none', color: canNext ? 'white':'#6F6F6F', backgroundColor: canNext ? '#FF9F4B':'#F2F2F2'}" class="btn" text="完成" @click="handleSave" />
  </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue'
import FancyInput from '@/components/FancyInput.vue'
import { updatePassword, KgUpdatePassword } from '@/apis/api.js'
import PopupTop from '@/components/Popup/PopupTop.vue'
import BtnSign from '@/components/BtnSign.vue'

export default {
  name: 'MemberPassword',
  components: {
    BtnSign,
    BackOnTop,
    FancyInput,
    PopupTop,
  },
  data: () => ({
    isSendCheck: false,
    isChecking: false,
    valid: '',
    noPasswordValid: false,
    noPasswordSame: false,
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
    showAlert: false,
    errorMsg: '',
    imgSrc: '',
    setErrorMode: true, //控制error mode
  }),
  beforeCreate() {
    this.$store.state.isLoading = true
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.state.isLoading = false
    })
  },
  methods: {
    handleBlur(error, item) {
      return error && !!item
    },
    handleSave() {
      var parent = this
      const valid = this.$refs.form.validate()
      parent.noPasswordSame = false
      parent.noPasswordValid = false
      if (valid) {
        updatePassword(localStorage.getItem('phone'), this.oldPassword, this.newPassword).then(
          function (rsp) {
            if (rsp) {
              localStorage.setItem('password', parent.newPassword)
              KgUpdatePassword(
                localStorage.getItem('phone'),
                localStorage.getItem('member_no'),
                parent.oldPassword,
                parent.newPassword
              ).then(function (rsp) {
                if (rsp) {
                  // parent.showAlert = true
                  // parent.imgSrc = '@/assets/images/imgTick.svg'
                  // parent.errorMsg = '修改成功'
                  // setTimeout(() => {
                  //   parent.showAlert = false
                  //   parent.$router.push('/member');
                  // }, 3000)
                  parent.$store.state.isShowAlert = 'password'
                  parent.$router.push('/member');
                } else {
                  parent.showAlert = true
                  parent.imgSrc = '@/assets/images/imgWarning.svg'
                  parent.errorMsg = '修改失敗，請再試一次'
                  parent.isSendCheck = true
                  parent.noPasswordValid = true
                  setTimeout(() => {
                    parent.showAlert = false
                  }, 3000)
                }
              })
            } else {
              parent.showAlert = true
              parent.imgSrc = '@/assets/images/imgWarning.svg'
              parent.errorMsg = '修改失敗，請再試一次'
              parent.isSendCheck = true
              parent.noPasswordValid = true
              setTimeout(() => {
                parent.showAlert = false
              }, 3000)
            }
          }
        )
      } else {
        if (parent.newPassword != parent.newPassword2) {
          parent.showAlert = true
          parent.imgSrc = '@/assets/images/imgWarning.svg'
          parent.errorMsg = '兩次密碼輸入不相同'
          parent.noPasswordSame = true
          setTimeout(() => {
            parent.showAlert = false
          }, 3000)
        }
      }
    },
  },
  computed: {
      canNext: function () {
          return !!this.oldPassword && !!this.newPassword && !!this.newPassword2;
      },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  margin-top: auto;
  margin-bottom: 40px;
}
.customMain {
  background-image: url('~@/assets/images/cat.png');
  background-size: 80%;
  background-position: bottom right;
  height: 100%;
}
.member-container {
  margin: 0px 0;
  padding: 14px 0 0 0;
  p.desc {
    font-size: 16px;
    line-height: 24px;
    color: $color_main_black;
    text-align: left;
  }
  .btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 52px;
  }
}
</style>
