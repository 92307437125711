<template>
    <v-main class="customMain" v-if="!$store.state.isLoading">
        <BackOnTop :url="url.path" :title="$route.query.type === 'new' ? '首次註冊手機驗證' : '簡訊驗證'" />
        <!-- <PopupAlert v-model="isSuccessOpened" :errorMsg="msg" :imgSrc="imgSrc" /> -->
        <PopupTop :is-show="showAlert" :errorMsg="errorMsg" />
        <v-container class="register-container otp px-8 mb-4">
            <p class="subject mb-4 large-title-medium">輸入您<br />收到的驗證碼</p>
            <p class="desc mb-5 body-regular">
                已傳送到您的手機{{ formatPhone }}<br />
                若還沒有收到請按重新傳送<br />
                若無法收到驗證碼，請聯繫大樹寵物官網客服
            </p>
            <p class="resend mb-8 body-regular" :class="{ cd: isCD }" @click="cdTimer(true)">
                重新發送驗證碼
                {{ isCD ? '00:' + cd.toString().padStart(2, '0') : '' }}
            </p>

            <FancyOTP v-model="otp" :isError="isOTPError" />
        </v-container>
        <BtnSign :canClick="canNext" :style="{pointerEvents: canNext ? 'auto':'none', color: canNext ? 'white':'#6F6F6F', backgroundColor: canNext ? '#FF9F4B':'#F2F2F2'}" class="btn mt-9" text="送出" @click="checkVerifyCode()" />
        <FancyDialog v-model="isSuccessOpened">
            <v-container fluid class="dialog_container">
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        margin-bottom: 12px;
                        align-items: start;
                        padding: 0 0 0 32px;
                    "
                >
                    <p class="large-title2-semibold" style="color: #ff9f4b">註冊成功</p>
                    <p class="body-regular" style="color: #0d0c0c">請使用註冊的帳號密碼登入</p>
                </div>
                <div style="display: flex; gap: 16px; margin-bottom: 12px; padding: 16px">
                    <Button
                        style="
                            background-color: #f2f2f2;
                            color: #6f6f6f;
                            border-radius: 18px;
                            padding: 16px;
                            width: 50%;
                        "
                        @click="isSuccessOpened = false">
                            取消
                    </Button>
                    <Button
                        style="
                            background-color: #ff9f4b;
                            color: #fff;
                            border-radius: 18px;
                            padding: 16px;
                            width: 50%;
                        "
                        @click="next()">
                            確認
                    </Button>
                </div>
            </v-container>
        </FancyDialog>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue'
// import FancyButton from '@/components/FancyButton.vue'
import BtnSign from '@/components/BtnSign.vue'
import FancyOTP from '@/components/FancyOTP.vue'
import FancyDialog from '@/components/FancyDialog.vue'
import PopupTop from '@/components/Popup/PopupTop.vue'
// import PopupAlert from '@/components/Popup/PopupAlert.vue'

import {
    register,
    KgRegister,
    checkVerifyCode,
    KgQueryMember,
    KgResetPassword,
    KgUpdatePassword,
    sendVerifyCode,
    updateDeviceId,
    KgQueryMemberAll,
} from '@/apis/api.js'
export default {
    name: 'RegisterOTP',
    components: {
        BackOnTop,
        // FancyButton,
        FancyOTP,
        FancyDialog,
        PopupTop,
        BtnSign,
        // PopupAlert,
    },
    data: () => ({
        isOTPError: false,
        url: '',
        otp: '',
        isSuccessOpened: false,
        isCD: false,
        cd: 60,
        userData: {},
        type: '',
        title: '您為首次註冊會員',
        device_id: '',
        showAlert: false,
        errorMsg: '驗證碼錯誤',
        msg: 'success',
        imgSrc: '',
        isfirst: true,
    }),
    beforeCreate() {
        this.$store.state.isLoading = true
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false
        })
    },
    created() {
        this.type = this.$route.query.type
        this.userData = this.$route.query
        console.log(this.userData)
        if (this.type == 'old') this.title = '門市會員進行啟用作業'
        if (this.type == 'change') this.title = '簡訊驗證'
        this.cdTimer(true)
        // sendVerifyCode(this.$route.query.phone).then(function(rsp){
        //     if(rsp){
        //         console.log("發送成功");
        //     }
        // })
        if (this.type == 'old') {
            this.url = {
                path: '/register',
                query: this.$route.query,
            }
        }
        if (this.type == 'change') {
            this.url = {
                path: '/',
            }
        }
    },
    computed: {
        formatPhone() {
            return this.$route.query.phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 - $2 - $3')
        },
        canNext: function () {
            return !!this.otp;
        },
    },
    methods: {
        next() {
            this.isSuccessOpened = false
            let parent = this
            setTimeout(function () {
                parent.$router.push('/register/pet?prev=otp&type=register')
            })
        },
        cdTimer(start) {
            let parent = this
            if (start && !this.isCD) {
                sendVerifyCode(this.$route.query.phone).then(function (rsp) {
                    if (rsp) {
                        console.log('發送成功')
                        if(!parent.isfirst){
                            parent.$store.state.isLoading = false
                            parent.errorMsg = '已重新寄送，請重新輸入'
                            parent.showAlert = true
                            setTimeout(() => {
                                parent.showAlert = false
                            }, 3000)
                        }
                        parent.isfirst = false
                    }
                })
                this.isCD = true
                this.cd = 60
            }
            if (this.cd > 0) {
                setTimeout(() => {
                    this.cd -= 1
                    this.cdTimer()
                }, 1000)
            }

            if (this.cd == 0) {
                this.isCD = false
            }
        },
        create_code(len) {
            let code = ''
            var codeLength = len //驗證碼的長度
            var random = new Array(
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z'
            ) //隨機數
            for (var i = 0; i < codeLength; i++) {
                //迴圈操作
                var index = Math.floor(Math.random() * 36)
                code += random[index]
            }
            return code
        },
        checkVerifyCode() {
            var parent = this
            parent.$store.state.isLoading = true
            checkVerifyCode(parent.$route.query.phone, parent.otp).then(function (rsp) {
                if (rsp) {
                    // 註冊
                    parent.device_id = parent.create_code(10)
                    if (parent.type == 'change') {
                        // 換機
                        updateDeviceId(
                            parent.userData.phone,
                            parent.userData.password,
                            parent.device_id
                        ).then(function (rsp) {
                            if (rsp) {
                                KgQueryMember(parent.userData.phone, parent.userData.password).then(
                                    function (rsp) {
                                        if (
                                            rsp.toString() != 'true' &&
                                            rsp.toString() != 'false' &&
                                            rsp.toString() != ''
                                        ) {
                                            parent.$store.state.isLoading = false
                                            localStorage.setItem('member_no', rsp)
                                            localStorage.setItem('phone', parent.userData.phone)
                                            localStorage.setItem(
                                                'password',
                                                parent.userData.password
                                            )
                                            localStorage.setItem('device_id', parent.device_id)
                                            parent.$router.push('/')
                                        } else {
                                            parent.$store.state.isLoading = false
                                            parent.errorMsg = '查無此會員，請洽客服人員'
                                            parent.imgSrc = '@/assets/images/imgWarning.svg'
                                            parent.showAlert = true
                                            parent.isOTPError = true
                                            setTimeout(() => {
                                                parent.showAlert = false
                                                parent.isOTPError = false
                                            }, 3000)
                                        }
                                    }
                                )
                            } else {
                                parent.$store.state.isLoading = false
                                parent.errorMsg = '換機失敗，請洽客服人員'
                                // parent.imgSrc = "@/assets/images/imgWarning.svg";
                                parent.showAlert = true
                                parent.isOTPError = true
                                setTimeout(() => {
                                    parent.showAlert = false
                                    parent.isOTPError = false
                                }, 3000)
                            }
                        })
                    } else {
                        if (parent.type == 'old') {
                            // 門市會員啟用
                            KgResetPassword(parent.userData.phone).then(function (rsp) {
                                if (rsp) {
                                    localStorage.setItem('member_no', rsp.member_no)
                                    var ori_password = rsp.password
                                    // debug
                                    // ori_password = "rrrr1234";
                                    KgUpdatePassword(
                                        parent.userData.phone,
                                        localStorage.getItem('member_no'),
                                        ori_password,
                                        parent.userData.password
                                    ).then(function (rsp) {
                                        if (rsp) {
                                            KgQueryMemberAll(
                                                parent.userData.phone,
                                                parent.userData.password
                                            ).then(function (rsp) {
                                                if (
                                                    rsp.toString() != 'true' &&
                                                    rsp.toString() != 'false' &&
                                                    rsp.toString() != ''
                                                ) {
                                                    register(
                                                        parent.userData.phone,
                                                        rsp.MEB_Name,
                                                        parent.userData.password,
                                                        parent.userData.gender,
                                                        parent.userData.birthday
                                                            .replace('/', '')
                                                            .replace('/', '')
                                                            .replace('-', '')
                                                            .replace('-', ''),
                                                        parent.device_id,
                                                        localStorage.getItem('member_no'),
                                                        parent.userData.reg_storeno,
                                                        parent.userData.county,
                                                        parent.userData.district,
                                                        parent.userData.address,
                                                        parent.userData.isSendDM ? '1' : '0'
                                                    ).then(function (rsp) {
                                                        if (rsp) {
                                                            parent.$store.state.isLoading = false
                                                            localStorage.setItem(
                                                                'phone',
                                                                parent.userData.phone
                                                            )
                                                            localStorage.setItem(
                                                                'password',
                                                                parent.userData.password
                                                            )
                                                            localStorage.setItem(
                                                                'device_id',
                                                                parent.device_id
                                                            )
                                                            parent.isSuccessOpened = true
                                                        } else {
                                                            parent.$store.state.isLoading = false
                                                        }
                                                    })
                                                } else {
                                                    parent.$store.state.isLoading = false
                                                    parent.errorMsg = '查無此會員，請洽客服人員'
                                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                                    parent.showAlert = true
                                                    parent.isOTPError = true
                                                    setTimeout(() => {
                                                        parent.showAlert = false
                                                        parent.isOTPError = false
                                                    }, 3000)
                                                }
                                            })
                                        } else {
                                            parent.$store.state.isLoading = false
                                            parent.errorMsg = '舊門市會員啟用失敗，請洽客服人員'
                                            parent.imgSrc = '@/assets/images/imgWarning.svg'
                                            parent.showAlert = true
                                            parent.isOTPError = true
                                            setTimeout(() => {
                                                parent.showAlert = false
                                                parent.isOTPError = false
                                            }, 3000)
                                        }
                                    })
                                } else {
                                    parent.$store.state.isLoading = false
                                    parent.errorMsg = '查無此舊門市會員，請洽客服人員'
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true
                                    parent.isOTPError = true
                                    setTimeout(() => {
                                        parent.showAlert = false
                                        parent.isOTPError = false
                                    }, 3000)
                                }
                            })
                        } else {
                            // 康技註冊
                            KgRegister(
                                parent.userData.phone,
                                parent.userData.name,
                                parent.userData.password,
                                parent.userData.gender,
                                parent.userData.birthday
                            ).then(function (rsp) {
                                if (rsp === 'timeout') {
                                    parent.$store.state.isLoading = false
                                    parent.errorMsg = '伺服器回應超時，請洽再試一次'
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true
                                    parent.isOTPError = true
                                    setTimeout(() => {
                                        parent.showAlert = false
                                        parent.isOTPError = false
                                    }, 3000)
                                } else if (rsp) {
                                    localStorage.setItem('member_no', rsp)
                                    register(
                                        parent.userData.phone,
                                        parent.userData.name,
                                        parent.userData.password,
                                        parent.userData.gender,
                                        parent.userData.birthday
                                            .replace('-', '')
                                            .replace('-', '')
                                            .replace('/', '')
                                            .replace('/', ''),
                                        parent.device_id,
                                        localStorage.getItem('member_no'),
                                        parent.userData.reg_storeno,
                                        parent.userData.county,
                                        parent.userData.district,
                                        parent.userData.address,
                                        parent.userData.isSendDM ? '1' : '0'
                                    ).then(function (rsp) {
                                        if (rsp) {
                                            parent.$store.state.isLoading = false
                                            localStorage.setItem('phone', parent.userData.phone)
                                            localStorage.setItem(
                                                'password',
                                                parent.userData.password
                                            )
                                            localStorage.setItem('device_id', parent.device_id)
                                            parent.isSuccessOpened = true
                                        } else {
                                            parent.$store.state.isLoading = false
                                            parent.errorMsg = '註冊(2)失敗，請洽客服人員'
                                            parent.imgSrc = '@/assets/images/imgWarning.svg'
                                            parent.showAlert = true
                                            parent.isOTPError = true
                                            setTimeout(() => {
                                                parent.showAlert = false
                                                parent.isOTPError = false
                                            }, 3000)
                                        }
                                    })
                                } else {
                                    parent.$store.state.isLoading = false
                                    parent.errorMsg = '註冊(1)失敗，請洽客服人員'
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true
                                    parent.isOTPError = true
                                    setTimeout(() => {
                                        parent.showAlert = false
                                        parent.isOTPError = false
                                    }, 3000)
                                }
                            })
                        }
                    }
                } else {
                    parent.errorMsg = '驗證碼錯誤'
                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                    parent.showAlert = true
                    parent.$store.state.isLoading = false
                    parent.isOTPError = true
                    setTimeout(() => {
                        parent.showAlert = false
                        parent.isOTPError = false
                    }, 3000)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.btn {
    margin-top: auto !important;
    margin-bottom: 40px !important;
}
.customMain {
    background-image: url('~@/assets/images/dog.png');
    background-size: contain;
    background-position: bottom left;
    height: 100% !important;
}
.register-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    &.otp {
        text-align: left;
        p {
            &.subject {
                font-size: 22px;
                color: #0D0C0C;
            }
            &.desc {
                font-size: 14px;
                color: $color_main_black;
            }
            &.resend {
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                color: #ff453a;
                text-decoration: underline;
                &.cd {
                    color: #d9d9d9;
                    text-decoration: none;
                }
            }
        }
    }
}
div.dialog_container {
    position: relative;
    z-index: 1;

    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 24px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
