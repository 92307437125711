<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="預約時段" />
        <SalonSubtitle textLeft="請選擇服務預約時段" />
        <SalonDatePicker
            v-model="selectedDate"
            :startDate="startDate"
            :endDate="endDate"
        />
        <v-container fluid class="salon-container pt-3 px-4 mt-3 mb-4">
            <SalonDateSchedule v-model="selectedTime" :times="listTimes" />
            <SalonBottomButton label="預約" @click="goNext()" />
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import SalonSubtitle from '@/components/SalonSubtitle.vue';
import SalonBottomButton from '@/components/SalonBottomButton.vue';
import SalonDatePicker from '@/components/SalonDatePicker.vue';
import SalonDateSchedule from '@/components/SalonDateSchedule.vue';
import { getReserveInfo, getReserveList } from '@/apis/api.js';

export default {
    name: 'SalonReservedTime',
    components: {
        BackOnTop,
        PageTitleBar,
        SalonSubtitle,
        SalonBottomButton,
        SalonDatePicker,
        SalonDateSchedule,
    },
    data: () => ({
        selectedDate: '2022-04-01',
        selectedTime: '',
        originTime: '',
        originDate: '',
        originItem: '',
        listTimes: [],
        query: {},
        store: '',
        typeDict: {
            洗澡: 'e_wash',
            剪毛: 'e_cut',
            // "草本SPA":'e_spa',
            游泳: 'e_swim',
            '洗澡+SPA': 'e_washspa',
            '洗澡+游泳': 'e_washswim',
            '剪毛+SPA': 'e_cutspa',
            '剪毛+游泳': 'e_cutswim',
        },
        itemList: [
            '洗澡',
            '剪毛',
            '游泳',
            '洗澡+SPA',
            '洗澡+游泳',
            '剪毛+SPA',
            '剪毛+游泳',
        ],
        startDate: '',
        endDate: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    created() {
        this.query = this.$route.query;
        this.store = decodeURIComponent(this.query.store).split(' ')[0];
        var parent = this;
        var numberOfMlSeconds = new Date().getTime();
        var newDateObj = new Date();
        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 2);
        this.startDate =
            newDateObj.getFullYear() +
            '-' +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1)
                : newDateObj.getMonth() + 1) +
            '-' +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate()
                : newDateObj.getDate());
        // this.selectedDate = this.startDate;

        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
        this.endDate =
            newDateObj.getFullYear() +
            '-' +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1)
                : newDateObj.getMonth() + 1) +
            '-' +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate()
                : newDateObj.getDate());
        console.log(this.startDate, this.endDate);
        getReserveList(
            localStorage.getItem('phone'),
            localStorage.getItem('password')
        ).then(function (rsp) {
            // for(let i in rsp){
            rsp.map(function (obj, i) {
                if (rsp[i].c_pbsapptno == parent.$route.query.reserve_no) {
                    parent.originDate =
                        rsp[i].c_resv_date.slice(0, 4) +
                        '-' +
                        rsp[i].c_resv_date.slice(4, 6) +
                        '-' +
                        rsp[i].c_resv_date.slice(6, 8);
                    if (parent.originDate < parent.startDate)
                        parent.selectedDate = parent.startDate;
                    else parent.selectedDate = parent.originDate;
                    parent.originTime = rsp[i].c_resv_time;
                    parent.originItem = rsp[i].e_resv_item;
                }
            });
        });
    },
    computed: {},
    methods: {
        goNext() {
            console.log(this.selectedDate, this.selectedTime);
            if (this.selectedDate && this.selectedTime) {
                this.query.date = this.selectedDate;
                this.query.time = this.selectedTime;
                this.$router.push({
                    path: '/salon/reserved/confirm',
                    query: this.query,
                });
            }
        },
    },
    watch: {
        selectedDate() {
            var parent = this;
            var time_list = [
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
            ];
            parent.listTimes = [];
            for (let i in time_list) {
                getReserveInfo(
                    this.selectedDate.replace(/-/g, ''),
                    this.query.store.split(' ')[0],
                    time_list[i]
                ).then(function (rsp) {
                    var type = decodeURIComponent(parent.query.type);
                    console.log('type', type);
                    if (
                        parent.selectedDate == parent.originDate &&
                        time_list[i] == parent.originTime &&
                        type == parent.itemList[parseInt(parent.originItem)]
                    )
                        rsp[parent.typeDict[type]] =
                            rsp[parent.typeDict[type]] != null
                                ? parseInt(rsp[parent.typeDict[type]]) + 1
                                : 0;

                    // for(let i in rsp){
                    parent.listTimes.push({
                        id: time_list[i],
                        time: time_list[i] + ':00',
                        text:
                            rsp[parent.typeDict[type]] == 0 ||
                            rsp[parent.typeDict[type]] == null
                                ? '額滿'
                                : '尚有' + rsp[parent.typeDict[type]] + '名額',
                        isFull:
                            rsp[parent.typeDict[type]] == 0 ||
                            rsp[parent.typeDict[type]] == null
                                ? true
                                : false,
                    });
                    // }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
}
</style>
