<template>
  <div style="margin-bottom: 12px;">
    <div v-if="iconName">
      <div
        style="
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 8px;
        "
      >
        <p class="body-regular" style="margin: 0" :style="{ color: isError && setErrorMode ? 'red' : '#0D0C0C'}">
          <span v-show="isRequired" class="body-regular" style="color: #ff453a"> * </span
          >{{ label }}
        </p>
        <img :width="sizes" :height="sizes" :src="require(`@/assets/images/${iconName}`)" alt="" />
      </div>
    </div>
    <v-text-field
      class="fancy"
      :class="[`input_${inputEl}`, {'my-0': setMargin }]"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      :type="!isPasswordType ? type : isOpenPassword ? 'text' : 'password'"
      autocomplete="new-password"
      persistent-placeholder
      @input="isInput"
    >
      <!-- <template v-slot:prepend-inner v-if="iconName">
        <div
          style="
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(-120%);
            gap: 4px;
          "
        >
          <p class="body-regular" style="margin: 0" :style="{ color: isError && setErrorMode ? 'red' : '#0D0C0C'}">
            <span v-show="isRequired" class="body-regular" style="color: #ff453a"> * </span
            >{{ label }}
          </p>
          <img :width="sizes" :height="sizes" :src="require(`@/assets/images/${iconName}`)" alt="" />
        </div>
      </template> -->
      <template v-slot:append v-if="isIcon">
        <img
          v-if="isOpenPassword && !searchType"
          width="20"
          height="20"
          :src="!handleInput() ? require(`@/assets/images/ic24ViewOnRed.svg`) : require(`@/assets/images/ic24ViewOn.svg`)"
          @click="isOpenPassword = false"
          alt=""
          style="cursor: pointer"
        />
        <img
          v-if="!isOpenPassword && !searchType"
          width="20"
          height="20"
          :src="!handleInput() ? require(`@/assets/images/ic24ViewOffRed.svg`) : require(`@/assets/images/ic24ViewOff.svg`)"
          @click="isOpenPassword = true"
          alt=""
          style="cursor: pointer"
        />
        <img
          v-if="searchType"
          width="20"
          height="20"
          :src="require(`@/assets/images/ic24Search.svg`)"
          @click="()=>{ $emit('click:search') }"
          alt=""
          style="cursor: pointer"
        />
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
    <div class="caption-regular" style="color: #ff4040; text-align: start; overflow: hidden; transition: 0.3s;display: flex; align-items: flex-end;" :style="{height: handleInput()?'0px':'22px', opacity: handleInput()?'0':'1'}">{{ wrongHint }}</div>
    <div v-if="defaultHint" class="caption-regular" style="color: #6F6F6F; text-align: start; overflow: hidden; transition: 0.3s;display: flex; align-items: flex-end;" :style="{height: handleInput()?'22px':'0px', opacity: handleInput()?'1':'0'}">{{ defaultHint }}</div>
  </div>
</template>

<script>
export default {
  name: 'FancyInput',
  inheritAttrs: false,
  props: {
    iconName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    setMargin: {
      type: Boolean,
      default: false,
    },
    sizes: {
      type: Number,
      default: 24,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    inputEl: {
      type: String,
      default: 'template',
    },
    setErrorMode: {
      type: Boolean,
      default: false,
    },
    wrongHint: {
      type: String,
      default: '',
    },
    defaultHint: {
      type: String,
      default: '',
    },
    isChecking: {
      type: Boolean,
      default: false,
    },
    hideCheck: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    isFirst: true,
    isPasswordType: false,
    isOpenPassword: false,
    isIcon: false
  }),
  created() {
    if (this.type == 'password' || this.searchType){
      this.isPasswordType = true
      this.isIcon = true
      var text = !this.isPasswordType ? this.type : this.isOpenPassword ? 'text' : 'password'
      console.log(text)
    }
  },
  mounted() {},
  computed: {
    commonAttrs() {
      return {
        outlined: true,
        shaped: true,
      }
    },
    checkError() {
      return this.handleInput()
    }
  },
  methods: {
    isInput(){
      this.isFirst = false
    },
    handleInput(){
      if(this.hideCheck) { return true }
      if(this.isFirst && !this.isChecking){
        return this.isFirst
      }
      return this.isError
    },
    getSlotData(){
      const inputFancy = document.querySelector(`.input_${this.inputEl} .v-input__slot`)
      const inputMessage = document.querySelector(`.input_${this.inputEl} .v-messages__message`)
      const textSlot = document.querySelector(`.input_${this.inputEl} .v-text-field__slot input`)
      
      if(this.checkError){
        inputFancy.classList.remove('redBorder')
        textSlot.classList.remove('redText', 'redPlaceholder')
        if(inputMessage){
          inputMessage.classList.remove('redText')
        }
      } else {
        inputFancy.classList.add('redBorder')
        textSlot.classList.add('redText', 'redPlaceholder')
        if(inputMessage){
          inputMessage.classList.add('redText')
        }
      }
    }
  },
  watch: {
    checkError() {
      this.getSlotData()
    }
  }
}
</script>

<style lang="scss">
.redText {
  color: #FF453A !important;
}
.redBorder {
  border: solid 1px #FF453A !important;
}
.redPlaceholder::placeholder {
  color: #FF453A !important;
}
.my-3 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}
div.fancy {
  &.v-text-field .v-input__control .v-input__slot {
    background-color: #fff;
  }
  &.v-text-field.v-input--is-focused .v-input__control .v-input__slot {
    border: solid 1px #FF9F4B !important;
    background-color: #FFFBF5;
  }
  &.v-text-field {
    > .v-input__control {
      color: #0d0c0c !important;
      caret-color: #FF9F4B;
      > .v-input__slot {
        // margin-bottom: 8px;
        border: 1px solid #d9d9d9;
        fieldset {
          color: $color_main_first !important;
          border: none !important;
          padding-left: 12px !important;
        }
        .v-label {
          color: $color_main_grey !important;
          height: 24px !important;
          line-height: 24px !important;
        }
        .v-input__prepend-inner,
        .v-input__append-inner {
          margin-top: auto !important;
          margin-bottom: auto !important;
          padding-right: 0px !important;
        }
      }
      > .v-text-field__details {
        padding-left:0px !important;
        margin-bottom: 0px;
        padding-top: 1px !important;
        > .v-messages {
          font-family: Noto Sans TC !important;
          font-size: 12px !important;
          line-height: 120% !important;
          letter-spacing: 0.48px !important;
          font-weight: regular !important;
          color: #6f6f6f !important;
          &.error--text {
            color: $color_main_red !important;
            > .v-messages__wrapper {
              padding-left: 0px;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              &:before {
                content: '';
                background-image: url('~@/assets/images/ic24Warning.svg');
                background-size: 12px 12px;
                height: 12px;
                width: 12px;
                margin-right: 4px;
              }
              > .v-messages__message {
                font-size: 8px;
                color: $color_main_red;
              }
            }
          }
        }
      }
    }

    &.v-input--is-disabled {
      > .v-input__control {
        color: #0d0c0c !important;
        > .v-input__slot {
          background-color: #f2f2f2;
          border: none;
          fieldset {
            border: none !important;
            color: $color_main_first !important;
          }
          .v-text-field__slot {
            input {
              color: #6f6f6f !important;
            }
            .v-label--active {
              color: $color_main_grey_light !important;
            }
          }
        }
      }
    }
  }
}
</style>
