<template>
    <v-container>
        <p class="subject text-left mb-7">套餐服務</p>
        <v-container fluid class="d_flex justify-space-between">
            <div
                v-for="(option, i) in options"
                :key="i"
                class="service_chip d_flex justify-center align-center mb-4"
                :class="{ checked: checkDetect(option) }"
                @click="handleCheckStatus(option)"
            >
                <p class="service_chip--text">{{ option }}</p>
            </div>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'ServiceChipGroup',
    components: {},
    data: () => ({
        checkedList: [],
    }),
    created() {
        var parent = this;
        setInterval(function () {
            parent.checkedList = Object.assign([], parent.value);
        }, 100);
    },
    props: {
        options: {
            type: Array,
        },
        value: {
            type: Array,
        },
    },
    emits: ['input'],
    methods: {
        handleCheckStatus(checked) {
            /* Multi*/
            // const idx = this.checkedList.indexOf(checked);
            // if (idx > -1) {
            //     this.checkedList.splice(idx, 1);
            // } else {
            //     this.checkedList.push(checked);
            // }
            //
            /* Single*/
            this.checkedList = [checked];
            this.$emit('input', this.checkedList);
        },
        checkDetect(checked) {
            return this.checkedList.indexOf(checked) > -1;
        },
    },

    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
p.subject {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $color_main_grey_dark;
    > span {
        font-weight: 400;
        font-size: 12px;
    }
}

.service_chip {
    cursor: pointer;
    width: calc(33% - 5px);
    height: 36px;

    background: #f8f1ec;
    border-radius: 30px;
    > p.service_chip--text {
        font-size: 14px;
        line-height: 24px;
        color: #ff9f48;
    }
    &.checked {
        border: 1px solid #ff9f48;
        border-radius: 27px;
    }
}
</style>
