<template>
    <v-app id="app" style="min-height: 100%">
        <Loading />
        <router-view />
    </v-app>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { checkDeviceIdMatch } from '@/apis/api.js';
export default {
    name: 'Home',
    components: {
        Loading,
    },
    created() {
        var parent = this;
        let test_account_list = [
            '0911911711',
            '0963915586',
            '0927199567',
            '0970426816',
            '0989187330',
            '0931079960',
            '0910572600',
            '0976808519',
            '0958184517',
            '0956080286',
        ];
        setInterval(function () {
            // console.log(localStorage.getItem('phone'));
            if (
                localStorage.getItem('phone') != null &&
                localStorage.getItem('password') != null &&
                localStorage.getItem('device_id') != null
            ) {
                checkDeviceIdMatch(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    localStorage.getItem('device_id')
                ).then(function (rsp) {
                    // console.log("checkDeviceIdMatch", rsp);
                    if (
                        !rsp &&
                        test_account_list.indexOf(
                            localStorage.getItem('phone')
                        ) == -1
                    ) {
                        localStorage.removeItem('phone');
                        localStorage.removeItem('password');
                        localStorage.removeItem('member_no');
                        parent.$router.push('/login');
                    }
                });
            }
        }, 1000 * 10);
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #0d0c0c;
    max-width: 700px;
    margin: 0 auto;
    nav {
        a {
            font-weight: bold;
            color: #0d0c0c;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    .slick-dots {
        padding: 0;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 10px;
        li {
            margin: 0 8px;
            display: inline-block;
            height: 10px;
            button {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                text-indent: -10000px;
                background-color: rgba(255, 255, 255, 0.5);
            }
            &.slick-active {
                button {
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}
</style>
