<template>
    <div>
        <!-- Slides -->
        <div style="display: flex">
            <img
                v-for="(slide, index) in slides"
                :key="index"
                class="mr-2 style-slide-img"
                @click="
                    openDialog = true;
                    selectedSlide = index;
                "
                :src="slide"
            />
        </div>
        <!-- Popup -->
        <v-dialog class="fancy" v-model="openDialog">
            <div class="dialog_box salon-photo-dialog">
                <v-container fluid class="dialog_container">
                    <div
                        class="d_flex flex-row-reverse mb-4"
                        style="
                            position: absolute;
                            right: 50px;
                            margin-top: 10px;
                        "
                    >
                        <img
                            width="24"
                            height="24"
                            class=""
                            src="@/assets/images/slide-close.png"
                            alt=""
                            @click="openDialog = false"
                        />
                    </div>
                    <img
                        style="
                            width: 90%;
                            box-shadow: -1px 2px 10px -2px rgba(75, 37, 24, 0.08),
                                1px 1px 4px -2px rgba(75, 37, 24, 0.12);
                        "
                        class="mb-n1 salon-big-photo"
                        :src="slides[selectedSlide]"
                        alt=""
                    />
                    <div
                        class="footer d-flex"
                        style="
                            padding-top: 15px;
                            box-shadow: -1px 2px 10px -2px rgba(75, 37, 24, 0.08);
                            padding-top: 20px;
                            background-color: #fff;
                            position: relative;
                            bottom: 20px;
                            height: 30px;
                            justify-content: space-between;
                            margin-top: 5px;
                            border-radius: 30% 30% 0px 0px;
                        "
                    >
                        <p
                            class="style-regular-body"
                            style="margin-left: 40px; color: #6f6f6f"
                        >
                            *照片僅保存2個月*
                        </p>
                        <div class="footer d-flex" style="margin-right: 40px">
                            <!-- <div @click="shareViaWebShare()" style="box-shadow: -1px 2px 10px -2px rgba(75, 37, 24, 0.08), 1px 1px 4px -2px rgba(75, 37, 24, 0.12);margin-right: 16px;justify-content: center;min-width: 28px;height: 28px;background-color: #FF9F4B;border-radius: 100%;display: flex;align-items: center;">
                                <img style="width: 20px;height: 20px;" src="@/assets/images/download-icon.svg">
                            </div> -->
                            <div
                                @click="shareViaWebShare()"
                                style="
                                    box-shadow: -1px 2px 10px -2px rgba(75, 37, 24, 0.08),
                                        1px 1px 4px -2px rgba(75, 37, 24, 0.12);
                                    justify-content: center;
                                    min-width: 28px;
                                    height: 28px;
                                    background-color: #ff9f4b;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                "
                            >
                                <img
                                    style="
                                        min-width: 20px;
                                        height: 20px;
                                        margin-right: 2px;
                                    "
                                    src="@/assets/images/share-icon.svg"
                                />
                            </div>
                        </div>

                        <!-- <FancyButton
                            class="btn"
                            block
                            @click="shareViaWebShare()"
                        >
                            <img
                                width="24"
                                height="24"
                                class="mr-5"
                                src="@/assets/images/ic24Share.svg"
                                alt=""
                            />
                            分享
                        </FancyButton> -->
                    </div>
                </v-container>
            </div>
        </v-dialog>
    </div>
</template>

<script>
// import FancyButton from '@/components/FancyButton.vue';

export default {
    name: 'SalonPhotoSlides',
    components: {
        // FancyButton,
    },
    props: {
        slides: { type: Array },
    },
    data: () => ({
        selectedSlide: '0',
        openDialog: false,
    }),
    methods: {
        handleSlideClick(toggle) {
            toggle();
            this.$nextTick(() => {
                this.openDialog = true;
            });
        },
        async shareViaWebShare() {
            var parent = this;
            let u = navigator.userAgent;
            let is_android =
                u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let is_ios =
                u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) != null ? true : false;
            console.log('platform', u, is_ios, is_android);
            if (!is_android) {
                parent.webkit.messageHandlers.ToApp.postMessage(
                    parent.slides[parent.selectedSlide]
                );
                parent.openDialog = false;
                // const response = await fetch(parent.slides[parent.selectedSlide].replace("http://testcms.greattreepets.com",""));
                // const blob = await response.blob();
                // const filesArray = [
                //     new File(
                //         [blob],
                //         parent.slides[parent.selectedSlide],
                //         {
                //             type: "image/jpeg",
                //             lastModified: new Date().getTime()
                //         }
                //     )
                // ];
                // const shareData = {
                //     files: filesArray,
                // };
                // navigator.share(shareData);
            } else {
                // this.AndroidShareHandler.share(parent.slides[parent.selectedSlide]);
                parent.AndroidShareHandler.share(
                    parent.slides[parent.selectedSlide]
                );
                parent.openDialog = false;
            }
        },
    },
    computed: {
        webShareApiSupported() {
            return navigator.share;
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
div.v-dialog {
    > .dialog_box {
        &.salon-photo-dialog {
            padding: 10px 0px;
        }
        .salon-big-photo {
            border-radius: 10px;
        }
        p.img-desc {
            width: 100%;
            color: $color_main_grey_dark;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }
    }
}
</style>
