<template>
    <v-dialog class="fancy" v-bind="{ ...$attrs }" v-on="$listeners">
        <div class="dialog_box salon-rating-popup">
            <v-container fluid class="dialog_container">
                <div style="text-align: left; margin-bottom: 12px">
                    <div class="popup-title" style="margin-bottom: 10px">
                        美容評分
                    </div>
                    <div class="popup-desc">邀請您為這次美容評分</div>
                </div>
                <div class="popup-desc" style="text-align: left">
                    <SalonRating style="margin-bottom: 12px" v-model="rating" />
                    <div style="margin-bottom: 8px">體驗回饋</div>
                    <FancyTextarea
                        class="mb-6"
                        v-model="feedback"
                        placeholder="請輸入想法"
                        height="130"
                        counter="100"
                        maxlength="100"
                    />
                </div>
                <div
                    class="footer d-flex justify-center"
                    style="margin-bottom: 8px"
                >
                    <button
                        @click="$emit('click:confirm')"
                        class="style-btn style-m-btn style-dark-btn"
                        style="margin: 8px"
                    >
                        取消
                    </button>
                    <button
                        class="style-btn style-m-btn style-light-btn"
                        @click="eval()"
                        style="margin: 8px"
                    >
                        確認
                    </button>
                </div>
            </v-container>
        </div>
    </v-dialog>
</template>

<script>
import FancyTextarea from '@/components/FancyTextarea.vue';
import SalonRating from '@/components/SalonRating.vue';
import { KgEval, createFeedback } from '@/apis/api.js';
export default {
    components: {
        FancyTextarea,
        SalonRating,
    },
    name: 'PopupSalonRating',
    props: {
        serviceList: {
            type: Array,
        },
        STO_No: {
            type: String,
        },
        PBS_ApptNo: {
            type: String,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({ rating: 5, feedback: '' }),
    methods: {
        eval() {
            var parent = this;
            if (this.rating && this.feedback) {
                KgEval(
                    localStorage.getItem('phone'),
                    localStorage.getItem('member_no'),
                    parent.STO_No,
                    parent.PBS_ApptNo,
                    parent.feedback
                ).then(function (rsp) {
                    if (rsp) {
                        createFeedback(
                            localStorage.getItem('phone'),
                            localStorage.getItem('password'),
                            parent.PBS_ApptNo,
                            parent.STO_No,
                            parent.rating,
                            parent.feedback
                        ).then(function (rsp) {
                            if (rsp) parent.$emit('click:confirm');
                        });
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
div.v-dialog {
    > .dialog_box {
        &.salon-rating-popup {
            padding: 24px 20px;
            p.rating-popup--title {
                color: $color_main_black;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}
</style>
