<script>
export default {
  components: {

  },
  name: 'PopupAlert',
  props: {
      errorMsg: {
          type: String,
      },
      imgSrc: {
          type: String,
          default: '',
      },
      isShow: {
        type: Boolean,
        default: false,
      }
  },
  emits: ['input', 'click:confirm'],
  data: () => ({}),
  methods: {

  },
};
</script>

<template>
  <div :class="{'appear':isShow}" class="fancy" overlay-color="transparent">
    <div class="title2-regular card" style="color:#FF9F4B;width:100%;padding:16px 24px;z-index:999;background-color:#fff;text-align:start;border-radius:10px;box-shadow:-1px 2px 10px -2px #4B251814,1px 1px 4px -2px #4B25181F">
      {{errorMsg}}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fancy{
  transition: 0.3s;
  pointer-events: none;
  opacity: 0;
  position:fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -10px);
  width: calc(100% - 48px);
  z-index: 99;
}
.appear{
  transition: 0.2s;
  transform: translate(-50%, 0px);
  opacity: 1;
}
</style>
