<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="Popup Preview" />
        <v-container fluid class="salon-container pt-4 px-4 mb-9 text-left">
            <h2>美容預約</h2>
            <div class="preview-box">
                <v-row>
                    <v-col class="text-left">
                        <p>無法取消</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderDeleteError(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>無法預約</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderUnable(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>無法預約_未新增</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderUnableNotAdd(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>預約失敗_作業過慢</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderError(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>預約成功</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderSuccess(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>預約失敗</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderFail(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>取消預約</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchReservedCancel(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>取消預約_確認</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchReservedCancelConfirm(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>取消預約_成功</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchReservedCancelSuccess(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>預約_確定修改</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchOrderChangeOrder(true)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>服務評比</p>
                    </v-col>
                    <v-col class="text-center">
                        <FancyButton
                            class="btn"
                            width="150"
                            height="48"
                            label="OPEN"
                            isLightOrange
                            @click.prevent="switchSalonRating(true)"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <PopupOrderDeleteError
            v-model="showOrderDeleteError"
            @click:confirm="switchOrderDeleteError(false)"
        />
        <PopupOrderError
            v-model="showOrderError"
            @click:confirm="switchOrderError(false)"
        />
        <PopupOrderSuccess
            v-model="showOrderSuccess"
            @click:confirm="switchOrderSuccess(false)"
        />
        <PopupOrderFail
            v-model="showOrderFail"
            @click:confirm="switchOrderFail(false)"
        />
        <PopupOrderUnable
            v-model="showOrderUnable"
            @click:confirm="switchOrderUnable(false)"
        />
        <PopupOrderUnableNotAdd
            v-model="showOrderUnableNotAdd"
            @click:confirm="switchOrderUnableNotAdd(false)"
        />
        <PopupReservedCancel
            :form="formReservedCancel"
            v-model="showReservedCancel"
            @click:confirm="switchReservedCancel(false)"
        />
        <PopupReservedCancelConfirm
            v-model="showReservedCancelConfirm"
            @click:confirm="switchReservedCancelConfirm(false)"
        />
        <PopupReservedCancelSuccess
            v-model="showReservedCancelSuccess"
            @click:confirm="switchReservedCancelSuccess(false)"
        />
        <PopupSalonRating
            v-model="showSalonRating"
            @click:confirm="switchSalonRating(false)"
        />
        <PopupChangeOrder
            v-model="showChangeOrder"
            @click:confirm="switchChangeOrder(false)"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyButton from '@/components/FancyButton.vue';
import PopupOrderUnable from '@/components/Popup/PopupOrderUnable.vue';
import PopupOrderUnableNotAdd from '@/components/Popup/PopupOrderUnableNotAdd.vue';
import PopupOrderDeleteError from '@/components/Popup/PopupOrderDeleteError.vue';
import PopupOrderError from '@/components/Popup/PopupOrderError.vue';
import PopupOrderSuccess from '@/components/Popup/PopupOrderSuccess.vue';
import PopupOrderFail from '@/components/Popup/PopupOrderFail.vue';
import PopupReservedCancel from '@/components/Popup/PopupReservedCancel.vue';
import PopupReservedCancelConfirm from '@/components/Popup/PopupReservedCancelConfirm.vue';
import PopupReservedCancelSuccess from '@/components/Popup/PopupReservedCancelSuccess.vue';
import PopupSalonRating from '@/components/Popup/PopupSalonRating.vue';
import PopupChangeOrder from '@/components/Popup/PopupChangeOrder.vue';

export default {
    name: 'SalonPopupPreview',
    components: {
        BackOnTop,
        PageTitleBar,
        FancyButton,
        PopupOrderUnable,
        PopupOrderUnableNotAdd,
        PopupOrderDeleteError,
        PopupOrderError,
        PopupOrderSuccess,
        PopupOrderFail,
        PopupReservedCancel,
        PopupReservedCancelConfirm,
        PopupReservedCancelSuccess,
        PopupSalonRating,
        PopupChangeOrder,
    },
    data: () => ({
        showOrderDeleteError: false,
        showOrderUnable: false,
        showOrderUnableNotAdd: false,
        showOrderError: false,
        showOrderSuccess: false,
        showOrderFail: false,
        showReservedCancel: false,
        showReservedCancelConfirm: false,
        showReservedCancelSuccess: false,
        showSalonRating: false,
        showChangeOrder: false,
        formReservedCancel: {
            name: 'scott',
            petName: '大白',
            time: '2021/06/18 14:00',
            store: '八德興豐店',
            reason: '',
            reasonText: '',
        },
        isAgreeRegisterContract: false,
        infos: [
            { key: '會員姓名', value: 'Scott' },
            { key: '聯絡電話', value: '0912344543' },
            { key: '寶貝姓名', value: '大白' },
            { key: '預約門市', value: '八德興豐店' },
            { key: '預約日期', value: '2021/06/18   14:00 ' },
        ],
        services: ['剪毛', '剪指甲', '剪毛', '(加購)spa護膚'],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        switchOrderUnable(isShow) {
            this.showOrderUnable = isShow;
        },
        switchOrderUnableNotAdd(isShow) {
            this.showOrderUnableNotAdd = isShow;
        },
        switchOrderDeleteError(isShow) {
            this.showOrderDeleteError = isShow;
        },
        switchOrderError(isShow) {
            this.showOrderError = isShow;
        },
        switchOrderSuccess(isShow) {
            this.showOrderSuccess = isShow;
        },
        switchOrderFail(isShow) {
            this.showOrderFail = isShow;
        },
        switchReservedCancel(isShow) {
            this.showReservedCancel = isShow;
        },
        switchReservedCancelConfirm(isShow) {
            this.showReservedCancelConfirm = isShow;
        },
        switchReservedCancelSuccess(isShow) {
            this.showReservedCancelSuccess = isShow;
        },
        switchSalonRating(isShow) {
            this.showSalonRating = isShow;
        },
        switchOrderChangeOrder(isShow) {
            this.showChangeOrder = isShow;
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
    h2 {
        margin: 12px 0 18px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
    }
    .s-box {
        margin: 0 0 35px 0;
    }
    .preview-box {
        background: #fafafa;
        border-radius: 16px;
        padding: 24px;
        p {
            color: $color_main_grey_dark;
            font-size: 16px;
            line-height: 24px;
        }
        .row {
            display: flex;
            align-items: center;
            padding: 0 0 16px 0;
            border-bottom: 1px solid #e6e6e6;
            &:last-child {
                border-bottom: none;
                padding: 0;
            }
        }

        .row + .row {
            margin-top: 16px;
        }
        // .row + .row {
        //     border-top: 1px solid #e6e6e6;
        // }
    }
}
</style>
