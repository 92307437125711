<!-- Layout -->
<template>
    <div class="lb_bg" :class="{ show: open }">
        <div id="lb" :class="{ isPrice }">
            <slot />
            <img
                src="~@/assets/images/btn_close.png"
                alt=""
                class="btn_close"
                @click="closeLb"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'LB',
    data: () => ({}),
    props: {
        open: Boolean,
        isPrice: Boolean,
    },
    mounted() {},
    methods: {
        closeLb() {
            this.$emit('closeLb');
        },
    },
};
</script>
<style lang="scss">
.lb_bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: opacity 0.5s;
    opacity: 0;
    z-index: -1000;
    &.show {
        opacity: 1;
        z-index: 100;
    }
}

#lb {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 320px;
    border-radius: 20px;
    background-color: #f8f4f1;
    padding: 24px;
    // overflow: hidden;
    .btn_close {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 46px);
        width: 30px;
    }
    h4 {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 250px;
        line-height: 45px;
        background-image: url(~@/assets/images/imgImgHome09.svg);
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100%;
        font {
            position: relative;
            z-index: 2;
            color: #fff;
            font-weight: bold;
            font-size: 20px;
        }
    }
    &.isPrice {
        transform: translate(-50%, -60%);
        width: 100%;
        max-width: 353px;
        padding: 0px;
    }
}
</style>
