<script>
export default {
  name: "Banner",
  props: {
    imgName: {
      type: String,
      default: ""
    }
  },
}

</script>

<template>
  <div style="width: 100%;">
    <img style="width: 100%;height: 100%" :src='require(`@/assets/images/${imgName}`)' alt="" />
  </div>
</template>

<style scoped>

</style>
