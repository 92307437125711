<template>
  <div style="margin-bottom: 12px;">
    <div
      style="
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
      "
    >
      <p class="body-regular" style="margin: 0"><span v-show="isRequired" class="body-regular" style="color:#FF453A;"> * </span>{{ label }}</p>
      <img
        :width="sizes"
        :height="sizes"
        :src="require(`@/assets/images/${iconName}`)"
        v-if="iconName"
        alt=""
      />
    </div>
    <v-select
      :menu-props="{ maxHeight: '240px', contentClass: 'custom-select-menu' }"
      :class="`input_${inputEl}`"
      class="fancy"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      hide-spin-buttons
      @blur="isInput"
      @click="selectFocus"
    >
      <template v-slot:append>
        <img
          style="transform: rotate(90deg)"
          mt-3
          :width="sizes"
          :height="sizes"
          :src="handleInput()?require(`@/assets/images/ic24Arrow.svg`):require(`@/assets/images/ic24Arrowred.svg`)"
          alt=""
        />
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-select>
    <!-- <v-bottom-sheet scrollable persistent v-model="isOpen">
            <v-card class="select-sheet text-center pa-6">
                <div class="d_flex justify-space-between mb-8">
                    <p>{{ label }}</p>
                    <a @click="isOpen = false">完成</a>
                </div>
                <v-card-text class="select-sheet-card-text">
                    <v-list nav dense>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            mandatory
                        >
                            <v-list-item
                                class="select-sheet-list-item"
                                :ripple="false"
                                v-for="(option, i) in options"
                                :key="i"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="option.text"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-bottom-sheet> -->
      <div v-if="wrongHint" class="caption-regular" style="color: #ff4040; text-align: start; overflow: hidden; transition: 0.3s;display: flex; align-items: flex-end;" :style="{height: handleInput()?'0px':'22px', opacity: handleInput()?'0':'1'}">{{ wrongHint }}</div>
      <div v-if="defaultHint" class="caption-regular" style="color: #6F6F6F; text-align: start; overflow: hidden; transition: 0.3s;display: flex; align-items: flex-end;" :style="{height: handleInput()?'22px':'0px', opacity: handleInput()?'1':'0'}">{{ defaultHint }}</div>
  </div>
</template>

<script>
export default {
  name: 'FancySelect',
  inheritAttrs: true,
  props: {
    iconName: String,
    options: Array,
    sizes: {
      type: Number,
      default: 24,
    },
    label: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    wrongHint: {
      type: String,
      default: '',
    },
    defaultHint: {
      type: String,
      default: '',
    },
    isChecking: {
      type: Boolean,
      default: false,
    },
    inputEl: {
      type: String,
      default: 'template',
    },
    hideCheck: {
      type: Boolean,
      defalut: false,
    }
  },
  data: () => ({ selectedItem: null, isOpen: false, isFirst: true }),
  watch: {
    selectedItem() {
      this.$emit('input', this.options[this.selectedItem].value)
    },
    checkError() {
      this.getSlotData()
    }
  },
  methods: {
    isInput(){
      this.isFirst = false
    },
    handleInput(){
      if(this.hideCheck) { return true }
      if(this.isFirst && !this.isChecking){
        return this.isFirst
      }
      return this.isError
    },
    selectFocus() {
      this.isOpen = true
    },
    getSlotData(){
      const inputFancy = document.querySelector(`.input_${this.inputEl} .v-input__slot`)
      const textSlot = document.querySelector(`.input_${this.inputEl} .v-input__control .v-select__slot .v-select__selections .v-select__selection`)
      
      if(this.checkError){
        inputFancy.classList.remove('redBorder')
        textSlot.classList.remove('redText')
      } else {
        inputFancy.classList.add('redBorder')
        textSlot.classList.add('redText')
      }
    }
  },
  computed: {
    commonAttrs() {
      return {
        outlined: true,
        shaped: true,
      }
    },
    checkError() {
      return this.handleInput()
    }
  },
}
</script>

<style lang="scss">
.custom-select-menu {
  transform: translateY(50px) !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
  padding: 0;
}
.redText {
  color: #FF453A !important;
}
.redBorder {
  border: solid 1px #FF453A !important;
}
.redPlaceholder::placeholder {
  color: #FF453A !important;
}
.v-menu__content {
  transform: translateY(58px);
  border-radius: 14px;
  box-shadow: -1px 2px 10px -2px #4b251814, 1px 1px 4px -2px #4b25181f;
  & .v-list-item__content {
    font-family: Noto Sans TC !important;
    font-size: 14px !important;
    line-height: 120% !important;
    letter-spacing: 0.56px !important;
    font-weight: 400 !important;
    &:hover {
      background-color: #fffbf5;
      color: #ff9f4b;
    }
    & .v-list-item__title {
      text-align: start !important;
    }
  }
}
div.fancy {
  &.v-text-field {
    > .v-input__control {
      > .v-input__slot {
        // margin-bottom: 8px;
        fieldset {
          color: $color_main_first !important;
          padding-left: 12px !important;
          margin: 0;
        }
        .v-label {
          color: $color_main_grey !important;
          height: 24px !important;
          line-height: 24px !important;
        }
        .v-label--active {
          color: $color_main_first !important;
          transform: translate(6px, -20px) scale(0.75) !important;
        }
        .v-input__prepend-inner {
          margin-top: 12px !important;
        }
        > .v-select__slot {
          > .v-input__append-inner {
            margin-top: 12px;
          }
        }
      }
    }
    // &--outlined {
    //     &.v-input--is-focused {
    //         fieldset {
    //             border: none !important;
    //         }
    //     }
    // }
  }
  &.sm_select {
    width: 200px;
    margin-left: auto !important;
    margin-right: auto !important;

    div {
      line-height: 35px;
      min-height: 35px;
      height: 35px;
      padding: 0;
    }
    .v-input__control {
      .v-input__slot {
        background-color: #f8f1ec;
        border: 1px solid $color_main_first;
        border-radius: 18px;
        min-height: 35px;
        box-shadow: none !important;
        fieldset {
          color: transparent !important;
        }
        .v-select__selection {
          color: $color_main_first;
          min-width: 150px;
        }

        > .v-select__slot {
          > .v-input__append-inner {
            margin-top: 0 !important;
            color: #999;
          }
        }
      }
    }
    .v-select__selection--comma {
      margin: 0;
      line-height: 33px;
    }
    .v-input__append-inner {
      margin-right: -10px;
      img {
        height: 33px;
      }
    }
  }
}
div.select-button {
  cursor: pointer;
  width: 103px;
  height: 48px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: $color_main_black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px 0px 16px;
  background: #f9f6f4;
  border-radius: 16px;
}

.v-sheet {
  &.select-sheet {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 80px;
    .v-card__text.select-sheet-card-text {
      height: 180px;
      padding: 0;
    }
  }
}

.v-list-item {
  &.primary--text {
    color: #000 !important;
  }
  &.select-sheet-list-item {
    color: $color_main_black;
    &.v-item--active {
      background: rgba(249, 98, 48, 0.15);
    }
    &:before {
      background: none !important;
    }
    .v-list-item__content {
      padding: 5px 0;
    }
    .v-list-item__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
// .v-text-field > .v-input__control > .v-input__slot .v-input__append-inner
/* 更改選中的選項顏色 */
.v-select-list .v-list-item--active .v-list-item__title {
  color: #FF9F4B !important; /* 設置已選擇項目的文字顏色 */
}

/* 更改選項的背景顏色 */
.v-select-list .v-list-item {
  background-color: #fff !important; /* 設置背景顏色 */
}

/* 更改選中的選項的背景顏色 */
.v-select-list .v-list-item--active {
  background-color: #FFFBF5 !important; /* 設置已選擇項目的背景顏色 */
}
.theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0 !important /* 設置已選擇項目的背景顏色 */
}
</style>
