<template>
    <v-dialog persistent overlay-color="#0D0C0CB2" class="fancy" v-bind="{ ...$attrs }" v-on="$listeners">
        <div class="dialog_box" :style="{'padding':isPadding ? '32px 16px 8px 16px' : ''}">
            <slot />
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'FancyDialog',
    inheritAttrs: false,
    props: {
        isPadding: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        handleDialog(value) {
            this.$emit('input', value);
        },
    },
};
</script>
<style lang="scss">
div.v-dialog {
    box-shadow: none;
    margin: 0;
    margin-left: 31px;
    margin-right: 32px;
    .dialog_box {
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
