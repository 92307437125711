import Vue from 'vue';
import VueRouter from 'vue-router';
import Redirect from '../views/Redirect.vue';
import Default from '../views/Default.vue';
import LoginIndex from '../views/login/LoginIndex.vue';
import RegisterIndex from '../views/register/RegisterIndex.vue';
import RegisterOTP from '../views/register/RegisterOTP.vue';
import RegisterPet from '../views/register/RegisterPet.vue';
import RegisterContract from '../views/register/RegisterContract.vue';
import MemberIndex from '../views/member/MemberIndex.vue';
import MemberInfo from '../views/member/MemberInfo.vue';
import MemberPassword from '../views/member/MemberPassword.vue';
// import MemberContract from '../views/member/MemberContract.vue';
import ForgotPasswordIndex from '../views/forgot-password/ForgotPasswordIndex.vue';
import ForgotPasswordOTP from '../views/forgot-password/ForgotPasswordOTP.vue';
import ForgotPasswordReset from '../views/forgot-password/ForgotPasswordReset.vue';
// import SalonHistory from '../views/salon/SalonHistory.vue';
import SalonProcess from '../views/salon/SalonProcess.vue';
// import SalonProcessRating from '../views/salon/SalonProcessRating.vue';
// import SalonCompleted from '../views/salon/SalonCompleted.vue';
// import SalonReserved from '../views/salon/SalonReserved.vue';
// import SalonReservedEdit from '../views/salon/SalonReservedEdit.vue';
import SalonReservedSelect from '../views/salon/SalonReservedSelect.vue';
import SalonReservedTime from '../views/salon/SalonReservedTime.vue';
import SalonReservedConfirm from '../views/salon/SalonReservedConfirm.vue';
// import SalonOrderEdit from '../views/salon/SalonOrderEdit.vue';
import SalonOrderSelect from '../views/salon/SalonOrderSelect.vue';
// import SalonOrderTime from '../views/salon/SalonOrderTime.vue';
// import SalonOrderConfirm from '../views/salon/SalonOrderConfirm.vue';
import SalonPopupPreview from '../views/salon/SalonPopupPreview.vue';

import SalonReserved from '../views/new_salon/SalonReserved.vue';
import SalonOrderEdit from '../views/new_salon/SalonOrderEdit.vue';
import SalonOrderTime from '../views/new_salon/SalonOrderTime.vue';
// import SalonReservedEdit from '../views/new_salon/SalonReservedEdit.vue';
import SalonProcessRating from '../views/new_salon/SalonProcessRating.vue';
import SalonOrderConfirm from '../views/new_salon/SalonOrderConfirm.vue';
import SalonHistory from '../views/new_salon/SalonHistory.vue';
import MemberContract from '../views/new_member/MemberContract.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/redirect',
        name: 'Redirect',
        component: Redirect,
    },
    {
        path: '/',
        name: 'Default',
        component: Default,
    },
    {
        path: '/login',
        name: 'LoginIndex',
        component: LoginIndex,
    },
    {
        path: '/register',
        name: 'RegisterIndex',
        component: RegisterIndex,
        meta: {
            title: 'Register - Great Tree Pets',
            metaTags: [
                {
                    name: 'description',
                    content: '大樹寵物',
                },
                {
                    property: 'og:description',
                    content: '大樹寵物',
                },
            ],
        },
    },
    {
        path: '/register/otp',
        name: 'RegisterOTP',
        component: RegisterOTP,
    },
    {
        path: '/register/pet',
        name: 'RegisterPet',
        component: RegisterPet,
    },
    {
        path: '/register/contract',
        name: 'RegisterContract',
        component: RegisterContract,
    },
    {
        path: '/member',
        name: 'MemberIndex',
        component: MemberIndex,
    },
    {
        path: '/member/info',
        name: 'MemberInfo',
        component: MemberInfo,
    },
    {
        path: '/member/password',
        name: 'MemberPassword',
        component: MemberPassword,
    },
    {
        path: '/member/contract',
        name: 'MemberContract',
        component: MemberContract,
    },
    {
        path: '/salon',
        redirect: '/salon/history',
    },
    {
        path: '/salon/history',
        name: 'SalonHistory',
        component: SalonHistory,
    },
    {
        path: '/salon/process',
        name: 'SalonProcess',
        component: SalonProcess,
    },
    {
        path: '/salon/process/rating',
        name: 'SalonProcessRating',
        component: SalonProcessRating,
    },
    {
        path: '/salon/completed',
        name: 'SalonCompleted',
        component: SalonProcessRating,
    },
    {
        path: '/salon/reserved',
        name: 'SalonReserved',
        component: SalonReserved,
    },
    {
        path: '/salon/reserved/edit',
        name: 'SalonReservedEdit',
        component: SalonOrderEdit,
    },
    {
        path: '/salon/reserved/select',
        name: 'SalonReservedSelect',
        component: SalonReservedSelect,
    },
    {
        path: '/salon/reserved/time',
        name: 'SalonReservedTime',
        component: SalonReservedTime,
    },
    {
        path: '/salon/reserved/confirm',
        name: 'SalonReservedConfirm',
        component: SalonReservedConfirm,
    },
    {
        path: '/salon/order/edit',
        name: 'SalonOrderEdit',
        component: SalonOrderEdit,
    },
    {
        path: '/salon/order/select',
        name: 'SalonOrderSelect',
        component: SalonOrderSelect,
    },
    {
        path: '/salon/order/time',
        name: 'SalonOrderTime',
        component: SalonOrderTime,
    },
    {
        path: '/salon/order/confirm',
        name: 'SalonOrderConfirm',
        component: SalonOrderConfirm,
    },
    {
        path: '/salon/popup-preview',
        name: 'SalonPopupPreview',
        component: SalonPopupPreview,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordIndex',
        component: ForgotPasswordIndex,
    },
    {
        path: '/forgot-password/otp',
        name: 'ForgotPasswordOTP',
        component: ForgotPasswordOTP,
    },
    {
        path: '/forgot-password/reset',
        name: 'ForgotPasswordReset',
        component: ForgotPasswordReset,
    },
    {
        path: '/point/',
        name: 'PointIndex',
        component: () => import('../views/point/PointIndex.vue'),
    },
    {
        path: '/point/coupon',
        name: 'PointIndex',
        component: () => import('../views/point/PointCouponIndex.vue'),
    },
    {
        path: '/dm/',
        name: 'DmIndex',
        component: () => import('../views/dm/DmIndex.vue'),
    },
    {
        path: '/dm/detail',
        name: 'DmDetail',
        component: () => import('../views/dm/DmDetail.vue'),
    },
    {
        path: '/tools/',
        name: 'ToolIndex',
        component: () => import('../views/tools/ToolIndex.vue'),
    },
    {
        path: '/tools/calculator',
        name: 'Calculator',
        component: () => import('../views/tools/Calculator.vue'),
    },
    {
        path: '/info/',
        name: 'InfoIndex',
        component: () => import('../views/new_info/InfoIndex.vue'),
    },
    {
        path: '/info/news',
        name: 'NewsArticle',
        component: () => import('../views/info/NewsArticle.vue'),
    },
    {
        path: '/info/news_for_msg',
        name: 'NewsArticle',
        component: () => import('../views/new_info/NewsArticle.vue'),
    },
    {
        path: '/info/coupon',
        name: 'CouponIndex',
        component: () => import('../views/info/CouponIndex.vue'),
    },
    {
        path: '/location/',
        name: 'LocationIndex',
        component: () => import('../views/location/LocationIndex.vue'),
    },
    {
        path: '/location/branch',
        name: 'LocationBranch',
        component: () => import('../views/location/LocationBranch.vue'),
    },
    {
        path: '/pet/',
        name: 'PetIndex',
        component: () => import('../views/pet/PetIndex.vue'),
    },
    {
        path: '/pet/edit',
        name: 'EditPet',
        component: () => import('../views/pet/EditPet.vue'),
    },
    {
        path: '/Invoice/index',
        name: 'Invoice',
        component: () => import('../views/Invoice/index.vue'),
    },
    {
        // catch all 404 - define at the very end
        path: '/:pathMatch(.*)*',
        component: () => import('../views/PageNotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
        (el) => el.parentNode.removeChild(el)
    );

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});

export default router;
