var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"customMain"},[_c('BackOnTop',{attrs:{"title":"密碼修改"},on:{"click:right":_vm.handleSave}}),_c('PopupTop',{attrs:{"is-show":_vm.showAlert,"errorMsg":_vm.errorMsg}}),_c('v-container',{staticClass:"member-container px-8 mb-4",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FancyInput',{attrs:{"inputEl":"passWordold","label":"舊密碼","sizes":18,"iconName":"ic24Name.svg","placeholder":"請輸入","hint":_vm.noPasswordValid && _vm.setErrorMode ? '輸入錯誤請，再輸入一次' : '6-12位英文及數字混和',"persistent-hint":"","rules":[
          v => !!v || '密碼為必填項',
        ],"type":"password","isRequired":"","required":"","isError":_vm.handleBlur(_vm.oldPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null && !_vm.isSendCheck, _vm.oldPassword),"hide-details":"true","wrongHint":_vm.oldPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null?'輸入錯誤請，再輸入一次':'請輸入6-12位英文及數字混和的密碼',"defaultHint":"6-12位英文及數字混和","isChecking":_vm.isChecking},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('FancyInput',{attrs:{"inputEl":"passWordnew","label":"新密碼","sizes":18,"iconName":"ic24Name.svg","placeholder":"請輸入","hint":"6-12位英文及數字混和","persistent-hint":"","rules":[
          () =>
            (!!_vm.newPassword &&
              _vm.newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null) ||
            '請輸入6-12位英文及數字混和',
        ],"type":"password","isRequired":"","required":"","isError":_vm.handleBlur(!!_vm.newPassword && _vm.newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null, _vm.newPassword),"hide-details":"true","wrongHint":"輸入錯誤請，再輸入一次","defaultHint":"6-12位英文及數字混和","isChecking":_vm.isChecking},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('FancyInput',{attrs:{"inputEl":"passWordnewagain","label":"再次輸入新密碼","sizes":18,"iconName":"ic24Name.svg","placeholder":"請輸入","hint":"請再次輸入跟上方相同的密碼","persistent-hint":"","rules":[
          () =>
            (!!_vm.newPassword2 &&
              _vm.newPassword == _vm.newPassword2 &&
              _vm.newPassword2.length >= 6 &&
              _vm.newPassword2.length <= 12) ||
            '兩次密碼輸入不相同',
        ],"type":"password","isRequired":"","required":"","isError":_vm.handleBlur(!!_vm.newPassword2 && _vm.newPassword == _vm.newPassword2 && _vm.newPassword2.length >= 6 && _vm.newPassword2.length <= 12, _vm.newPassword2),"hide-details":"true","wrongHint":"兩次密碼輸入不相同","defaultHint":"請再次輸入跟上方相同的密碼","isChecking":_vm.isChecking},model:{value:(_vm.newPassword2),callback:function ($$v) {_vm.newPassword2=$$v},expression:"newPassword2"}})],1)],1),_c('BtnSign',{staticClass:"btn",style:({pointerEvents: _vm.canNext ? 'auto':'none', color: _vm.canNext ? 'white':'#6F6F6F', backgroundColor: _vm.canNext ? '#FF9F4B':'#F2F2F2'}),attrs:{"canClick":_vm.canNext,"text":"完成"},on:{"click":_vm.handleSave}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }