<template>
    <v-main v-if="!$store.state.isLoading">
        <BackOnTop v-if="$route.query.prev != 'otp'" />
        <PageTitleBar title="新增寶貝資料" />
        <PopupRegisterGift
            v-model="showRegisterGift"
            :giftQuantity="giftQuantity"
            @click:confirm="handleRegisterGift"
            @click:close="$router.push('/')"
        />
        <v-container class="register-container px-4 mb-4">
            <v-form ref="form" class="" v-model="valid" lazy-validation>
                <v-row class="mt-0">
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyInput
                            v-model="form.alias"
                            label="寶貝暱稱*(必填)"
                            iconName="ic24Pname.svg"
                            :rules="[() => !!form.alias || '請輸入寶貝暱稱']"
                            :isError="handleBlur(true, form.alias)"
                            wrongHint="請輸入寶貝暱稱"
                            :isChecking="isChecking"
                            inputEl="registerPetAlias"
                            hide-details="true"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancySelect
                            v-model="form.type"
                            iconName="ic24Breed.svg"
                            :rules="[() => !!form.type || '請輸入寶貝種類']"
                            :items="petTypeList"
                            :options="petTypeList"
                            :isError="handleBlur(true, form.type)"
                            wrongHint="請輸入寶貝種類"
                            :isChecking="isChecking"
                            inputEl="registerPetType"
                            hide-details="true"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancySelect
                            v-model="form.breed"
                            label="寶貝品種*(必填)"
                            iconName="ic24Breed.svg"
                            :rules="[() => !!form.breed || '請輸入寶貝品種']"
                            :items="
                                petBreedList.filter(function (obj) {
                                    if (obj.type == form.type) return true;
                                    else return false;
                                })
                            "
                            :options="
                                petBreedList.filter(function (obj) {
                                    if (obj.type == form.type) return true;
                                    else return false;
                                })
                            "
                            :isError="handleBlur(true, form.breed)"
                            wrongHint="請輸入寶貝品種"
                            :isChecking="isChecking"
                            inputEl="registerPetBreed"
                            hide-details="true"
                            required
                        />
                    </v-col>
                    <!-- <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyDate
                            v-model="form.birthday"
                            label="寶貝生日*(必填)"
                            iconName="ic24Birthday.svg"
                            :rules="[() => !!form.birthday || '請輸入寶貝生日']"
                            required
                        />
                    </v-col> -->
                    <v-col cols="6" sm="6" class="py-0">
                        <FancySelect
                            label="寶貝生日 年"
                            v-model="form.birthday_year"
                            iconName="ic24Birthday.svg"
                            :items="yearOptions"
                            :options="yearOptions"
                            hide-details="true"
                            hideCheck
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0">
                        <FancySelect
                            hide-details="true"
                            hideCheck
                            label="月"
                            v-model="form.birthday_month"
                            :options="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                            :items="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0">
                        <FancySelect
                            hide-details="true"
                            hideCheck
                            label="日"
                            v-model="form.birthday_day"
                            :items="dayOptions"
                            :options="dayOptions"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancySelect
                            v-model="form.gender"
                            iconName="ic24PetGender.svg"
                            :rules="[() => !!form.gender || '請輸入寶貝性別']"
                            :options="[
                                {
                                    text: '寶貝性別*(必填)',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '公', value: '01' },
                                { text: '母', value: '02' },
                                { text: '其他', value: '03' },
                            ]"
                            :items="[
                                {
                                    text: '寶貝性別*(必填)',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '公', value: '01' },
                                { text: '母', value: '02' },
                                { text: '其他', value: '03' },
                            ]"
                            :isError="handleBlur(true, form.gender)"
                            wrongHint="請輸入寶貝性別"
                            :isChecking="isChecking"
                            inputEl="registerPetGender"
                            hide-details="true"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancySelect
                            v-model="form.body"
                            iconName="ic24PetBody.svg"
                            :rules="[() => !!form.body || '請輸入寶貝體型']"
                            :options="[
                                {
                                    text: '寶貝體型*(必填)',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '小型犬', value: '2' },
                                { text: '中型犬', value: '4' },
                                { text: '大型犬', value: '6' },
                                { text: '幼貓', value: '7' },
                                { text: '成貓', value: '8' },
                            ]"
                            :items="[
                                {
                                    text: '寶貝體型*(必填)',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '小型犬', value: '2' },
                                { text: '中型犬', value: '4' },
                                { text: '大型犬', value: '6' },
                                { text: '幼貓', value: '7' },
                                { text: '成貓', value: '8' },
                            ]"
                            :isError="handleBlur(true, form.body)"
                            wrongHint="請輸入寶貝體型"
                            :isChecking="isChecking"
                            inputEl="registerPetBody"
                            hide-details="true"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyInput
                            v-model="form.comb"
                            label="毛色*(必填)"
                            iconName="ic24Comb.svg"
                            :rules="[
                                () =>
                                    (!!form.comb && form.comb.length <= 5) ||
                                    '請輸入寶貝毛色或輸入長度過長',
                            ]"
                            :isError="handleBlur(form.comb.length <= 5, form.comb)"
                            wrongHint="請輸入寶貝毛色或輸入長度過長"
                            :isChecking="isChecking"
                            inputEl="registerPetComb"
                            hide-details="true"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyRadio
                            row
                            v-model="form.isNeutered"
                            :items="[
                                { label: '已結紮', value: '1' },
                                { label: '未結紮', value: '0' },
                            ]"
                            :rules="[
                                () =>
                                    (!!form.isNeutered &&
                                        form.isNeutered.length == 1) ||
                                    '請填寫寶貝是否結紮',
                            ]"
                            required
                        />
                    </v-col>
                </v-row>
                <FancyButton
                    class="mb-6"
                    label="完成"
                    width="150"
                    height="48"
                    @click="goNext"
                />
                <br />
                <router-link
                    v-if="isShowJump"
                    class="skip mb-8"
                    :to="{
                        path: '/',
                    }"
                >
                    跳過
                </router-link>
            </v-form>
        </v-container>
        <FancyDialog v-model="isSuccessOpened">
            <v-container fluid class="dialog_pet_success">
                <img
                    width="90"
                    height="90"
                    class="mb-5"
                    src="@/assets/images/img_dog.svg"
                    alt=""
                />
                <p class="title mb-3">恭喜您</p>
                <p class="desc mb-6">已成功新增寶貝資料</p>
                <div class="footer d-flex justify-center">
                    <FancyButton
                        isGreen
                        class="btn mr-3"
                        label="確認"
                        @click="
                            $route.query.prev != 'otp'
                                ? $router.go(-1)
                                : $router.push('/')
                        "
                    />
                    <FancyButton
                        class="btn"
                        color="primary"
                        label="新增其他寶貝"
                        @click="refresh()"
                    />
                </div>
            </v-container>
        </FancyDialog>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyButton from '@/components/FancyButton.vue';
import FancyInput from '@/components/FancyInput.vue';
import FancySelect from '@/components/FancySelect.vue';
import FancyRadio from '@/components/FancyRadio.vue';
import FancyDialog from '@/components/FancyDialog.vue';
import PopupRegisterGift from '@/components/NewPopup/PopupRegisterGift.vue';
import {
    KgGetPetType,
    insertPet,
    KgInsertPet,
    KgGetPetBreed,
    checkRegisterCoupon,
} from '@/apis/api.js';
export default {
    name: 'RegisterPet',
    components: {
        BackOnTop,
        PageTitleBar,
        FancyButton,
        FancyInput,
        FancySelect,
        FancyRadio,
        FancyDialog,
        PopupRegisterGift,
    },
    data: () => ({
        isChecking: false,
        showRegisterGift: false,
        giftQuantity: 1,
        valid: true,
        isSuccessOpened: false,
        form: {
            alias: '',
            type: '',
            birthday: '',
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            gender: '',
            body: '',
            comb: '',
            breed: '',
            isNeutered: true,
        },
        isAgreeRegisterContract: false,
        petTypeList: [
            {
                text: '寶貝種類*(必填)',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
        petBreedList: [
            {
                text: '寶貝品種*(必填)',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
        isShowJump: false,
        yearOptions: [
            {
                text: '生日 年',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
        dayOptions: [
            {
                text: '日',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
    }),
    created() {
        if (this.$route.query.type == 'register') this.isShowJump = true;
        console.log('phone', localStorage.getItem('phone'));
        var parent = this;
        for (
            let i = new Date().getFullYear();
            i >= new Date().getFullYear() - 100;
            i--
        ) {
            this.yearOptions.push({
                text: `${i} (民${i - 1911})`,
                value: `${i}`,
            });
        }
        KgGetPetBreed(localStorage.getItem('phone')).then(function (rsp) {
            var result = rsp.map(function (obj) {
                return {
                    type: obj.PET_Type,
                    text: obj.PET_BreedName,
                    value: obj.PET_Breed,
                };
            });
            parent.petBreedList = result;
        });
        KgGetPetType(localStorage.getItem('phone')).then(function (rsp) {
            var result = rsp.map(function (obj) {
                return {
                    text: obj.PET_TypeName,
                    value: obj.PET_Type,
                };
            });
            parent.petTypeList = parent.petTypeList.concat(result);
        });
        var now = new Date();
        // var date = now.getFullYear() + "-" + ((now.getMonth()+1) < 10 ? "0" : "") + (now.getMonth()+1) + "-" + (now.getDate() < 10 ? "0" : "") + now.getDate();
        this.form.birthday_year = `${now.getFullYear()}`;
        this.form.birthday_month = `${now.getMonth() + 1}`;
        this.form.birthday_day = `${now.getDate()}`;
    },
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    updated() {
        // console.log(this.form);
    },
    methods: {
        handleBlur(error, item) {
          return error && !!item
        },
        handleRegisterGift() {
            this.$router.push('/info/coupon?type=use');
        },
        daysInMonth(year, month) {
            console.log('daysInMonth', year, month);
            return new Date(year, month, 0).getDate();
        },
        goNext() {
            this.$store.state.isLoading = true;
            const valid = this.$refs.form.validate();
            var phone = localStorage.getItem('phone');
            var member_no = localStorage.getItem('member_no');
            var password = localStorage.getItem('password');
            var parent = this;
            if (valid && phone && member_no) {
                // debug
                // var rsp = {
                //     PET_SeqNo:3,
                //     PET_Name:"momo"
                // }
                KgInsertPet(phone, member_no, parent.form).then(function (rsp) {
                    if (rsp) {
                        insertPet(
                            phone,
                            localStorage.getItem('password'),
                            rsp.PET_SeqNo,
                            rsp.PET_Name
                        ).then(function (i_rsp) {
                            if (i_rsp) {
                                checkRegisterCoupon(phone, password).then(
                                    function (rsp) {
                                        if (rsp && rsp.status == '1') {
                                            parent.showRegisterGift = true;
                                            parent.giftQuantity = rsp.quantity;
                                        } else {
                                            parent.isSuccessOpened = true;
                                        }
                                        parent.$store.state.isLoading = false;
                                    }
                                );
                                parent.isSuccessOpened = true;
                            }
                            parent.$store.state.isLoading = false;
                        });
                    } else {
                        console.log('失敗');
                        parent.$store.state.isLoading = false;
                    }
                });
            } else {
                this.isChecking = true;
                this.$store.state.isLoading = false;
                console.log('缺少資料', phone, member_no);
            }
        },
        refresh() {
            this.form = {
                alias: '',
                type: '',
                birthday: '',
                birthday_year: '',
                birthday_month: '',
                birthday_day: '',
                gender: '',
                body: '',
                comb: '',
                isNeutered: true,
            };
            this.isSuccessOpened = false;
        },
    },
    watch: {
        birthday_year_month: function () {
            if (this.form.birthday_year && this.form.birthday_month) {
                var days = this.daysInMonth(
                    this.form.birthday_year,
                    parseInt(this.form.birthday_month)
                );
                this.dayOptions = [
                    {
                        text: '日',
                        value: '',
                        selected: true,
                        disabled: true,
                    },
                ];
                for (let i = 1; i <= days; i++) {
                    this.dayOptions.push({
                        text: i < 10 ? `0${i}` : `${i}`,
                        value: i < 10 ? `0${i}` : `${i}`,
                    });
                }
            }
        },
        birthday_year_month_day: function () {
            this.form.birthday = `${this.form.birthday_year}-${this.form.birthday_month}-${this.form.birthday_day}`;
        },
    },
    computed: {
        birthday_year_month() {
            return this.form.birthday_year + this.form.birthday_month;
        },
        birthday_year_month_day() {
            return (
                this.form.birthday_year +
                this.form.birthday_month +
                this.form.birthday_day
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.register-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
}

a.skip {
    text-decoration-line: underline;
    color: $color_main_second;
}

div.dialog_pet_success {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    > .footer {
        .btn,
        a {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
        a {
            font-size: 16px;
            font-weight: 500;
            border-radius: 25px;
            background-color: #ff9f48 !important;
        }
    }
}
</style>
