<template>
    <vc-date-picker
        style="background: none; padding-top: 0px"
        v-bind="{ ...$attrs }"
        v-on="$listeners"
        mode="date"
        is-expanded
        trim-weeks
        :min-date="startDate"
        :max-date="endDate"
        :model-config="modelConfig"
        :select-attribute="selectAttribute"
    />
</template>

<script>
export default {
    name: 'SalonDatePicker',
    data: () => ({
        modelConfig: {
            type: 'string',
            mask: 'YYYY-MM-DD', // Uses 'iso' if missing
        },
        selectAttribute: {
            highlight: {
                color: '#FF9F4B',
                fillMode: 'solid',
                style: {
                    backgroundColor: '#FF9F4B',
                },
            },
        },
    }),
    props: {
        startDate: {
            type: String,
        },
        endDate: {
            type: String,
        },
    },
    created() {
        // console.log("in datepicker",this.startDate,this.endDate)
    },
};
</script>

<style lang="scss" scoped></style>
